<template>
    <div class="wholeBox">    
      <div class="tabsBox">
          <el-tabs tab-position="top" type="border-card" style="background:transparent;height:600px;width:90%" stretch >
              <el-tab-pane >
                  <span slot="label" ><i class="el-icon-date" style="margin-right:20px;"></i>公司基本資料</span> 
                  <el-table ref="multipleTable" :data="profileList" :show-header="false" :cell-style="{padding: '3px', height: '35px'}" :header-cell-style="{ background: 'transparent'}" style="width: 100%;max-height:550px;overflow-y:auto;"  empty-text="暫無數據">
                  <el-table-column label="標題" prop="title" min-width="35%"> </el-table-column>
                  <el-table-column label="內容" prop="value" min-width="65%" ></el-table-column>
                  </el-table>
              </el-tab-pane>
              <el-tab-pane  >
                  <span slot="label" ><i class="el-icon-reading" style="margin-right:20px;"></i>分公司資料</span>
                  <el-table ref="multipleTable" :data="branchList"  :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}" style="width: 100%;max-height:550px;overflow-y:auto;"  empty-text="暫無數據">
                  <el-table-column label="序號" prop="Nm" min-width="10%"> </el-table-column>
                  <el-table-column label="統一編號" prop="UniBrNm" min-width="10%"> </el-table-column>
                  <el-table-column label="分公司名稱" prop="BrName" min-width="40%"> </el-table-column>
                  <el-table-column label="公司狀況" prop="BrStatus" min-width="10%"> </el-table-column>
                  <el-table-column label="分公司核准設立日期" prop="BrApvDt" min-width="20%"> </el-table-column>     
                  <el-table-column label="最後核准變更日期" prop="BrFnlApvDt" min-width="15%"> </el-table-column>                                         
                  </el-table>
              </el-tab-pane>
              <el-tab-pane  >
                  <span slot="label" ><i class="el-icon-reading" style="margin-right:20px;"></i>歷史資料</span>
                  <div class="HistoryBox">
                      <div class="leftHistory">
                          請選擇核准日期:
                          <el-select v-model="selectChangeValue" @change="getComChange" placeholder="請選擇核准日期" no-data-text="目前無資料">
                              <el-option v-for="item in changeList" :key="item" :label="item" :value="item"></el-option>
                          </el-select>
                      </div>
                      <div class="rightHistory">
                          <el-table ref="multipleTable" :data="changeItemList"  :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}" style="width: 100%;max-height:300px;overflow-y:auto;"  empty-text="暫無數據">
                          <el-table-column label="變更項目" prop="Title" min-width="30%"> </el-table-column>
                          <el-table-column label="變更內容" prop="value" min-width="70%"> </el-table-column>                                      
                          </el-table>                        
                      </div>
                  </div>
  
              </el-tab-pane>                
          </el-tabs>
      </div>
      <div class="buttonBox">
          <!-- <el-button type="primary" @click="openTopology()"><font-awesome-icon icon="circle-notch"  style="margin-right:5px;" />公司關聯分析</el-button>         -->
          <el-button type="danger"  @click="closeWin()" ><font-awesome-icon icon="times"  style="margin-right:5px;" />關閉</el-button>
      </div>
    </div>
  </template>
  
  <script>
  
  
  export default {
  
      name : 'companyInfo',
      props: {
          comid : {
              type: String,
          },
          comname:{
              type: String,
          },
      },  
      
    data() {
      return {
          ComName:"",
          pcomid:"",
          pcomname:"",
          prepname:"",
          paddress:"",
          profileList:[],
          supervisorList:[],
          managerList:[],
          branchList:[],
          changeList:[],
          BasicInfo : {},
          selectChangeValue: "",
          changeItemList: [],
          
      };
    },
  
    methods: {
  
  
      saveWatchList() {
          alert('saveWatchList');
      },
  
      openTopology() {
        const comId = this.comid;
        let routeUrl = this.$router.resolve({
          path: "/Topology",
          query: { id: comId },
        });
        window.open(routeUrl.href, "_blank");
      },      
      closeWin() {
          window.close();
      },
      // 基本資料
      async getBasicInfo(uniComNm) {
  
  
      },
      // 變更
      async getComChange(strdate) {
          this.$store.dispatch("loadingHandler", true);
          const qryCom = { UniComNm: this.comid, DataDt: strdate, isSys: true };
          console.log(qryCom);
          await this.$api.apimgrs.getForeignComChange(qryCom).then((res)=>{
  
  
                  console.log(res.Before, res.After);
                  this.getChangeItems(res.Before, res.After);
  
              //console.log(this.changeItemList);
          }, err=> {
              this.$notify.error({
              title: "錯誤",
              message: "網路發生錯誤",
              type: "Error"})
          });
          this.$store.dispatch("loadingHandler", false);
      },
      getChangeItems(before, after) {
          this.changeItemList = [];
          
          if(before.PUCap!=after.PUCap){
              this.changeItemList.push( {Title: "實收資本額(元)", value : this.commaFormat( before.PUCap) + " -> " + this.commaFormat(after.PUCap)});
          }
          if(before.ComStatus!=after.ComStatus){
              this.changeItemList.push( {Title: "公司狀況", value : before.ComStatus + " -> " + after.ComStatus});
          }     
          if(before.BizItem!=after.BizItem){
              // this.changeItemList.push( {Title: "所營事業資料", value : before.BizItem + " -> " + after.BizItem});
              this.compareBizItems(before.BizItem,after.BizItem);
          }  
          if(before.ComName!=after.ComName){
              this.changeItemList.push( {Title: "公司名稱", value : before.ComName + " -> " + after.ComName});
          }
          if(before.RepName!=after.RepName){
              this.changeItemList.push( {Title: "代表人姓名", value : before.RepName + " -> " + after.RepName});
          }
          if(before.ComLoc!=after.ComLoc){
              this.changeItemList.push( {Title: "公司所在地", value : before.ComLoc + " -> " + after.ComLoc});
          }
          if(before.TotCap!=after.TotCap){
              this.changeItemList.push( {Title: "資本總額(元)", value : this.commaFormat(before.TotCap) + " -> " + this.commaFormat(after.TotCap)});
          }
          if(JSON.stringify(before.Manager)!=JSON.stringify(after.Manager)){
              // this.changeItemList.push( {Title: "經理人資料", value : before.Manager + " -> " + after.Manager});
              this.compareManger(before.Manager,after.Manager);
          }       
          if(JSON.stringify(before.Supervisor)!=JSON.stringify(after.Supervisor)){
              // this.changeItemList.push( {Title: "董監事資料", value : JSON.stringify(before.Supervisor) + " -> " + JSON.stringify(after.Supervisor)});
              this.compareSupervisor(before.Supervisor,after.Supervisor);
          }    
          //console.log(this.changeItemList);   
      },
  
      compareManger(before, after) {
  
          let bName = before.map(x=>x.Name);
          let aName = after.map(y=>y.Name);
          let union = bName.concat(aName);
          //console.log(union);
          let adds = union.filter( x=> ! bName.includes(x));
          let subs = union.filter( x=> ! aName.includes(x));
          //console.log(adds, subs);
          adds.forEach(x=> {
              this.changeItemList.push( {Title: "新增經理人", value : x});
          })
          subs.forEach(x=> {
              this.changeItemList.push( {Title: "刪除經理人", value : x});
          })
      },
  
      compareSupervisor(before, after) {
           let bName = before.map(x=>x.Name);
          let aName = after.map(y=>y.Name);
          let union = bName.concat(aName);
          //console.log(union);
          let adds = union.filter( x=> ! bName.includes(x));
          let subs = union.filter( x=> ! aName.includes(x));
          //console.log(adds, subs);
          adds.forEach(x=> {
              this.changeItemList.push( {Title: "新增董監事", value : x});
          })
          subs.forEach(x=> {
              this.changeItemList.push( {Title: "刪除董監事", value : x});
          })  
          
          // 比對內容.. 名字相同
          const interName = bName.filter(value => aName.includes(value));
          //console.log(interName);
          interName.forEach(z=>{
              let bObj = before.find(y=>y.Name==z);
              let aObj = after.find(y=>y.Name==z);
              //console.log(bObj,aObj);
              if( bObj.LegPersonRep!=aObj.LegPersonRep ) 
              {
                  const bstr = bObj.LegPersonRep ? bObj.LegPersonRep : "無";
                  const astr = aObj.LegPersonRep ? aObj.LegPersonRep : "無";
                  if(!( bstr=="無" && astr=="無"))
                      this.changeItemList.push( {Title: "董監事 "+ z + " 所代表法人變更", value : bstr  + " -> " + astr   });
              }
              if( bObj.HoldStkNm!=aObj.HoldStkNm )
                  this.changeItemList.push( {Title: "董監事 "+ z + " 持有股份數(股)變更", value : bObj.HoldStkNm + " -> " + aObj.HoldStkNm});  
              if( bObj.JobTitle!=aObj.JobTitle )
              {
                  const bstr = bObj.JobTitle ? bObj.JobTitle : "無";
                  const astr = aObj.JobTitle ? aObj.JobTitle : "無"; 
                  if(!( bstr=="無" && astr=="無"))              
                      this.changeItemList.push( {Title: "董監事 "+ z + " 職稱變更", value : bstr  + " -> " + astr});  
              }
                               
          });
      },
      
      compareBizItems(beforeStr, afterStr) {
  
  
          let bName = [];
          let aName = [];
          const regPattern=/(?<item>[A-Z]{1,2}\d{5,6}[\u4e00-\u9fa5\u3002\uff1b\uff0c\uff1a\u201c\u201d\uff08\uff09\u3001\uff1f\u300a\u300b]+)/mg; 
  
          let match = regPattern.exec(beforeStr);
          do {
              bName.push(match.groups.item);
          } while((match = regPattern.exec(beforeStr)) !== null);
  
          let match1 = regPattern.exec(afterStr);
          do {
              aName.push(match1.groups.item);
          } while((match1 = regPattern.exec(afterStr)) !== null);
  
  
          let union = bName.concat(aName);
          //console.log(union);
          let adds = union.filter( x=> ! bName.includes(x));
          let subs = union.filter( x=> ! aName.includes(x));
          //console.log(adds, subs);
          adds.forEach(x=> {
              this.changeItemList.push( {Title: "新增營業項目", value : x});
          })
          subs.forEach(x=> {
              this.changeItemList.push( {Title: "刪除營業項目", value : x});
          })
      },
  
      commaFormat: function(value) {

        // return "";
          if( value===null) 
              return "";
          else  {
              // 加上千分位符號
              return value
                  .toString()
                  .replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, function(all, pre, groupOf3Digital) {
                  return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
                  });
          }  
  
  
      },
    },
  
    async mounted() {
      this.$store.dispatch("loadingHandler", true);
        // 取得Unicode ...
      const qryCom = { "UniComNm": this.comid };
      this.ComName = this.comname;
      console.log(qryCom);
      console.log(this.ComName,this.comid);
      
      await this.$api.apimgrs.getForeignComBasicInfo(qryCom).then((res) => {      
        
        
          console.log("Foriegn",res);
          this.profileList = [];
          let profile = res.ForComProf;
          
          this.profileList.push( { title: "統一編號", value: profile.UniComNm  });
          this.profileList.push( { title: "公司狀況", value: profile.ComStatus  });
          this.profileList.push( { title: "公司名稱", value: this.ComName  });
          this.profileList.push( { title: "營運資金(元)", value: this.commaFormat(profile.OpsCap)  });
        //   this.profileList.push( { title: "實收資本額(元)", value: this.commaFormat(profile.PUCap)  });
          this.profileList.push( { title: "代表人姓名", value: profile.AgentName  });
          this.profileList.push( { title: "辦事處所在地", value: profile.ComLoc  });
          this.profileList.push( { title: "登記機關", value: profile.RegAuth  });
          this.profileList.push( { title: "核准設立日期", value: profile.ApvDt  });
          this.profileList.push( { title: "最後核准變更日期", value: profile.FnlApvDt  });
        //   this.profileList.push( { title: "所營事業資料", value: profile.BizItem  });  
          this.pcomid = profile.UniComNm;
          this.pcomname = this.ComName;
          this.prepname = profile.RepName;
          this.paddress = profile.ComLoc;
    
 
          this.branchList = [];
          res.ForComBr.forEach(x => {
              this.branchList.push( { Nm: x.Nm, UniBrNm:x.UniBrNm, BrName:x.BrName,BrStatus:x.BrStatus,BrApvDt:x.BrApvDt,BrFnlApvDt:x.BrFnlApvDt });
          }); 
          
          
          this.changeList = [];

          let changeHistory = res.Change.filter( (item,index) => res.Change.indexOf(item) === index);

          changeHistory.forEach(x => {
              if( x!=="2017-01-01")
                  this.changeList.push(x);
          }); 
  
          if( this.changeList.length==1) {
              this.changeList = null;
          }
          

          
      }, err => {
          this.$notify.error({
          title: "錯誤",
          message: "網路發生錯誤",
          type: "Error"});
      });



      this.$store.dispatch("loadingHandler", false);
    },
  }
  </script>
  
  <style lang="scss" scoped>
  // * {
  //     outline: red solid 1px;
  // }
  .wholeBox {
      display: flex;
      flex-direction: column;
      margin: 0px 0px;
      padding: 0px 0px;
      // width: 95%;
      // height:150vh;
      // background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%) center center / 100% 100%;
     
  
      .tabsBox {
          display: flex;
          flex-direction: row;
          margin: 0px 0px;
          // padding: 20px 20px;  
          width: 100%;
          justify-content: center;
      }
  
      .buttonBox {
          display: flex;
          flex-direction: row;
          margin: 0px 0px;
          padding: 20px 20px;
          justify-content: center;
          align-items: center;
      }
  }
  .HistoryBox {
      display: flex;
      flex-direction: column;
      .leftHistory {
          padding: 20px 20px 0px 20px;
      }
      .rightHistory {
          padding: 20px 20px;
          // outline: red solid 1px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .before {
              width: 40%;
          }
          .after {
              width: 40%
          }
      }
  }
  .el-tabs {
      background-color: transparent;
  }
  
  </style>