import axios from 'axios';
import store from '@/store';
import { MessageBox, Message } from 'element-ui';
import router from '../router';
// import { https } from 'https';
// import * as https from 'https';

//https://stackoverflow.com/questions/68239942/using-https-with-axios-request-in-nestjs

let start = "";
let surl = "";
const service = axios.create({
  //baseURL: process.env.VUE_APP_BASE_API,
  timeout: 120000,
  // baseURL: "https://35.194.230.94/",
  baseURL: process.env.VUE_APP_BASE_API,
  // headers: {
  //   "Content-Type":"multipart/form-data",
  //   "accept": "multipart/form-data"
  // }
  // timeout: 60000,
  // httpsAgent: new https.Agent({  
  //   rejectUnauthorized: false
  // }) 
});

service.interceptors.request.use(
  (config) => {   
    start = Date.now();    
    surl = config.url;

    // 以下是 minds GCP 用的System Token.. 
    // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZXMiOlsic3lzdGVtIl0sImFjY291bnQiOiJzeXN0ZW0iLCJuYW1lIjoic3lzdGVtIiwicm9sZSI6WyJzeXN0ZW0iXSwiY29tcGFueV9uYW1lIjoic3lzdGVtIiwiZGVwdF9uYW1lIjoic3lzdGVtIiwidXNlcl9pZCI6InN5c3RlbSIsImNvbXBhbnlfaWQiOiIxIiwiZGVwdF9pZCI6InN5c3RlbSIsInRva2VuX3NvdXJjZSI6Ii9jcmVhdGVfdG9rZW4vIiwiZXhwIjo0ODQ1OTMzOTkzfQ.BC_NWIzIf5mSw2w2pxHYQq_9kl0u5I1vS2_cRES1sNY"

    // 以下是台企銀用的System Token.. 
    //let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZXMiOlsic3lzdGVtIl0sImFjY291bnQiOiJzeXN0ZW0iLCJuYW1lIjoic3lzdGVtIiwicm9sZSI6WyJzeXN0ZW0iXSwiY29tcGFueV9uYW1lIjoic3lzdGVtIiwiZGVwdF9uYW1lIjoic3lzdGVtIiwidXNlcl9pZCI6InN5c3RlbSIsImNvbXBhbnlfaWQiOiIxIiwiZGVwdF9pZCI6InN5c3RlbSIsInRva2VuX3NvdXJjZSI6Ii9jcmVhdGVfdG9rZW4vIiwiZXhwIjo0ODQ1MDY3NzM3fQ.p0pmPCSDAEA8arzFhUgYsGSmyPTOcY5tW41oIc08d1Y"

    let token = process.env.VUE_APP_BASE_SYSTOKEN
      if(token)
        config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {     
    const millis = Date.now() - start;
    // console.log(` ${surl} SYS載入時間 = ${Math.floor(millis / 1000 )}`);
    const res = response.data;
    return res;
  },
  (error) => {
    console.log(error);
    const { response } = error;
    store.dispatch('loadingHandler', false);
    if (response) {
      errorHandle(response.status, response.data.error, response);
      return Promise.reject(error);
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
  }
);

/* 錯誤捕捉 */
const errorHandle = (status, msg, response) => {
  switch (status) {
    case 400:
      break;

    case 401:
      tokenExpire();
      break;

    case 403:
      break;

    case 404:
      router.replace({
        name: '404',
      });
      break;

    case 500:
      break;

    default:
      break;
  }
};

const tokenExpire = () => {
  Message({
    message: '登入逾時，請重新登入！',
    type: 'error',
    duration: 3000,
  });
  /* 登出api */
  store.dispatch('Logout');
  router.replace({
    name: 'login',
  });
};

// const tokenExpire = (response) => {
//   // TODO: 找到api url
//   if (response.xxx === '/check/refreshToken') {
//     Message({
//       message: '登入逾時，請重新登入！',
//       type: 'error',
//       duration: 3000,
//     });
//     /* 登出api */
//     store.dispatch('Logout');
//     router.replace({
//       name: 'login',
//     });
//   } else {
//     // 執行refreshToken
//     api.refreshToken().then((res) => {
//       setToken(res.data.token)
//     })
//   }
// };


export default service;
