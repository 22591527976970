<template>
  <div class="comRelBox">
    <Breadcrumb :title="$route.meta.title" />
    <div class="searchWrap">
      <div class="leftBox">
        <div class="itemWrap">
          <label>登記日期</label>
          <el-date-picker :editable="true" v-model="dateRange" format="yyyy-MM-dd"  value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="開始日期" end-placeholder="結束日期" clearable>
          </el-date-picker>          
        </div>
        <div class="itemWrap">
          <label>資本總額(萬)</label>
          <el-input v-model="TotCapStart" style="width:30%" clearable=""></el-input>  ~
          <el-input v-model="TotCapEnd" style="width:30%" clearable=""></el-input>      
        </div>    
        <div class="itemWrap">
          <label>公司狀況</label>
          <el-select v-model="selectedComStatus" placeholder="請選擇公司種類" no-data-text="無數據" clearable >
        
            <el-option label="核准設立" value="核准設立"></el-option>
            <el-option label="其他" value="other"></el-option>          
          </el-select>           
        </div>
        <div class="itemWrap">
            <label>公司名稱</label>
            <el-input v-model="query" placeholder="公司名稱/統一編號" style="width:50%;margin-right:15px;" clearable></el-input>
            <el-button type="primary" @click="searchNews">查詢</el-button>
        </div>               
      </div>
      <div class="rightBox">
        <div class="itemWrap">
          <label>所在縣市</label>
            <el-select v-model="selectedCity" placeholder="請選擇縣市" no-data-text="目前無縣市" clearable @change="changeDist">
              <el-option v-for="(item, key) in CityList" :key="key"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>          
        </div>  
        <div class="itemWrap">
          <label>所在區域</label>
            <el-select v-model="selectedDistrict" placeholder="請選擇鄉鎮" no-data-text="目前無鄉鎮" clearable @change="changeRoad">
              <el-option v-for="(item, key) in DistList" :key="key"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>         
        </div>
        <div class="itemWrap">
          <label>所在街道</label>
            <el-select v-model="selectedStreet" placeholder="請選擇路段" no-data-text="目前無路段" clearable  >
              <el-option v-for="(item, key) in RoadList" :key="key"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>          
        </div>                           
      </div>
    </div>

    <div class="companyInfoBox">
        <div class="TableCont">
          <el-table style="width: 100%" ref="multipleTable" :data="tableData"  :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}"  empty-text="暫無數據"  >
            <el-table-column prop="UniComNm" label="統一編號" min-width="15%"></el-table-column>
            <el-table-column label="公司名稱" min-width="35%">
                <template slot-scope="scope">
                    <a class="goDetailPage" @click="goDetailPage(scope.row)">{{ scope.row.ComName }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="ComStatus"  label="公司狀況" min-width="15%" >
            </el-table-column>            
            <el-table-column prop="RepName"  label="代表人姓名" min-width="15%" >
            </el-table-column>           
            <el-table-column  label="核淮設立日期"  min-width="10%">
                <template slot-scope="scope">
                <div>{{ scope.row.ApvDt | moment("YYYY-MM-DD") }}</div>
                </template>            
            </el-table-column>
            <el-table-column  label="關聯公司"  min-width="10%" >
                <template slot-scope="scope">
                    <a class="goDetailPage" @click="goRelCompany(scope.row)">關聯公司</a>
                    <br/>
                    <a class="goDetailPage" @click="goTopology(scope.row)">關聯圖</a>
                </template>          
            </el-table-column>          
        </el-table>
        
      </div>
      <!-- <CompanyInfo :comid="ComId"  :comname="ComName" v-if="ComName.length > 0" /> -->
      <Pagination @currentChange="pageChange" :pageSize="listQuery.Page_Size" :propsCurrentPage="listQuery.Page_Num" :totalLists="totalLists"> </Pagination>        
    </div>

    <!-- <el-dialog title="關聯公司新聞" :visible.sync="openDialog" width="80%" center >
      <div style="display:flex; flex-direction:column; align-items:center;max-height:400px;overflow-y: auto; ">
        <showRelCom :UniComNm="ComId" :sDate="dateRange[0]" :eDate="dateRange[1]"  />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeOpenDialog" type="warning" icon="el-icon-close">關閉</el-button>
      </div>      
    </el-dialog> -->
  </div>
</template>

<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from '../../src/components/Breadcrumb.vue';
// import CompanyInfo from '../components/CompanyInfo.vue';

import moment from "moment";
export default {
  components: {Breadcrumb,Pagination  },
  data() {
    return {
      ComId: "84149054",
      ComName: "揚智科技股份有限公司",
      totalLists: 0,
      tableData: [],

      query:"",
      dateRange: ["",""
        //moment().add(-90, "days").format("YYYY-MM-DD"),
        //moment().format("YYYY-MM-DD"),
      ],
      TotCapStart: "",
      TotCapEnd: "",
      selectedComStatus:"",
      selectedCity:"",
      selectedDistrict:"",
      selectedStreet:"",
      totalLists: 0,
      listQuery: {
          Start_Date:"",
          End_Date:"",
          Page_Size: 10,
          Page_Num : 1,
          ComStatus: [],
          TotCapStart: "",
          TotCapEnd: "",
          City:"",
          District:"",
          Street:"",
          
      },
      CityList: [],
      DistList: [],
      RoadList: [],

      openDialog: false,
    };
  },
  computed: {

  },
  methods: {
    closeOpenDialog() {

      this.openDialog=false;
    },
    
    goRelCompany(val) {
      // this.openDialog = true;

      // console.log(val);
      // this.ComId = val.UniComNm;

      let routeUrl = this.$router.resolve({
        path: "/RelComInfo",        
        query: { ComId: val.UniComNm },
      });
      window.open(routeUrl.href, "_blank");

    },

    goTopology(val) {
      let routeUrl = this.$router.resolve({        
        path: "/Topology",
        query: { ComId: val.UniComNm },
      });
      window.open(routeUrl.href, "_blank");

    },    

    searchNews() {

      this.listQuery.Page_Num = 1;
      
      this.getList();     

    },

    searchNews_OLD() {
      //var reg = /^\d+$/;
      // console.log('@',this.dateRange,'@');
      this.listQuery.Page_Num = 1;
      if( !this.dateRange || this.dateRange[0]=="") {
        delete this.listQuery.Start_Date;
        delete this.listQuery.End_Date;
      }
      else {
        this.listQuery.Start_Date = this.dateRange[0];
        this.listQuery.End_Date = this.dateRange[1];
      }
      
      if( this.TotCapStart!="" && this.TotCapEnd!="") {
        // 檢查, TotCapStart <= TotCapEnd 

        if( parseInt(this.TotCapStart) > parseInt(this.TotCapEnd) ) {
          this.$notify.error({
            title: "輸入錯誤",
            message: "資本額起始數字 大於 結束數字",
            type: "Error"});
          return;
        }
        this.listQuery.TotCapStart = this.TotCapStart * 10000;
        this.listQuery.TotCapEnd = this.TotCapEnd * 10000;
      }
      else {
        delete this.listQuery.TotCapEnd;
        delete this.listQuery.TotCapStart;  
      }  
      
      if( this.listQuery.ComStatus=="")
        delete this.listQuery.ComStatus;

      if( this.listQuery.City=="")
        delete this.listQuery.City;
 
      if( this.listQuery.District=="")
        delete this.listQuery.District;

      if( this.listQuery.Street=="")
        delete this.listQuery.Street;        

      if( this.query.length == 0) {
        delete this.listQuery.ComName;
        delete this.listQuery.UniComNm;
      } 
      else {
        var reg = new RegExp(/^\d+$/);
        if( reg.test(this.query)) {
          this.listQuery.UniComNm = this.query;
          delete this.listQuery.ComName;
        } else { // 名稱
          delete this.listQuery.UniComNm ;
          this.listQuery.ComName = this.query;
        }
      }  
      
      this.getList();     

    },    

    async getCity() {
      let qry = {
        "Type": "City"
      }
      
      this.CityList = [];
      await this.$api.apimgrs.getAddress(qry).then((res)=>{
        this.CityList = res.data;
      });      
    },

    async getDist(val) {
      
      let qry = {
        "Type": "District",
        "City": val
      }
      
      this.DistList = [];
      await this.$api.apimgrs.getAddress(qry).then((res)=>{
        this.DistList = res.data;
      });  
    },

    async getRoad(city,dist) {
      let qry = {
        "Type": "Street",
        "City": city,
        "District":dist,
      }
      this.RoadList = [];
      await this.$api.apimgrs.getAddress(qry).then((res)=>{
        this.RoadList = res.data;
      });  
      // console.log(this.RoadList);
    },

    async changeDist(val) {
      this.$store.dispatch("loadingHandler", true);
      // console.log("Select City",val);
      this.selectedDistrict = "";
      this.selectedStreet = "";
      await this.getDist(val);
      this.$store.dispatch("loadingHandler", false);
    },

    async changeRoad(val) {
      this.$store.dispatch("loadingHandler", true);

      this.selectedStreet = "";
      await this.getRoad(this.selectedCity, val);
      this.$store.dispatch("loadingHandler", false);
    },

    checkCondition_OLD() {
      // 檢查, this.listQuery, 是否只有.. Page_Num, Page_Num 若是, 表示沒有其他條件.
      let qry = { ...this.listQuery};
      delete qry.Page_Num;
      delete qry.Page_Size;     

      // return true;
      if( Object.keys(qry).length === 0) {        
        return true;
      }
        
      else
        return false;

    },

    checkCondition() {
      
      var iCount = 0;
      // console.log("dateRange", this.dateRange);

      if( ! this.dateRange) {
        this.dateRange=["",""];
      }

      if( this.dateRange[0].trim() !== "" )
        iCount++;

      


      if( this.TotCapStart!="" && this.TotCapEnd!="")       
        iCount++;      

      if( this.selectedComStatus.trim() !== "")
        iCount++;
      
        
      if( this.selectedCity.trim() !== "")
        iCount++;

      if( this.query.length > 0)
        iCount++;

      // console.log(iCount);
      if( iCount==0)
        return true;
      else
        return false;
    },



    async getList() {   
      
      if( this.checkCondition() ) {
        this.$notify.error({
          title: "請輸入查詢條件",
          message: "請輸入至少一個條件",
          type: "Error"});
        return;
      }
      
    
      // await this.deleteProperty();

      
      let qry = { Page_Num: this.listQuery.Page_Num, 
        Page_Size: this.listQuery.Page_Size       
      }

      if( this.dateRange ) {
        if( !(this.dateRange[0]==""))
        {
          qry.Start_Date = this.dateRange[0];
          qry.End_Date = this.dateRange[1];
        }
      }


      if( this.TotCapStart!="" && this.TotCapEnd!="") 
      {
          // 檢查, TotCapStart <= TotCapEnd 
          if( parseInt(this.TotCapStart) > parseInt(this.TotCapEnd) ) {
            this.$notify.error({
            title: "輸入錯誤",
            message: "資本額起始數字 大於 結束數字",
            type: "Error"});
          return;
        }
        qry.TotCapStart = this.TotCapStart * 10000;
        qry.TotCapEnd = this.TotCapEnd * 10000;
      }

      if( this.selectedComStatus!="")
        qry.ComStatus = this.selectedComStatus;

      
      if( this.selectedCity!="")
        qry.City = this.selectedCity;

      if( this.selectedDistrict!="")
        qry.District = this.selectedDistrict;

      if( this.selectedStreet !="")
        qry.Street = this.selectedStreet;

      if( this.query.length > 0) {
        var reg = new RegExp(/^\d+$/);
        if( reg.test(this.query)) {
          qry.UniComNm = this.query;          
        } else { // 名稱          
          qry.ComName = this.query;
        }        
      }
      this.$store.dispatch("loadingHandler", true);
      //console.log(qry);

      await this.$api.apimgrs.searchCompany(qry).then((res)=>{
            this.tableData = res.data;    
            this.totalLists = res.total;
            //console.log(this.tableData);
      }, err => {
        this.$notify.error({
        title: "錯誤",
        message: "網路發生錯誤",
        type: "Error"});
      });
      this.$store.dispatch("loadingHandler", false);
    },

    async deleteProperty() {
      let keys = Object.keys(this.listQuery)
      keys.forEach((key)=>{
        if(Array.isArray(this.listQuery[key]) && this.listQuery[key].length===0){
          delete this.listQuery[key]
        }
        if(!this.listQuery[key]){
          delete this.listQuery[key]
        }
      });
    },

    async pageChange(val) {
      this.listQuery.Page_Num = val;
      await this.getList();
    }, 



    // 開啟公司明細頁....
    goDetailPage(data) {
        console.log(data);
        if( data.ComType=="foreign") {
          let routeUrl = this.$router.resolve({
            path: "/companyDetailForeign",
            query: { comid: data.UniComNm, comname: data.ComName },
          });
          window.open(routeUrl.href, "_blank");
        }
        else 
        {
          let routeUrl = this.$router.resolve({
            path: "/companyDetail",
            query: { comid: data.UniComNm, comname: data.ComName },
          });
          window.open(routeUrl.href, "_blank");
        }

    },
  },
  async mounted() {
    //await this.getRegularCustomer();
    await this.getCity();  
  },
};
</script>

<style lang="scss" >
// * {
//   outline: red solid 1px;
// }

.comRelBox {
//   height:100vh;
  background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  display: flex;
  flex-direction: column;
  
//   padding: 0px 5%;



  .companyInfoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;    
    margin-top: 20px;
    padding: 0px 5%;


  }

  .searchWrap {
    display: flex;
    flex-direction: row;    
    justify-content: flex-start;
    padding: 0px 5%;

    .leftBox  {
      width: 60%;
      display: flex;
      flex-direction: column;
      .itemWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top:10px;

        label {
          width: 100px;
          white-space: nowrap;
          margin-right: 10px;
          text-align: right;
        }
      }
    }
    
    .rightBox {
      width:30%;
      display: flex;
      flex-direction: column;

      .itemWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top:10px; 

        label {
          width: 100px;
          white-space: nowrap;
          margin-right: 10px;
          text-align: right;
        }
      }
    }

    

  }

  .goDetailPage {
    color: #0645ad;
    text-decoration: underline;
    cursor: pointer;
}

}
</style>
