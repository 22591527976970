<template>
    <div class="topicBox">
      <Breadcrumb :title="$route.meta.title" />      
      
        
      <div class="topicBox__searchBox" v-if="openSearchBox">
        <div class="topicBox__searchBox--sort">
          <label>專卷：</label>
          <el-select v-model="listQuery.folder" placeholder="請選擇專卷分類" no-data-text="無專卷分類" @change="changeFolder">
              <el-option key="99" label="請選擇專卷" value="" ></el-option>    
              <el-option v-for="(y,index) in folderList" :key="index" :label="y.FolderCategory" :value="y.FolderID" ></el-option>                                                   </el-select>
        </div>
        <div class="topicBox__searchBox--theme">
          <label>主題：</label>
          <el-select v-model="listQuery.topic" clearable placeholder="請選擇主題名稱" no-data-text="目前無名單" :disabled="listQuery.folder===''">              
              <el-option key="99" label="全部" value="" ></el-option>    
              <el-option v-for="(y,index) in topicList" :key="index" :label="y.TopicName" :value="y.TopicID" ></el-option>                                       
          </el-select>         
        </div>

      </div>   
  
 
  
      <div class="topicBox__listBox">
        <div class="topicBox__listBox--controlbar">
          <div class="topicBox__listBox--controlbar--pos">
            顯示詞性：
            <el-checkbox-group v-model="posCheck" size="medium" @change="handleCheckedPosChange" style="margin-left:10px;">
              <!-- <el-checkbox-button v-for="pos in posChecksList" :label="pos.title" :key="pos.id"></el-checkbox-button> -->
              <el-checkbox v-for="pos in posChecksList" :label="pos.title" :key="pos.id"></el-checkbox>
            </el-checkbox-group>
          </div>  
          <div class="topicBox__listBox--controlbar--slider">  
            <span >細緻度</span>        
            <el-slider v-model="sliderValue" :step="50" :format-tooltip="formatTooltip" show-stops @change="handleSlider">
            </el-slider>
          </div> 
          <div class ="topicBox__listBox--Operation">      
            <el-dropdown split-button type="success" >
              <font-awesome-icon icon="user-cog"  style="margin-left:15px;margin-right:10px" /><span style="margin-right:15px;">操作</span>
              <el-dropdown-menu slot="dropdown" >
                <!-- <el-dropdown-item><font-awesome-icon icon="plus" style="margin-right:10px;"  />新增</el-dropdown-item>
                <el-dropdown-item @click.native="deleteNews()"><font-awesome-icon icon="times" style="margin-right:10px;" />刪除</el-dropdown-item>
                <el-dropdown-item @click.native="openRelationAnalysis()" divided><font-awesome-icon icon="chart-pie" style="margin-right:10px;" />關聯分析</el-dropdown-item> 
                <el-dropdown-item @click="Mag"><font-awesome-icon icon="chart-pie" style="margin-right:10px;" />圖形放大</el-dropdown-item>-->
                <el-dropdown-item ><font-awesome-icon icon="redo" style="margin-right:10px;" />圖形重繪</el-dropdown-item>
                <el-dropdown-item divided><download-csv :data="multipleSelection" name="專卷查詢.csv"><font-awesome-icon icon="download" style="margin-right:10px;" />匯出CSV</download-csv></el-dropdown-item>    
              </el-dropdown-menu>
            </el-dropdown>
          </div>          
        </div>     
      </div>
    
      <div class="graphBox">     
          <div class="graphBox__leftbox">          
              <div id="cy"> 
              </div>
          </div>
          <div class="graphBox__rightbox">
            <el-tabs tab-position="top" type="border-card" style="background:transparent;height:350px" stretch>
              <el-tab-pane >
                <span slot="label" ><font-awesome-icon icon="flag" style="maring:0px 15px" />國家</span> 
                  <div v-if="tagGroup[0].value.length > 0">
                    <el-tag type="info" v-for="(items, idx) in tagGroup[0].value" :key="idx" @click.native="clickEntity(items)">
                      {{ items }}
                    </el-tag>  
                  </div>
                  <div v-else>
                    <p class="body__noData">無資料</p>
                  </div>
              </el-tab-pane>
              <el-tab-pane  >
                <span slot="label" ><font-awesome-icon icon="map-marker-alt" />地點</span>
                  <div v-if="tagGroup[1].value.length > 0">
                    <el-tag type="info" v-for="(items, idx) in tagGroup[1].value" :key="idx" @click.native="clickEntity(items)">
                      {{ items }}
                    </el-tag>  
                  </div>
                  <div v-else>
                    <p class="body__noData">無資料</p>
                  </div>
              </el-tab-pane>
               <el-tab-pane  >
                <span slot="label" ><font-awesome-icon icon="user" />人名</span>
                   <div v-if="tagGroup[2].value.length > 0">
                    <el-tag type="info" v-for="(items, idx) in tagGroup[2].value" :key="idx" @click.native="clickEntity(items)">
                      {{ items }}                    
                    </el-tag>  
                  </div>
                  <div v-else>
                    <p class="body__noData">無資料</p>
                  </div>
              </el-tab-pane>
              <el-tab-pane  >
                <span slot="label" ><font-awesome-icon icon="building" />組織</span>
                  <div v-if="tagGroup[3].value.length > 0">
                    <el-tag type="info" v-for="(items, idx) in tagGroup[3].value" :key="idx" @click.native="clickEntity(items)">
                      {{ items }}
                    </el-tag>  
                  </div>
                  <div v-else>
                    <p class="body__noData">無資料</p>
                  </div>
              </el-tab-pane>                       
            </el-tabs>
  
  
  
          </div>  
  
  
      </div> 
      <!-- 暫時先hidden起來 20231123 -->
      <!-- <div class="projNav" >
        <div class="projNav--search">
          專卷搜尋: 
            <el-button type="light" v-for="q in queries" :key="q" @click="delQuery(q)" >{{q}}<i class="el-icon-close el-icon--right"></i></el-button>   
        </div>
          <div class="projNav--button">
          <el-button type="success" @click="clearQuery" ><i class="el-icon-close" style="margin-right:10px;"></i> 清除 </el-button> 
          <el-button type="danger" @click="searchProj" > <i class="el-icon-search" style="margin-right:10px;"></i> 搜尋 </el-button> 
        </div>         
      </div> -->
      <div class="newsTable">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" empty-text="暫無數據" :cell-style="{padding: '3px', height: '35px'}" :header-cell-style="{ background: 'transparent'}" >
          <!--<el-table-column type="selection" width="50"></el-table-column> -->
          <!--<el-table-column label="序號" type="index" width="50"></el-table-column>-->
          <el-table-column label="新聞標題">
            <template slot-scope="scope">
              <a class="topicBox__listBox--goDetailPage" @click="goDetailPage(scope.row)">{{ scope.row.NewsTitle }}</a>
            </template>
          </el-table-column>
          <el-table-column label="新聞時間" width="120">
            <template slot-scope="scope">{{ scope.row.NewsTime | moment("YYYY-MM-DD") }}</template>
          </el-table-column>
          <el-table-column label="新聞網站" prop="NewsSite" width="120"></el-table-column>
          <el-table-column label="新聞頻道" prop="NewsChannel" width="100"></el-table-column>
          <!--<el-table-column label="情緒指標" prop="sentiment" width="100"></el-table-column>-->
        </el-table>        
        <Pagination @currentChange="pageChange" :pageSize="listQuery.Page_Size" :propsCurrentPage="listQuery.Page_Num" :totalLists="totalLists"> </Pagination>        
      </div>
  
    </div>    
  
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue';
import * as cytoscape from 'cytoscape';
import d3Force from 'cytoscape-d3-force';
import cxtmenu from 'cytoscape-cxtmenu';
import panZoom from 'cytoscape-panzoom';
import Pagination from '../components/Pagination.vue';

export default {
    components: {Breadcrumb,Pagination  },
    data() {
        return {
            totalLists: 0,
            tableData: [],
            query:"",
            totalLists: 0,
            listQuery: {
                folder:"",
                topic:"",
                Page_Size:10,
                Page_Num:1,
            },
            selectedPos : [],
            folderList:[],
            topicList:[],
            userTopic: {}, // 拓墣圖的節點及線段資料
            topicInfo: {}, // 專卷的資料, 如專卷名稱, 專卷ID, 專卷描述等
            dataNodes: [], // 繪圖用的節點及線段資料
            tagGroup: [
                { id: 0, title: "國家", value: [], require: true },
                { id: 1, title: "地點", value: [], require: true },
                { id: 2, title: "人名", value: [], require: true },
                { id: 3, title: "組織", value: [], require: true },
            ],                // 右方的標籤群組
            openSearchBox: true,
            posCheck: ["國家","地點","人名","組織"],
            posChecksList:[
                { id: 0, title: "國家", pos: "nat"},
                { id: 1, title: "地點", pos: "loc"},
                { id: 2, title: "人名", pos: "ppl"},
                { id: 3, title: "組織", pos: "org"},
            ],  
            sliderValue:100,          
            multipleSelection: [],
            queries: ["全部"],
        };
    },    
    methods : {  

        clickEntity(val) {          
          if( this.queries[0]=="全部")
            this.queries = [];
          this.queries.push(val);      
        },      

        async delQuery(val) {
          //console.log("delQuery",val);
          this.queries = this.queries.filter(function(value, index, arr){ 
            return arr[index]!=val;
          });  
          if( this.queries.length==0)
            this.queries = ["全部"];
        },      


        async getFolderList(isSys) {
          this.folderList = [];

          let req = {Page_Size: 100, Page_Num: 1 };     
          if ( !isSys ) 
              req.isSys = false;
          else 
              req.isSys = true;            

          await this.$api.folder.searchFolder(req).then((res)=>{                
              this.folderList = res.data;
              console.log(this.folderList);
              // this.totalLists = res.total;                
          });
        },  

        async getTopicList(isSys)
            {
            this.$store.dispatch("loadingHandler", true);
            this.topicList = [];

            let req = {Page_Size: 100, Page_Num: 1, FolderID: this.listQuery.folder };     
            if ( !isSys ) 
                req.isSys = false;
            else 
                req.isSys = true;
            // console.log(req);

            await this.$api.folder.searchTopic(req).then((res)=>{                
                // console.log(res);        
                this.topicList = res.data;
                this.totalLists = res.total;
                // console.log(this.topicList);        
            });        

            this.$store.dispatch("loadingHandler", false);
        },        

        async getTableData(isSys) {
            this.$store.dispatch("loadingHandler", true);
            this.tableData = [];

            let req = {Page_Size: this.listQuery.Page_Size, Page_Num: this.listQuery.Page_Num, FolderID: this.listQuery.folder };     
            if ( this.listQuery.topic != "" ) 
                req.TopicID = this.listQuery.topic;

            if ( !isSys ) 
                req.isSys = false;
            else 
                req.isSys = true;            

            await this.$api.folder.searchTopicNews(req).then((res)=>{                                
                this.tableData = res.data;
                this.totalLists = res.total;      
                console.log(this.tableData); 
                // this.makeTableShowChecked();
            });      


            this.$store.dispatch("loadingHandler", false);
        },        
        
        async pageChange(val) {
            this.listQuery.Page_Num = val;
            await this.getList();
        },        
     

        // 針對專卷內容去搜尋關鍵字
        // 需要用this.Queries 的關鍵字 去全文檢索
        //      this.projTheme , 代入TopicId 
        async searchProj() {
        
            const searchbytid = {
                topicId:this.projTheme,
                query:this.queries
            };
            console.log("搜尋", searchbytid);
            await this.$api.folder.searchTopicNews(searchbytid).then((res) => {
                console.log("Result", res.data.data);
                this.tableData = res.data.data;
                this.$store.dispatch("loadingHandler", false);
            });
            
            //console.log("this.tableData", this.tableData);
        },   
        
        handleSelectionChange(val) {
            this.multipleSelection = val;
            //console.log(val);
        },        

        async clearQuery() {
            this.queries = ["全部"];
        },        

        handleSlider(val) {    
            this.refreshCy();
        },        

        async changeFolder() {
            if ( this.listQuery.folder == "" ) {
                this.$notify({
                        title: "請選擇專卷",
                        message: "請選擇專卷",
                        type: "warning",
                    });
                return;
            }
            await this.getTopicList(true);      
        },    
        
        formatTooltip(val) {
            if( val <50)
                return "較少";
            else if( val == 50 )
                return "適中";
            else
                return "全部";
        },        

        async getTopicInfo(){
            this.$store.dispatch("loadingHandler", true);
            let qry = {
                "Page_Size": 10,
                "Page_Num": 1,
                "TopicID": this.listQuery.topic,
                "isSys": true
            }                           
            // console.log(qry);
            await this.$api.folder.searchTopic(qry).then((res) => {
                console.log(res);
                if( res.data.length > 0 ){
                    this.listQuery.folder = res.data[0].FolderID;
                    this.listQuery.topic = res.data[0].TopicID;
                    this.topicInfo = res.data[0];
                }
                
            })
            .catch(() => {});
            
            this.$store.dispatch("loadingHandler", false);
        },    
        
        async getList() {
        
            this.$store.dispatch("loadingHandler", true);
            const qry = {
                "TopicID": this.listQuery.topic,
                "isSys": true
            }

            this.userTopic = {};

            await this.$api.folder.getTopology(qry).then((res) => {
                //console.log(res);
                this.userTopic = res;
            })

            if( this.userTopic==null || this.userTopic==undefined ){
                this.$store.dispatch("loadingHandler", false);
                this.$notify.warning({
                    title: "無資料",
                    message: "無節點及線段資料",
                    type: "warning"});
                return;
            }


            const oNode = this.userTopic.nodes;

            let ctyName = oNode.filter(x=>x.pos=="nat");                           
            if( ctyName.length > 0 )
                this.tagGroup[0].value = ctyName.map(x=>x.name);

            let locName = oNode.filter(x=>x.pos=="loc"); 
            if( locName.length > 0)
                this.tagGroup[1].value = locName.map(x=>x.name);
 
            let pplName = oNode.filter(x=>x.pos=="ppl");   
            if( pplName.length > 0)
                this.tagGroup[2].value = pplName.map(x=>x.name);

            let comName = oNode.filter(x=>x.pos=="org");  
            if( comName.length > 0)
                this.tagGroup[3].value = comName.map(x=>x.name);  

            console.log(this.tagGroup);
            //this.markAllEntities();
            await this.refreshCy();
            this.getTableData(true);
            this.$store.dispatch("loadingHandler", false);

        },     
        async refreshCy() {
            await this.draw();
            
            this.cyObj.elements().remove();
            this.cyObj.add(this.dataNodes);            
            this.layoutRedraw();
        },   

        async draw() {
            await this.getMap();
            // console.log(this.dataNodes);

            // if (typeof cytoscape('core', 'cxtmenu') !== 'function') {
            //   cytoscape.use( cxtmenu );
            // }

            //cytoscape.use( cxtmenu );

            cytoscape.use( d3Force );
            
            const vm = this;
            var cy = cytoscape({
                container: document.getElementById('cy'), // container to render in
                style: [ // the stylesheet for the graph
                {
                    selector: 'node',
                    style: {
                    'content': 'data(name)',
                    'text-valign': 'center',
                    'text-halign': 'right',
                    'height': '10px',
                    'width': '10px',
                    'background-color': 'data(bg)', // '#666',
                    'border-width': '1px',
                    'border-color': '#333',              
                    'color': '#333',
                    'label': 'data(name)',

                    }
                },                
                {
                    selector: 'edge',
                    style: {
                    'width': 'data(width)',
                    'line-color': '#ccc',
                    'target-arrow-color': '#888',
                    'curve-style': 'bezier',
                    }
                },
                {
                    selector: ':selected',
                    css: {
                    'background-color': 'SteelBlue',
                    'line-color': 'black',
                    'target-arrow-color': 'black',
                    'source-arrow-color': 'black'
                    }
                }
                ],
                wheelSensitivity: 0.1,
                layout: {
                name: 'd3-force',
                animate: true,
                fixedAfterDragging: false,
                linkId: function id(d) {
                    return d.id;
                },
                linkDistance: 200,
                manyBodyStrength: -300,
                ready: function(){},
                stop: function(){},
                tick: function(progress) {
                    //console.log('progress - ', progress);
                },
                randomize: false,
                infinite: true
                }
            });

            cy.on('click','node', (e)=>{
                //this.selectedNode2 = e.target.id();
                var nd = vm.dataNodes.find( x => x.data.id==e.target.id());
                if( nd != null)
                {
                  //console.log(nd.data.name + '@' + nd.data.id);
                  if( vm.queries[0]=="全部")
                    vm.queries = [nd.data.name];
                  else
                    vm.queries.push(nd.data.name);
                  
                  //console.log("Queryies",vm.queries);      
                }
                else
                {
                  console.log("nd is null");
                }


            });

            cy.on('click','edge', function(evt){
                var node = evt.target;
                var ed = vm.dataNodes.find( x => x.data.id==node.id());
                //console.log(ed);
                if( ed != null )
                {
                //console.log( ed.data.source + '@' + ed.data.target);
                let setKeyWord = [];
                var source = vm.dataNodes.find(x=> x.data.id==ed.data.source )
                var target = vm.dataNodes.find(x=> x.data.id==ed.data.target );
                setKeyWord.push(source.data.name);
                setKeyWord.push(target.data.name);
                vm.queries = setKeyWord;
                console.log("paraid=" + ed.data.paraid);
                //console.log(vm.queries);
                }
                
            });

            cy.on('select', 'node', (e)=>{
                this.selectedCtxMenu = e.cyTarget;
                console.log('selectedCtxMenu = ' + e.cyTarget);
            });
            
            //cy.userZoomingEnabled( false ); // 取消Zooming ... 功能;

            // the default values of each option are outlined below:
            let defaults = {
                menuRadius: function(ele){ return 80; }, // the outer radius (node center to the end of the menu) in pixels. It is added to the rendered size of the node. Can either be a number or function as in the example.
                selector: 'node', // elements matching this Cytoscape.js selector will trigger cxtmenus
                commands: [
                {
                    fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                    content: '人名', // html/text content to be displayed in the menu
                    contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                    select: function(ele){ // a function to execute when the command is selected
                    //console.log( ele.id() ) // `ele` holds the reference to the active element
                    //alert( ele.position() );
                    console.log("選取"+ele.id()+"的人名子項目");
                    },
                    enabled: true // whether the command is selectable
                },
                {
                    fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                    content: '地名', // html/text content to be displayed in the menu
                    contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                    select: function(ele){ // a function to execute when the command is selected
                    //console.log( ele.id() ) // `ele` holds the reference to the active element
                    },
                    enabled: true // whether the command is selectable
                },
                {
                    fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                    content: '機關名', // html/text content to be displayed in the menu
                    contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                    select: function(ele){ // a function to execute when the command is selected
                    //console.log( ele.id() ) // `ele` holds the reference to the active element
                    },
                    enabled: true // whether the command is selectable
                },
                {
                    fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                    content: '國名', // html/text content to be displayed in the menu
                    contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                    select: function(ele){ // a function to execute when the command is selected
                    //console.log( ele.id() ) // `ele` holds the reference to the active element
                    },
                    enabled: true // whether the command is selectable
                },
                {
                    fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                    content: '關係人', // html/text content to be displayed in the menu
                    contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                    select: function(ele){ // a function to execute when the command is selected
                    //console.log( ele.id() ) // `ele` holds the reference to the active element
                    },
                    enabled: true // whether the command is selectable
                },
                {
                    fillColor: 'rgba(256, 200, 200, 0.75)', // optional: custom background color for item
                    content: '移除', // html/text content to be displayed in the menu
                    contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                    select: function(ele){ // a function to execute when the command is selected
                    //console.log( ele.id() ) // `ele` holds the reference to the active element
                    //cy.remove(ele.id());
                    //var j = cy.$('#'+ele.id());
                    cy.remove( '#'+ele.id() );
                    vm.layoutRedraw();
                    },
                    enabled: true // whether the command is selectable
                },
                {
                    fillColor: 'rgba(256, 200, 200, 0.75)', // optional: custom background color for item
                    content: '展開', // html/text content to be displayed in the menu
                    contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                    select: function(ele){ // a function to execute when the command is selected
                    //console.log( ele.id() ) // `ele` holds the reference to the active element
                    //cy.remove(ele.id());
                    //var j = cy.$('#'+ele.id());
                    cy.remove( '#'+ele.id() );
                    vm.layoutRedraw();
                    },
                    enabled: true // whether the command is selectable
                },
                {
                    fillColor: 'rgba(256, 200, 200, 0.75)', // optional: custom background color for item
                    content: '中心點', // html/text content to be displayed in the menu
                    contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                    select: function(ele){ // a function to execute when the command is selected
                    //console.log( ele.id() ) // `ele` holds the reference to the active element
                    //cy.remove(ele.id());
                    //var j = cy.$('#'+ele.id());
                    cy.center( '#'+ele.id() );              
                    vm.layoutRedraw();
                    },
                    enabled: true // whether the command is selectable
                }                    
                ],
                fillColor: 'rgba(0, 0, 0, 0.75)', // the background colour of the menu
                activeFillColor: 'rgba(1, 105, 217, 0.75)', // the colour used to indicate the selected command
                activePadding: 20, // additional size in pixels for the active command
                indicatorSize: 24, // the size in pixels of the pointer to the active command, will default to the node size if the node size is smaller than the indicator size, 
                separatorWidth: 3, // the empty spacing in pixels between successive commands
                spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
                adaptativeNodeSpotlightRadius: false, // specify whether the spotlight radius should adapt to the node size
                minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
                maxSpotlightRadius: 38, // the maximum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
                openMenuEvents: 'cxttap', // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
                itemColor: 'white', // the colour of text in the command's content
                itemTextShadowColor: 'transparent', // the text shadow colour of the command's content
                zIndex: 9999, // the z-index of the ui div
                atMouse: false, // draw menu at mouse position
                outsideMenuCancel: false // if set to a number, this will cancel the command if the pointer is released outside of the spotlight, padded by the number given 
            };
            
            //if (typeof cytoscape('core', 'cxtmenu') !== 'function') {
            //   cy.cxtmenu(defaults);
            // }
            
            //cy.cxtmenu(defaults);
            
            this.cyObj = cy;
            
        },

        async getMap_OLD() {
            let datas = [];

            var oNode = this.userTopic.nodes;
            console.log(oNode);
            if(oNode!=null && oNode.length>0)
            {
                for (let i = 0; i < oNode.length; i++)
                {
                    datas.push({
                        group: 'nodes',
                        data: oNode[i]
                    })
                }
                //console.log(datas);
            };
            var oEdges = this.userTopic.edges;
            console.log(oEdges);
            if(oEdges!=null && oEdges.length>0)
            {
                for (let i = 0; i < oEdges.length; i++)
                {
                    datas.push({
                        group: 'edges',
                        data: oEdges[i]
                    })
                }
                //console.log(datas);
            } 

            console.log("data",datas);
            this.dataNodes = datas;
        },   
        
        // 此功能, 有考慮到畫面上所選取的詞性, 以及sliderValue 的限制
        async getMap() {
          let datas = [];
          let exceptNodes = [];
          // 要考慮畫面上所選取的詞性..
          // this.selectedPos
          var oNode = this.userTopic.nodes;
          
          if(oNode!=null && oNode.length>0)
          {
            console.log("selectedPos", this.selectedPos);
            var newNode = [];
            
            if( this.selectedPos.length > 0 ){
              for(let i=0; i<this.selectedPos.length; i++)
              {
                var sPos = oNode.filter(x=>x.pos==this.selectedPos[i]).forEach(y=> newNode.push(y));
              };
            }
            else
            {
              newNode = oNode;
            }


            // 在加上this.sliderValue 的限制
            let sum = 0;
            newNode.forEach(x=> sum = sum + parseInt(x.TF) );
            let avg = sum / newNode.length;        
            if( this.sliderValue==50) { //適中
              newNode = newNode.filter(x=> x.TF <= avg );
            }
            if( this.sliderValue < 50) {
                newNode = newNode.filter(x=> x.TF > avg );
            }
            
            
            for (let i = 0; i < newNode.length; i++)
            {          
              datas.push({
                  group: 'nodes',
                  data: newNode[i]
              })
            }
            exceptNodes = oNode.filter( x=> ! newNode.includes(x));
          };
          //console.log("exceptNodes", exceptNodes);

          
          var oEdges = this.userTopic.edges;
          if(oEdges!=null && oEdges.length>0)
          {
            for (let i = 0; i < oEdges.length; i++)
            {
              //var jud = this.filterNode(oEdges[i], exceptNodes);
              if(this.filterNode(oEdges[i], exceptNodes))
              {
                datas.push({
                    group: 'edges',
                    data: oEdges[i]
                })
              }
            }
          }      

          //console.log("datas count",datas.length);
          this.dataNodes = datas;
        },

        filterNode(edge , exc){
          if( exc.length > 0)
          {
            var s = exc.find(x=> x.id == edge.source);
            var t = exc.find(x=> x.id == edge.target);
            var result = (s==undefined && t==undefined);
            return result;
          }
          else
            return true;
        },        


        layoutRedraw() {
            var layout = this.cyObj.layout({
                    name: 'd3-force',
                    animate: true,
                    fixedAfterDragging: false,
                    linkId: function id(d) {
                        return d.id;
                    },
                    linkDistance: 200,
                    manyBodyStrength: -300,
                    randomize: false,
                    infinite: true
                });
            // this.cyObj.minZoom(0.1);
            // layout.on ('layoutstop', () => { 
            //     this.cyObj.minZoom(this.cyObj.zoom()); 
            //     this.cyObj.panzoom('destroy'); 
            //     this.cyObj.panzoom(Object.assign({ minZoom : this.cyObj.zoom() }, relateConfig.panzoomDefaults,)); 
            // });
            console.log(this.cyObj);
            // this.cyObj.panZoom();

            layout.run(); 
        },                

        // 產生selectedPos 目前畫面上選取的詞性:
        handleCheckedPosChange(value) {
          console.log(value);
            let checkedCount = value.length;
            this.selectedPos = [];
            for(let i=0;i<value.length; i++ ) {
                let sPos = this.posChecksList.find( x=>{
                    return x.title==value[i];
                });
                this.selectedPos.push(sPos.pos);
            }; 
            this.refreshCy();
        },  

        /* 前往新聞詳細頁 */
        goDetailPage(data) {
            let routeUrl = this.$router.resolve({
                path: "/detailNews",
                query: { id: data.NewsID },
            });
            window.open(routeUrl.href, "_blank");
        },         

    },

    async mounted() {
        
        this.listQuery.topic = this.$route.query.topicId;
        // console.log(this.$route.query);
        await this.getTopicInfo(); // get Topic Info
        await this.getFolderList(true);
        await this.getTopicList(true);     
        await this.getList(); // Layout Entitiy
    },
}
</script>

<style lang="scss" scope>
// * {
//   outline: red solid 1px;
// }

#cy {
    height: 100%;
    width: 100%;
    display: block;
    background-color: rgb(255, 220, 236);
    // box-shadow: 0.5rem 0.25rem 0.3rem rgba(0, 0, 0, 0.5);
    border:#888 1px solid;
}

// .el-tabs__item {
//   height: 100px !important;
// }
svg {
  margin-right: 5px;
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: gray;
  //width: 28%;
  border:  1px dotted gray;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin: 0;
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: #191970;
  color: white;
}

.el-tabs__content {
  max-height: 300px;
  padding: 5px 10px !important;
  margin: 0px 0px !important;
  overflow-y: auto;
}

.el-tag.el-tag--info {
  margin: 1px 3px;
}



.projNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 30px;
  width: 90%;
}




.topicBox {
  background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  display: flex;
  flex-direction: column;

  &__searchBox {
    display: flex;
    flex-direction: row;    
    justify-content: flex-start;
    padding: 0px 5% 15px 5%;   

    label {
      font-weight: 500;
      color: #2a2a2a;
      // letter-spacing: 2px;
      font-size: 1.1rem;
      margin: 0px 20px 0px 0px;
    }

    &--theme {
      margin-left: 20px;
    }


  }

  &__listBox {
    display: flex;
    flex-direction: row;    
    justify-content: flex-start;
    padding: 0px 5%;   

    &--controlbar {
      display: flex;
      width: 100%;
      justify-content: space-between;
      &--pos {
        display: flex;
        width: 40%;
        justify-content: flex-start;
        align-items: center;

        .el-checkbox {
          margin: 0 5px 0px 5px;
        }
      }

      &--slider{        
        width:30%;
        justify-content: flex-start;
      }

      &--Operation {
        display: flex;
        flex-direction: row;
        width:30%;      
        justify-content: flex-end;
      }      
    }

    &--goDetailPage {
      color: #0645ad;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .graphBox {
    display: flex;
    flex-direction: row;      
    padding: 0px 5%;
    width: 90%;  
    max-height: 380px;
    overflow-y:hidden;
    background-color: transparent;
    justify-content: space-between;

    &__leftbox {
      width: 60%;
      height: 350px;
    }
    &__rightbox {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;    
      width: 35%;

    }
  }    
  
  .newsTable {
    display: flex;
    flex-direction: column;
    padding: 0px 5%;    
  }  
}




#infoDetail {
  width: 95%;
  height: 100vh;
  min-height: 1024px;
  padding: 0px 30px;
  position: relative; 


  .detailPage {
    &__leftBox {
      width: 100%;
      padding: 0px;
      box-sizing: border-box;

      &--newsInfo {
        width: 100%;
        display: flex;
        font-size: 1.3rem;
        margin-bottom: 30px;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        label {
          padding-bottom: 8px;
        }
      }

      &--newsContent {
        width: 100%;
        padding: 18px;
        font-size: 1.25rem;
        line-height: 35px;
        box-sizing: border-box;
        min-height: 300px;

        overflow-y: auto;
        border: 1px solid lightgreen;
        box-sizing: border-box;

        p {
          margin: 0;
          letter-spacing: 1.6px;
          line-height: 1.6rem;
        }

        .markKeyWord {
          width: 100%;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &__markIcon {
            padding: 8px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            svg {
              padding: 0 8px;
              transition: 0.4s;
              cursor: pointer;

              &:nth-child(1) {
                color: #4dffff;
                filter:drop-shadow(2px 2px 1px grey);
              }
              &:nth-child(2) {
                color: #79ff79;
                filter:drop-shadow(2px 2px 1px grey);
              }
              &:nth-child(3) {
                color: #ffe66f;
                filter:drop-shadow(2px 2px 1px grey);
              }
              &:nth-child(4) {
                color: #ff95ca;
                filter:drop-shadow(2px 2px 1px grey);
              }

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }

        .nation > mark {
          background: rgb(187, 255, 255) !important;
          font-weight: 700;
          font-size:1.2rem;
        }
        .place > mark {
          background: rgb(187, 255, 187) !important;
          font-weight: 700;
          font-size:1.2rem;
        }
        .titlePeople > mark {
          background: rgb(255, 255, 206) !important;
          font-weight: 700;
          font-size:1.2rem;
        }
        .org > mark {
          background: rgb(255, 217, 236) !important;
          font-weight: 700;
          font-size:1.2rem;
        }
      }
    }

    &__rightBox {
      display: flex;
      flex-direction: column;      
      width: 90%;
      padding: 8px;
      margin-top: -20px;
      box-sizing: border-box;

      &--tagBox {
        width: 100%;
        border: 1px solid #eee;
        border-radius: 4px;
        margin-bottom: 15px;
        margin-top: 15px;
        box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.5);

        &:last-child {
          margin: 0;
        }

        .header {
          width: 100%;
          padding: 8px;
          box-sizing: border-box;
          border-bottom: 3px solid #eee;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #191970;

          i {
            padding: 4px 8px;
            font-weight: bold;
            color: #191970;
            transition: 0.4s;
            cursor: pointer;

            &:hover {
              transform: scale(1.3);
            }
          }
        }

        .body {
          width: 100%;
          padding: 16px;
          box-sizing: border-box;

          .el-tag {
            margin: 0 4px 4px 0;

            &:last-child {
              margin-right: 0;
            }
          }

          &__noData {
            padding-bottom: 4px;
            margin: 0;
            font-size: 13px;
          }
        }
      }

      &--tag {
        padding: 4px 0;
        .collapseTag {
          padding: 4px 8px;
          background: #eee;
          border-radius: 10px;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &__footer {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>