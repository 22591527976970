<template>
  <div class="home">
    <Breadcrumb :title="$route.meta.title" />    

    <div class="tabsBox" id="contentBody" style="line-height:30px;">
      <el-tabs tab-position="top" type="border-card" style="background:transparent;width:90%;height: 100%;"  stretch >
        <el-tab-pane style="overflow-y: auto;" >
                <span slot="label" ><i class="el-icon-date" style="margin-right:20px;"></i>金融同業新聞</span> 
                <el-table style="width: 100%" ref="multipleTable" :data="tableData"  :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}"  empty-text="暫無數據" >
                <el-table-column type="selection" width="50" ></el-table-column>
                <el-table-column prop="NewsTitle" label="新聞標題" min-width="30%">
                  <template slot-scope="scope">
                    <a class="goDetailPage" @click="goDetailPage(scope.row)">{{scope.row.NewsTitle }}</a>
                  </template>
                </el-table-column>
                <el-table-column prop="FinList" label="金融同業" min-width="30%">
                </el-table-column>                  
                <el-table-column prop="NewsTime" label="新聞時間" min-width="10%">
                  <template slot-scope="scope">
                    <div>{{ scope.row.NewsTime | moment("YYYY-MM-DD") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="NewsSite" label="新聞網站" min-width="20%"></el-table-column>
                <el-table-column prop="NewsChannel" label="新聞頻道" min-width="10%"> </el-table-column>
              </el-table>
              <Pagination @currentChange="pageChange" :pageSize="Page_Size" :propsCurrentPage="Page_Num" :totalLists="totalLists"> </Pagination>   
        </el-tab-pane>
        <el-tab-pane style="overflow-y: auto;"  >
              <span slot="label" ><i class="el-icon-reading" style="margin-right:20px;"></i>專卷分析管理</span>
              <div class="filterWrap">     
                <div class="itemWrap" style="width:25%">
                  <label>專卷：</label>
                  <el-select v-model="listQuery.folder" clearable placeholder="請選擇專卷名稱" no-data-text="目前無名單" @change="changeFolder">                                   
                      <el-option key="99" label="請選擇專卷" value="" ></el-option>    
                      <el-option v-for="(y,index) in folerList" :key="index" :label="y.FolderCategory" :value="y.FolderID" ></el-option>                                       
                  </el-select>
                </div>
                <div class="itemWrap" style="width:25%">
                  <label>主題：</label>
                  <el-select v-model="listQuery.topic" clearable placeholder="請選擇主題名稱" no-data-text="目前無名單">              
                      <el-option key="99" label="全部" value="" ></el-option>    
                      <el-option v-for="(y,index) in topicList" :key="index" :label="y.TopicName" :value="y.TopicID" ></el-option>                                       
                  </el-select>
                </div>
                <div class="itemWrap" style="width:25%">
                  <el-button type="danger" @click="searchTopicNews">查詢</el-button>
                </div>
              </div>
              <el-table style="width: 100%" ref="multipleTable" :data="tableData1"  :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}"  empty-text="暫無數據" >
                <!-- <el-table-column type="selection" width="50" ></el-table-column>  -->
                <el-table-column prop="NewsTitle" label="新聞標題" min-width="30%" >
                  <template slot-scope="scope">
                    <a class="goDetailPage" @click="goDetailPage(scope.row)">{{scope.row.NewsTitle }}</a>
                  </template></el-table-column>
                  <el-table-column prop="TbbList" label="既有客戶"  min-width="30%">
                </el-table-column>                    
                <el-table-column prop="NewsTime" label="新聞時間" min-width="10%">
                  <template slot-scope="scope">
                    <div>{{ scope.row.NewsTime | moment("YYYY-MM-DD") }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="NewsSite" label="新聞網站" min-width="20%"></el-table-column>
                <el-table-column prop="NewsChannel" label="新聞頻道" min-width="10%"> </el-table-column>
              </el-table>
              <Pagination @currentChange="pageChange1" :pageSize="Page_Size1" :propsCurrentPage="Page_Num1" :totalLists="totalLists1"> </Pagination>  
        </el-tab-pane>
      </el-tabs>
        
    </div>


  </div>
</template>
<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from "../components/Breadcrumb.vue";
import moment from "moment";


export default {
  components: {Breadcrumb,Pagination},
  data() {
    return {
      sAuthCode:"",
      start_date: moment().add(-3, "days").format("YYYY-MM-DD"),
      end_date:moment().format("YYYY-MM-DD"),
      Page_Size: 10,
      Page_Num: 1,      
      Page_Size1: 10,
      Page_Num1: 1,      
      tableData: [],    
      tableData1: [],  
      totalLists: 0, 
      totalLists1: 0,     
      selectData: [],
      selectPage: [],
      un: this.$store.state.userInfo.UserName,
      folerList: [],
      topicList: [],
      listQuery: {
        folder: "",
        topic: "",
      },
    };
  },
  computed: {

  },
  methods: {

    async searchTopicNews() {
      if(this.listQuery.folder == "") {
        this.$notify({
                title: "請選擇專卷",
                message: "請選擇專卷",
                type: "warning",
              });
        return;
      }
      this.getList1(false);
    },

    async changeFolder() {
      if ( this.listQuery.folder == "" ) {
        this.$notify({
                title: "請選擇專卷",
                message: "請選擇專卷",
                type: "warning",
              });
        return;
      }
      await this.getTopicList(true);      
    },

    async getTopicList(isSys)
    {
      this.$store.dispatch("loadingHandler", true);
      this.topicList = [];

      let req = {Page_Size: 100, Page_Num: 1, FolderID: this.listQuery.folder };     
      if ( !isSys ) 
        req.isSys = false;
      else 
        req.isSys = true;
      console.log(req);

      await this.$api.folder.searchTopic(req).then((res)=>{                
        console.log(res);        
        this.topicList = res.data;
        this.totalLists = res.total;
        console.log(this.topicList);        
      });        

      this.$store.dispatch("loadingHandler", false);
    },

    async getFolderList(isSys) {
      this.folerList = [];

      let req = {Page_Size: 100, Page_Num: 1 };     
      if ( !isSys ) 
        req.isSys = false;
      else 
        req.isSys = true;      

      await this.$api.folder.searchFolder(req).then((res)=>{        
        this.folerList = res.data;
        this.totalLists = res.total;      
      });
    },

    accMgt() {
      this.$router.push("/account-management");
    },
    authMgt() {
      this.$router.push("/auth-management");
    },

    showAll() {

    },
 

    goDetailPage(data) {
        console.log(data);
        let routeUrl = this.$router.resolve({
            path: "/detailNews",
            query: { id: data.DataID },                
        });
        window.open(routeUrl.href, "_blank");
    }, 

    async getList(isSys) {
      this.$store.dispatch("loadingHandler", true);
      this.tableData = [];

      let req = {Page_Size: this.Page_Size, Page_Num: this.Page_Num, Start_Date: this.start_date, End_Date: this.end_date, NewsType: 1 };     
      if ( !isSys ) 
        req.isSys = false;
      else 
        req.isSys = true;     

      await this.$api.apimgrs.searchNews(req).then((res)=>{        
        this.tableData = res.data;
        this.totalLists = res.total;              
      });


      this.$store.dispatch("loadingHandler", false);
    },


    async getList1(isSys) {
      this.$store.dispatch("loadingHandler", true);
      this.tableData1 = [];

      let req = {Page_Size: this.Page_Size1, Page_Num: this.Page_Num1, FolderID: this.listQuery.folder };     
      if ( this.listQuery.topic != "" ) 
        req.TopicID = this.listQuery.topic;

      console.log(req);


      if ( !isSys ) 
        req.isSys = false;
      else 
        req.isSys = true;

      console.log(req);

      await this.$api.folder.searchTopicNews(req).then((res)=>{                
        // console.log(res);        
        this.tableData1 = res.data;
        this.totalLists1 = res.total;
        console.log(this.tableData1);        
      });      


      this.$store.dispatch("loadingHandler", false);
    },    



    pageChange(val) {
      this.$store.dispatch("loadingHandler", true);
      // console.log('pageChange')
      this.Page_Num = val;
      this.getList(true);
      this.$store.dispatch("loadingHandler", false);
    },  

    pageChange1(val) {
      this.$store.dispatch("loadingHandler", true);
      // console.log('pageChange')
      this.Page_Num1 = val;
      this.getList1(true);
      this.$store.dispatch("loadingHandler", false);
    },  


  },
  async mounted() {
    // const start = Date.now();
    this.$store.dispatch("loadingHandler", true);
    await this.getList(true);
    this.getFolderList(true);
    this.$store.dispatch("loadingHandler", false);

  },

};
</script>
<style lang="scss" scoped>

.home {
  height:150vh;
  background-image: linear-gradient(to bottom,#FFF 55% ,#fff 80% , transparent 50% );
  background-size: cover;
  height: 100vh;

  .welcomeBox {
    margin: 0px 0px;
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center; 
  }

  .tabsBox {
        display: flex;
        flex-direction: row;
        margin: 0px 0px;
        // padding: 20px 20px;  
        width: 100%;
        justify-content: center;
    }

    .goDetailPage {
      color: #0645ad;
      text-decoration: underline;
      cursor: pointer;
   } 

  .listBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0px 0px;
    padding: 20px 5%;

    .functionButton {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 80%;
      padding: 3% 13%;

      .el-button {
        width: 100%;
      }

    }


  }

  .filterWrap{

    display: flex;
    flex-direction: row;
    padding: 0 5%;
    margin-bottom: 2%;

    .itemWrap{
        margin: 5px 10px;
        // width: 500px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        label{
            white-space:nowrap;
        }
        &--add {
            display: flex;
            flex-direction: row;
            width: 90%;
            justify-content: flex-end;
            margin-bottom: 20px;
        }

    }
  }  

  
}
::v-deep .yaxisTest{
    // font-size: 99px
    cursor: pointer;
  }
</style>
