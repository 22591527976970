import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/BIDSHome",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      hideMenu: true,
    },
  },

  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      hideMenu: true,
    },
  },  

  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
    meta: {
      hideMenu: true,
    },
  },  

  {
    path: "/logoutauto",
    name: "logoutauto",
    component: () => import("../views/Logoutauto.vue"),
    meta: {
      hideMenu: true,
    },
  },  

  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      hideMenu: false,
    },
  },

  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
    meta: {
      title: "網頁找不到",
      hideMenu: true,
    },
  },

  /*公司拓墣圖*/
  {
    path: "/Topology",
    name: "Topology",
    component: () => import("../views/comTopo.vue"),
    meta: {
      title: "公司關聯分析",
      hideMenu: true,
    },
  }, 

  /*新聞詳細頁*/
  // {
  //   path: "/detailNews",
  //   name: "detailNews",
  //   component: () => import("../views/detailNews.vue"),
  //   meta: {
  //     hideMenu: true,
  //   },
  // },  



  {
    path: "/account-mgmt",
    name: "account-mgmt",
    component: () => import("../views/accMgt.vue"),
    meta: {
      title: "帳號管理",
      hideMenu: false,
    },
  },

  /*專卷查詢*/
  {
    path: "/project-search",
    name: "project-search",
    component: () => import("../views/projSearch.vue"),
    meta: {
      title: "專卷查詢",
      hideMenu: false,
    },
  },  

  /* 關聯分析 */
  {
    path: '/topicTopology',
    name: 'topicTopology',
    component: () => import('../views/topicTopology.vue'),
    meta: {
      title: "專卷關聯分析",
      hideMenu: true
    }
  },  

  /*專卷維護*/
  {
    path: "/projec-mgmt",
    name: "projec-mgmt",
    component: () => import("../views/projMgt.vue"),
    meta: {
      title: "專卷維護",
      hideMenu: false,
    },
  },  

  { /* 金融財金新聞查詢 */
    path: "/bizNews",
    name: "bi-news-search",
    component: () => import("../views/biNewsSearch.vue"),
    meta: {
      title: "金融財金新聞查詢",
      hideMenu: false,
    },
  },

  /*新聞詳細頁*/
  {
    path: "/detailNews",
    name: "detailNews",
    component: () => import("../views/detailNews.vue"),
    meta: {
      title: "商情新聞明細",
      hideMenu: true,
    },
  },  

  { /* 企業關聯查詢 */
    path: "/comRel",
    name: "com-rel-search",
    component: () => import("../views/comRelSearch.vue"),
    meta: {
      title: "企業關聯查詢",
      hideMenu: false,
    },
  },

  /*顯示關聯公司資訊*/
  {
    path: "/RelComInfo",
    name: "RelComInfo",
    component: () => import("../views/RelComInfo.vue"),
    meta: {
      title: "關聯企業資訊",
      hideMenu: true,
    },
  },    

  /* 公司明細頁 */
  {
    path: "/companyDetail",
    name: "companyDetail",
    component: () => import("../views/companyDetail.vue"),
    meta: {
      title: "公司明細資訊",
      hideMenu: true,
    },  
  },  
  /* 國外公司明細頁 */
  {
    path: "/companyDetailForeign",
    name: "companyDetailForeign",
    component: () => import("../views/companyDetailForeign.vue"),
    meta: {
      title: "國外公司明細資訊",
      hideMenu: true,
    },  
  },    

  /*即時發查*/
  {
    path: "/immediateSearch",
    name: "immediate-search",
    component: () => import("../views/imsearch.vue"),
    meta: {
      title: "即時商情發查",
      hideMenu: false,
    },
  },

  { /* 產業關聯查詢 */
    path: "/indusSearch",
    name: "indus-rel-search",
    component: () => import("../views/indusSearch.vue"),
    meta: {
      title: "產業關聯查詢",
      hideMenu: false,
    },
  },  

  { /* 上市基本資料 */
    path: "/comBasicInfo",
    name: "com-basic-search",
    component: () => import("../views/comBasicInfo.vue"),
    meta: {
      title: "上市櫃公司基本資料查詢",
      hideMenu: false,
    },
  },    

  { /* 上市財務資料 */
    path: "/comFinInfo",
    name: "com-fin-search",
    component: () => import("../views/comFinInfo.vue"),
    meta: {
      title: "上市櫃公司財務指標查詢",
      hideMenu: false,
    },
  },    

  /*管理報表__系統登入記錄*/
  {
    path: "/login-record",
    name: "login-record",
    component: () => import("../views/sysLoginLog.vue"),
    meta: {
      title: "系統登入記錄",
      hideMenu: false,
    },
  },
  /*管理報表__系統操作記錄*/
  {
    path: "/operate-record",
    name: "operate-record",
    component: () => import("../views/sysOperateLog.vue"),
    meta: {
      title: "系統操作記錄",
      hideMenu: false,
    },
  },

  
  /*管理報表__API使用狀況統計表*/
  {
    path: "/api-summary",
    name: "api-summary",
    component: () => import("../views/apiSummary.vue"),
    meta: {
      title: "API使用狀況統計表",
      hideMenu: false,
    },
  },

  /*API上架資訊__API上架資訊查詢*/
  {
    path: "/apiQuery",
    name: "api-query",
    component: () => import("../views/apiQuery.vue"),
    meta: {
      title: "API上架資訊查詢",
      hideMenu: false,
    },
  },
   




  /*稽核軌跡紀錄及查詢__API發查申請及結果查詢*/
  {
    path: "/applyApi",
    name: "realtime-query",
    component: () => import("../views/apiApply.vue"),
    meta: {
      title: "API發查申請及結果查詢",
      hideMenu: false,
    },
  },


  /*授權與認證*/
  {
    path: "/auth-management",
    name: "auth-mgmt",
    component: () => import("../views/authManage.vue"),
    meta: {
      title: "即時發查權限管理",
      hideMenu: false,
    },
  },


  /*角色及功能權限設定*/
  {
    path: "/roleFuncMgmt",
    name: "role-mgmt",
    component: () => import("../views/roleFuncMgmt.vue"),
    meta: {
      title: "角色及功能權限管理",
      hideMenu: false,
    },
  },
  
  /*其他的路由設定*/
  {
    path:"*",
    redirect:"/404"
  },


];

const router = new VueRouter({
  mode: 'history',
  routes,
});


// 讓每一換頁, 一律捲到最上面...
router.afterEach((to, from) => {
  const main = document.querySelector('.el-main')
  main.scrollTo(0, 0)
})

export default router;
