<template>
    <div class="accountManage">
      <Breadcrumb :title="$route.meta.title" />      
      <div class="accountManage__listBox">
        <div class="accountManage__listBox--add" >
          <el-button type="primary" @click="setAccountFunc('add')"><font-awesome-icon icon="plus"  style="margin-right:5px;" />新增帳號</el-button>
          <!-- <span @click="setAccountFunc('add')">
            <i class="el-icon-plus"></i>
            <a>新增</a>
          </span> -->
        </div>      
        <div class="accountManage__listBox--Content">
          <el-table :data="tableData" ref="multipleTable" style="width: 100%" :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}"  empty-text="暫無數據">
            <el-table-column type="index" label="序號" width="60" ></el-table-column>
            <el-table-column label="使用者名稱" prop="UserName" min-width="40%"></el-table-column>
            <!-- <el-table-column label="部門" prop="DeptName" min-width="40%"></el-table-column>
            <el-table-column label="使用者群組"  width="150">
              <template slot-scope="scope">
                {{ scope.row.Role=="admin" ? "管理者" : "使用者" }}
              </template>            
            </el-table-column> -->
            <el-table-column label="啟用時間" min-width="40%">
              <template slot-scope="scope">
                {{ scope.row.CreateDt | moment("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="20%">
              <template slot-scope="scope">
                <div class="accountManage__listBox--Content--userFunc" >
                  <el-tooltip effect="dark" content="刪除帳號" placement="left" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button type="text" @click="setAccountFunc('del', scope.row)">
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </el-tooltip>                                  
                  <el-tooltip effect="dark" content="編輯帳號" placement="top" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button type="text" @click="setAccountFunc('edit', scope.row)">
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </el-tooltip>  
                  <el-tooltip v-if="scope.row.UserName==UserName" effect="dark" content="修改密碼" placement="right" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button type="text" @click="setAccountFunc('pass', scope.row)">
                      <i class="el-icon-key"></i>
                    </el-button>
                  </el-tooltip>                                  
                  <el-tooltip effect="dark" content="產生金鑰" placement="top" style="color:#000;text-shadow:2px 2px 2px grey" >
                    <el-button @click.native.prevent="changeTokenModal(scope.row)" style="background-color:transparent;border:0px;" >
                      <i class="el-icon-more"></i>
                      <!-- <font-awesome-icon icon="key" /> -->
                    </el-button>                 
                  </el-tooltip>                    
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @currentChange="pageChange" :pageSize="Page_Size" :propsCurrentPage="Page_Num" :totalLists="totalLists"> </Pagination>          
        </div>
      </div>

    <!-- 新增或編輯帳號 -->
    <el-dialog :title="setAccountTitle" :visible.sync="setAccount" width="60%" center>
      <el-form :model="userQuery" :rules="rules_setAccount" ref="ruleForm_setAccount" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="使用者名稱" prop="UserName"  >
              <el-input v-model="userQuery.UserName"  ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用者部門" prop="DeptID">
              <el-select v-model="userQuery.DeptID" placeholder="請選擇部門">
                <el-option v-for="dept in deptList" :label="dept.DeptName" :value="String(dept.ID)" :key="dept.ID"></el-option>                
              </el-select>                    
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="使用者帳號" prop="Account"  >
              <el-input v-model="userQuery.Account"  ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="setAccountMode!='edit'">
            <el-form-item label="密碼" prop="Password">
              <el-input v-model="userQuery.Password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="setAccountMode!='edit'">
            <el-form-item label="再次輸入" prop="rePassword">
              <el-input v-model="userQuery.rePassword"></el-input>
            </el-form-item>
          </el-col>          
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="使用者角色" prop="RoleID"  >
              <el-select multiple v-model="userQuery.RoleID" placeholder="請選擇角色">
                <el-option v-for="role in roleList" :label="role.Name" :value="String(role.ID)" :key="role.ID"></el-option>                
              </el-select>              
              <!-- <el-input v-model="userQuery.RoleID"  ></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="聯絡資訊" prop="Contact">
              <el-input v-model="userQuery.Contact"></el-input>
            </el-form-item>
          </el-col>
        </el-row>        
        <el-form-item label="備註" prop="Memo">
          <el-input type="textarea" v-model="userQuery.Memo"  :autosize="{ minRows: 2, maxRows: 4}" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addUser()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button>
        <el-button type="danger" @click="setAccount = false"><font-awesome-icon icon="times" style="margin-right:5px;" />取消</el-button>
      </span>
    </el-dialog>
    
    <!-- 變更密碼 -->
    <el-dialog title="產生密碼" :visible.sync="changePWD_func" width="60%" center>
      <el-form :model="ruleForm" :rules="rules_pwd" ref="ruleForm" label-width="200px">
        <el-form-item label="請輸入舊密碼" prop="oldPWD">
          <el-input v-model="ruleForm.oldPWD" show-password></el-input>
        </el-form-item>
        <el-form-item label="請輸入新密碼" prop="newPWD">
          <el-input v-model="ruleForm.newPWD" show-password></el-input>
        </el-form-item>
        <el-form-item label="請再次輸入新密碼" prop="checkPWD">
          <el-input v-model="ruleForm.checkPWD" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        
        <el-button type="primary" @click="changePWD()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button>
        <el-button type="danger" @click="changePWD_func = false"><font-awesome-icon icon="times"  style="margin-right:5px;" />取消</el-button>
      </span>
    </el-dialog>     

    <!-- 變更金鑰 -->
    <el-dialog title="產生金鑰" :visible.sync="changeToken_func" width="60%" center>
      <el-form  label-width="100px"  >
        <el-form-item label="使用者名稱" prop="diaglog_userName">
          <el-input v-model="diaglog_userName" readonly></el-input>
        </el-form-item>

        <el-form-item label="金鑰(token)" prop="newPWD"   >
          <div style="display: flex;flex-direction: row;">
            <el-input v-model="tokengen" type="textarea" :rows="5" style="margin-right:20px;" readonly id="gToken"  ></el-input>
            <div class="buttonVert" style="display: flex;flex-direction: column;justify-content: space-around;">
              <el-button type="primary" @click="genToken()"  ><font-awesome-icon icon="key"  style="margin-right:5px;" />自動產生</el-button>
              <el-button type="primary" @click="copyToken()"  style="margin-left:0px" ><font-awesome-icon icon="copy"  style="margin-right:5px;" />複製剪貼簿</el-button>
            </div>
            
          </div>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
        
        <!-- <el-button type="primary" @click="changePWD()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button> -->
        <el-button type="danger" @click="changeToken_func = false"><font-awesome-icon icon="times"  style="margin-right:5px;" />取消</el-button>
      </span>
    </el-dialog>      
        
    </div>
    
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
import Pagination from "../components/Pagination.vue";
import { uuid } from 'vue-uuid'; 

import API from "../api/api";
export default {
  // name : 'account-management',
  components: {Breadcrumb,Pagination},
  data() {
    var addValidatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("此為必填欄位"));
      } else if (value !== this.userQuery.pass) {
        callback(new Error("兩次密碼輸入不一致"));
      } else {
        callback();
      }
    };
    var changePWDValidatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("此為必填欄位"));
      } else if (value !== this.ruleForm.newPWD) {
        callback(new Error("兩次密碼輸入不一致"));
      } else {
        callback();
      }
    }; 
    return {
      setAccount: false,
      setAccountTitle: "新增帳號",         
      setAccountMode:"add",   
      tableData: [],
      groupData: [],
      diaglog_userName:"",
      totalLists: 0,
      Page_Num: 1,
      Page_Size: 10,      
      /* 變更密碼 */
      changePWD_func: false,
      changeToken_func: false,
      ruleForm: {
        oldPWD: "",
        newPWD: "",
        checkPWD: "",
      },   
      rules_pwd: {
        oldPWD: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        newPWD: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        checkPWD: [
          {
            required: true,
            validator: changePWDValidatePass2,
            trigger: "blur",
          },
        ],
      },        
      UserID: "",
      UserName: "",
      tokengen:"",
      rules_setAccount: {
          UserName: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          RoleID: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          Account: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          Password: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          rePassword: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
      },        
      roleList: [],
      deptList: [],

      /* 新增帳號 */
      userQuery: {
        "UserName": "",
        "ComID": "",
        "DeptID": "",
        "RoleID": [],
        "Contact": "",
        "Memo": "",
        "Account": "",
        "Password": "",
        "rePassword": ""
      },

    };
  },
  computed: {

  },
  methods: {
    changePWDModal(id) {
      this.changePWDID = id;
      this.changePWD_func = true;
    },    
    changePWD() {
      const vm = this;
      vm.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const pwdQuery = {
            // Id: this.changePWDID,
            // UserId: JSON.parse(window.localStorage.getItem("userInfo")).userId,
            // 依照token中的userId, 所以不需要有UserId
            CurrentPassword: vm.ruleForm.oldPWD,
            Password: vm.ruleForm.newPWD,
          };
          this.$api.apimgrs.updatePassword(pwdQuery).then((res) => {
            console.log(res);
            this.$notify({
              title: "成功",
              message: "修改成功",
              type: "success",
            });
            this.changePWD_func = false;
          });
        }
      });
    },

    async getRoleList() {
      const qry = {
        "Page_Size": 100,
        "Page_Num": 1,
        "isSys": true
      }
      await this.$api.apimgrs.getRoleList(qry).then((res)=>{        
        this.roleList = res.data;
        // console.log(this.roleList);
      });
    },

    async getDeptList() {
      const qry = {
        "Page_Size": 100,
        "Page_Num": 1,
        "isSys": true
      }
      await this.$api.apimgrs.getDeptList(qry).then((res)=>{        
        this.deptList = res.data;
        // console.log(this.deptList);
      });
    },


    async genToken() {
      let req = 
        {
          "UserID": this.UserID,
          "Expire": "permanent"
        }
        this.$store.dispatch("loadingHandler", true);
        console.log(req);
        await this.$api.apimgrs.genToken(req).then((res)=>{
          this.tokengen = res.access_token
          console.log(this.tokengen);
          // navigator.clipboard.writeText(this.tokengen)
          // .then(()=>{
          //     this.$notify({
          //     title: "成功",
          //     message: "已產生到剪貼簿",
          //     type: "success",
          //   });
          // })
          // .catch(()=>{
          //   //   this.$notify({
          //   //   title: "失敗",
          //   //   message: "無法複製到剪貼簿",
          //   //   type: "error",
          //   // });
          // });

        });
        this.$store.dispatch("loadingHandler", false);
    },
   
    async copyToken() {

        if( this.tokengen.length==0) {
          this.$notify({
            title: "通知",
            message: "請先按自動產生Token",
            type: "information",
          });
          return;
        }

        // console.log(this.tokengen);
        var copyText = document.getElementById("gToken").value;
        console.log(copyText);
        // Select the text field
        
        // https://stackoverflow.com/questions/71873824/copy-text-to-clipboard-cannot-read-properties-of-undefined-reading-writetext
        //navigator.clipboard.writeText(this.tokengen)

        try {
           navigator.clipboard.writeText(copyText)
            .then(()=>{
                this.$notify({
                title: "成功",
                message: "已產生到剪貼簿",
                type: "success",
              });
            })
        } catch (e) {
            console.log(e);
            this.$notify({
              title: "失敗",
              message: "由於不支援https, 因此無法支援複製到剪貼簿",
              type: "error",
            });
        }

        // if (window.isSecureContext && navigator.clipboard) {
        //   alert('安全');
        //   navigator.clipboard.writeText(content);
        // } else {
        //   alert('不安全');
        //   unsecuredCopyToClipboard(content);
        // }


    
    },

  unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  },
  
    async pageChange(val) {
      this.$store.dispatch("loadingHandler", true);
      this.Page_Num = val;
      await this.getList(true);
      this.$store.dispatch("loadingHandler", false);
    },    
    formValidate(form) {
      // let status = false;
      // this.$refs[form].validate((valid) => {
      //   if (valid) {
      //     status = true;
      //   }
      // });
      //return status;
      return true;
    },    


    // 新增或編輯使用者
    async addUser() {

 
      this.$store.dispatch("loadingHandler", true);

      
      if( this.setAccountMode=="add") {
        console.log("新增userQuery",this.userQuery);
        await this.$api.apimgrs.addUser(this.userQuery).then((res)=>{
          console.log(res);
          this.$notify({
            title: "成功",
            message: "新增成功",
            type: "success",
          }); 
          this.getList(true); 
          // this.$store.dispatch("loadingHandler", false);
        }).catch(err=>{
            this.$notify.error({
            title: "錯誤",
            message: err,
            type: "Error"});
        });
        this.setAccount = false;
        
      }

      if( this.setAccountMode=="edit") {

        console.log("編輯userQuery",this.userQuery);
        this.userQuery.LastMADt = Date.now();        
        await this.$api.apimgrs.updateUser(this.userQuery).then((res)=>{
          // console.log(res);
          this.$notify({
            title: "成功",
            message: "編輯成功",
            type: "success",
          });
          this.getList(true); 
          this.$store.dispatch("loadingHandler", false);                  
        }).catch(err=>{
            this.$notify.error({
            title: "錯誤",
            message: err,
            type: "Error"});
        });
        this.setAccount = false;
        
      }
      this.$store.dispatch("loadingHandler", false);


    },    


    async delUser(userid) {
      this.$store.dispatch("loadingHandler", true);
      
      const delUserId = {
        UserID : userid
      };
      await this.$api.apimgrs.deleteUser(delUserId).then((res)=>{
        //console.log(res);
        this.$notify({
          title: "成功",
          message: "刪除成功",
          type: "success",
        });   
        this.getList(true);     
      });
      
      //await this.getList();

      this.$store.dispatch("loadingHandler", false);
    }, 

    async setAccountFunc(val, data) {
      if( ! this.formValidate("ruleForm_setAccount"))
      {
        
        return;
      }
         
      //console.log('op=', val)
      switch (val) {
        case "add":          
          this.setAccountMode = "add";
          this.setAccountTitle = "新增帳號";          

          let newuser = {
            //UserID: this.$store.state.userInfo.UserID,
            //UniComNm: this.$store.state.userInfo.UniComNm,
            UserID:"",
            ComName: "",
            UserName: "",
            Role: "",
            DeptName: "",
            Memo: "",            
            Account: "",
          };

          this.userQuery = newuser;
          this.setAccount = true;          
          
          break;
        case "edit":
          console.log(data);
          this.setAccountTitle = "編輯帳號";
          this.userQuery.UserName = data.UserName;
          this.userQuery.Account = data.Account;
          this.userQuery.Memo = data.Memo;
          this.userQuery.Contact = data.Contact;
          this.userQuery.UserID = data.UserID;
          this.userQuery.DeptID = data.DeptID;
          this.userQuery.RoleID = data.RoleID.split(",");
          this.setAccountMode ="edit";
          console.log(this.userQuery);  
          this.setAccount = true;
          break;
        case "pass":
          this.changePWD_func = true;
          break;
        case "del":
          console.log(data);
          this.$confirm("確定要刪除『" + data.UserName + "』嗎？", "提示", {
            confirmButtonText: "確定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
              // console.log("data",data);
              this.delUser(data.UserID);
              
            })
          .catch(() => {
              this.$notify({
                title: "失敗",
                message: "刪除失敗",
                type: "error",
              });  

          });          
          break;
      }
      
      
      
    },  
    changeTokenModal(item) {
      // console.log(item);
      this.UserID = item.UserID;

      this.diaglog_userName = item.UserName;
      this.changeToken_func = true;
      this.tokengen = "";
    },    
    async getList(isSys) {
      this.$store.dispatch("loadingHandler", true);
      this.tableData = [];
      // let _comID = window.localStorage.getItem("comID");
      //console.log(_comID);
      let req = {Page_Size: this.Page_Size, Page_Num: this.Page_Num };     
      // if( !!_comID){
      //   req.ComID = _comID;
      // }

      if ( !isSys ) 
        req.isSys = false;
      else 
        req.isSys = true;

      console.log(req);
      let temp = []
      await this.$api.apimgrs.getAllUser(req).then((res)=>{
        //console.log("getallUser",res);
        // res.data.forEach(x=>{    
        //   if(this.$store.state.userInfo.Role=="manager") {
        //     this.tableData.push(x);
        //   }
        //   else {
        //     if( x.UserName==this.$store.state.userInfo.UserName)
        //       this.tableData.push(x);
        //   }
        // });

        let adminCount = 0;
        // 不顯示admin 
        res.data.forEach(x=>{
          if( x.UserName!="admin") {
            temp.push(x);            
          }
          else {
            adminCount++;
          }
            
        });
        
        this.tableData = temp;
        this.totalLists = res.total - adminCount;
        //console.log(this.tableData);        
      });
      
      this.$store.dispatch("loadingHandler", false);
    },

    async getGroupList() {
      this.groupData = [];
      let _comID = this.$store.state.userInfo.ComID;
      let req = {Page_Size: this.Page_Size, Page_Num: this.Page_Num };     
      if( !!_comID){
        req.ComID = _comID;
      }
      await this.$api.apimgrs.getDeptInfo(req).then((res)=>{       
        this.groupData = res.data;
        // this.totalLists = res.total;
        // console.log(this.tableData);        
      });
      console.log(this.groupData);
    }
  },
  async mounted() {
    this.$store.dispatch("loadingHandler", true);
    //await this.getGroupList();
    this.getRoleList();
    this.getDeptList();
    await this.getList(true);
    // console.log(window.localStorage.getItem("userName"));
    
    //this.$store.loading = true;
    // console.log("userInfo",this.$store.state.userInfo);
    let userID = window.localStorage.getItem("userID")
    this.UserName = window.localStorage.getItem("userName")
    this.$store.dispatch("loadingHandler", false);
  },

};
</script>
<style lang="scss" scoped>
// * {
//   outline: red solid 1px;
// }
.accountManage {
  height:150vh;
  // background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  background-image: linear-gradient(to bottom,#FFF 55% ,#fff 80% , transparent 50% );
  
  background-size: cover;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;


  &__title {
    width: 100%;
    padding-top: 20px;
    text-align: center;

    strong {
      color: #191972;
      letter-spacing: 2px;
      font-size: 1.1rem;
    }
  }

  &__listBox {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--add {
      display: flex;
      flex-direction: row;
      width: 90%;
      justify-content: flex-end;
      margin-bottom: 20px;
    }

    &--Content {
      width: 90%;

      &--add {
        width: 100%;
        //padding: 0 30px;
        box-sizing: border-box;
        text-align: right;

        span {
          transition: 0.6s;
          cursor: pointer;
          i,
          a {
            font-size: 18px;
            font-weight: bold;
            color: #191972;
          }
          i {
            padding-right: 4px;
          }

          &:hover {
            letter-spacing: 2px;
          }
        }
      }

      &--userFunc {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
</style>
