<template>
    <div class="apiBox">
        <Breadcrumb :title="$route.meta.title" />    
        <div class="apiBox__listBox">
        <section class="filterWrap">
            <!-- 時間 -->        
            <div class="itemWrap">
            <label>查詢日期：</label>
            <el-date-picker :editable="true" type="daterange" value-format="yyyy-MM-dd" range-separator="至" 
                start-placeholder="開始日期" 
                end-placeholder="結束日期"
                v-model="rangeDate"
            ></el-date-picker>
            </div>
            <!-- 名單類型 -->
            <div class="itemWrap">
            <label>查詢單位：</label>
            <el-select v-model="listQuery.DeptName" clearable placeholder="請選擇單位" no-data-text="目前無名單">
                <el-option key="0" label="全部" value="all" ></el-option>                       
                <el-option v-for="item in deptList"
                :key="item.ID"
                :label="item.DeptName"
                :value="item.DeptName"
                ></el-option>
            </el-select>
            </div>
            <div class="itemWrap">
            <label>查詢Api：</label>
            <el-select v-model="listQuery.ApiID" clearable placeholder="請選擇單位" no-data-text="目前無名單" multiple>                                
                <el-option v-for="item in allApis" 
                :key="item.ID"
                :label="item.Name"
                :value="item.ID"
                ></el-option>
            </el-select>
            </div>            
            <div class="itemWrap">
            <el-button @click="doSearch" type="danger" class="searchLog"><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>
            </div>
        </section>             
        <div class="content">
            <el-table ref="multipleTable" border :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF'}" style="width: 100%"  empty-text="暫無數據" :data="tableData">
              <el-table-column label="Api名稱"  prop="Name" >
              </el-table-column>
              <el-table-column label="部門" prop="DeptName"  min-width="25%">  
              </el-table-column>
              <el-table-column label="使用者" prop="UserName"  min-width="25%">  
              </el-table-column>
              <el-table-column label="使用次數" prop="total"  min-width="15%">  
              </el-table-column>            
              <el-table-column label="操作" prop="EndPoint" width="150">
                <template slot-scope="scope">
                  <el-tooltip effect="dark" content="明細" placement="left" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button type="text" @click="showUsage(scope.row)">
                        <i class="el-icon-more"></i>
                    </el-button>  
                  </el-tooltip>            
                </template>
              </el-table-column>    
            </el-table>
        </div>
        <div class="pageBox">
          <Pagination
            @currentChange="pageChange"
            :pageSize="this.listQuery.Page_Size"
            :propsCurrentPage="this.listQuery.Page_Num"
            :totalLists="totalLists">
          </Pagination>
        </div>        
        </div>

        <el-dialog title="Api使用狀況" :visible.sync="listUsages" width="80%" center>
          <div style="display:flex; flex-direction:column; align-items:center;">
            <el-table ref="multipleTable" border :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF'}"  style="width: 100%"  empty-text="暫無數據" :data="tableUsage">
              <el-table-column label="單位"  prop="DeptName" min-width="40%">
                <template slot-scope="scope">
                  <div>{{ scope.row.DeptName?scope.row.DeptName:"無" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="使用者" prop="UserName"  min-width="40%">
                <template slot-scope="scope">
                  <div>{{ scope.row.UserName?scope.row.UserName:"無" }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作時間" prop="CreateDt" width="180">
                <template slot-scope="scope">
                  <div>{{ filterDate(scope.row.CreateDt) }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作內容" prop="UseCont" min-width="50%"></el-table-column>              
            </el-table>
          <div class="pageBox">
            <Pagination
              @currentChange="pageChange1"
              :pageSize="this.usageQuery.Page_Size"
              :propsCurrentPage="this.usageQuery.Page_Num"
              :totalLists="totalUsageLists">
            </Pagination>
          </div>             
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="listUsages = false"><font-awesome-icon icon="times" style="margin-right:5px;" />關閉</el-button>
          </span>
        </el-dialog>

    </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";

export default {

  components: { Breadcrumb ,Pagination},
  data() {
    return {
      rangeDate: [moment().add(-1, "days").format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
      tableData: [],
      tableUsage: [],
      totalLists: 0,
      allApis: [],
      deptList: [],
      totalLists: 0,
      totalUsageLists: 0,
      listUsages: false,
      usageQuery : {
        Page_Size:10,
        Page_Num:1        
      },
      listQuery: {
        DeptName: "all",        
        ApiID: [],
        selApi: "",
        Start_Date:"",
        End_Date:"",
        Page_Size:10,
        Page_Num:1
      },
    };
  },
  computed: {
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      };
    },
  },

  methods: {
    async doSearch(){
      await this.getList();
    },

    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.getList();
    },

    pageChange1(val) {
      this.usageQuery.Page_Num = val;
      this.getList();
    },    

    async showUsage(data) {
      //console.log(data);
      let req= { UserID: data.UserID, EndPoint: data.EndPoint, Page_Size: 10, Page_Num:1, Start_Date: this.rangeDate[0], End_Date: this.rangeDate[1] }

      let retResult = [];
      //console.log(req);
      await this.$api.apimgrs.actionRecord(req).then(res=>{
        this.tableUsage = res.data;
        //console.log(res);
        this.totalUsageLists = res.total;
      });
      //console.log(this.tableUsage);
      this.listUsages = true;
    },

    async getApiList() {

      let req= {Page_Size:100, Page_Num:1};
      //console.log(req);
      this.allApis = []
      await this.$api.apimgrs.getApi(req).then((res)=>{
        this.allApis = res.data;
        // this.totalLists = res.total;
      });

      //console.log(this.allApis);        

    },     
    async getDept() {

      let cn = this.$store.state.userInfo.ComName;
      console.log('cn',cn);
      if( !! cn) {
        //console.log(this.$store.state.userInfo);

        // deptList
        let req = cn;
        await this.$api.apimgrs.getDeptName(req).then((res)=>{
          this.deptList = res[0].department;
          console.log(this.deptList);
          //this.totalLists = res.total;
        });
      }
      

    },  
    async getList() {

      // tableData: [],

      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }

      const cn = this.$store.state.userInfo.ComName;
      let req = this.listQuery;
      if( req.DeptName=="all") // 刪除
        delete req.DeptName; 
      if( !! cn)
        req.ComName = cn;

      this.tableData = [];

      console.log(this.listQuery);

      {
        // req.ApiID = [];
        // req.ApiID.push(req.selApiID);
        // //console.log(req);
        // delete req.selApiID;
        // req.Start_Date = this.rangeDate[0];
        // req.End_Date = this.rangeDate[1];
        let ret = [];
        // console.log(req);
        await this.$api.apimgrs.apiStatic(this.listQuery).then((res)=>{
            ret = res;
            console.log(ret);
            if( ret.length>0) {
              //console.log(ret[0]);
              const apiName = ret[0].Name;
              const apiMemo = ret[0].Memo;
              const ep = ret[0].EndPoint;
              ret[0].data.forEach(x=>{
                let r = { Name :apiName, DeptName : x.DeptName, UserName : x.UserName, total : x.total, EndPoint:  ep, UserID: x.UserID };
                this.tableData.push(r);
              })
              this.totalLists = ret[0].data.length;


              //console.log(this.tableData);
            }
          }
        );


      }


    }, 
  },

  async mounted() {
    this.$store.dispatch("loadingHandler", true);
    await this.getApiList();
    await this.getDept();
    //await this.getList();  
    

    let userID = window.localStorage.getItem("userID")
    this.$store.dispatch("loadingHandler", false);
  },
}
</script>


<style lang="scss"  scoped>
.apiBox {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  display: flex;
  padding:  5px 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;   

  &__listBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    // outline: red solid 1px;

    .header {
      display: flex;
      width: 90%;
      justify-content: flex-end;
      margin: 0px 0px 10px 0px;
    } 

    .content, .pageBox {
      display: flex;
      width: 90%;
      flex-direction: row;
      justify-content: center;
      
    }
  }  

  .filterWrap{
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  .itemWrap{
      margin: 5px 10px;
      // width: 500px;
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      label{
          white-space:nowrap;
      }
      &:nth-child(4){
        width:15%;
      }
  }

  }
}
</style>