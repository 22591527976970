<template>
    <div class="comDetail">
        <Breadcrumb :title="$route.meta.title" />
        <ForeignInfo :comid="ComId"  :comname="ComName" v-if="ComName.length > 0" />         
    </div>
    
</template>

<script>
import ForeignInfo from "../components/ForeignInfo.vue";
import Breadcrumb from '../../src/components/Breadcrumb.vue';
export default {
    components: { ForeignInfo,Breadcrumb },
  data() {
    return {

        ComName:"",
        ComId:"",


    };
  },

  methods: {

  },

  mounted() {
      // 取得Unicode ...
    //const qryCom = { "UniComNm": this.$route.query.comid };
    this.ComName = this.$route.query.comname;
    this.ComId = this.$route.query.comid;
    //console.log(this.ComId, this.ComName);
  },
}
</script>

<style lang="scss" scope>
// * {
//     outline: red solid 1px;
// }
.wholeBox {
    display: flex;
    flex-direction: column;
    margin: 0px 0px;
    // padding: 20px 20px;
    // width: 95%;
    // height:150vh;
    background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%) center center / 100% 100%;
   


}

.el-tabs {
    background-color: transparent;
}

</style>