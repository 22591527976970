<template>
  <div class="login">
    <div class="login__header">
      <div class="login__header--title">
        <img src="@/assets/logo.png" alt="logo" width="15%">
        <strong> 商情資料查詢系統</strong>

      </div>
    </div>

    <div class="login__func" v-if="! loginHide">
      <div class="login__func--infoCard">
        <div class="flex align-center justify-center">
          <label style="color:black;font-weight:700"  >帳號：</label>
          <input type="text" v-model.trim="userInfo.username" placeholder="請輸入帳號" :disabled="loginHide">
        </div>
        <div class="flex align-center justify-center">
          <label style="color:black;font-weight:700" >密碼：</label>
          <input type="password" v-model.trim="userInfo.password" placeholder="請輸入密碼"  :disabled="loginHide">
        </div>
        <button @click="login()" >登入</button>
      </div>
    </div>

    <div class="footer">
      <!-- V05301520 -->
      <!-- <p>Copyright © 2023 minds Corporation. V0411</p> -->
    </div>
  </div>
</template>

<script>
import {getIPs,getIPv4} from 'webrtc-ips';
import axios from 'axios';

export default {
 
  data() {
    return {
      userInfo: {
        username: "",
        password: ""
      },
      loginHide: true,
      sAuthCode:"",
      sDecode:"",
      tbbToken:"",

    };
  },
  methods: {

    async loginDeptName() {

      // 應由ePortal回傳的, 組成以下的JSON
      // {
      //   "UserID": "Z0001",
      //   "UserName": "Dep001",
      //   "ComName": "臺灣中小企業銀行",
      //   "DeptName": [
      //     "DC"
      //   ]
      // }

      if (!!this.userInfo.username && !!this.userInfo.password) {
        let req = {
          "UserID": this.userInfo.username,
          "UserName": this.userInfo.username,
          "ComName": "臺灣中小企業銀行",
          "DeptName": [
            this.userInfo.username
          ]
        }        


        this.$store.dispatch("loadingHandler", true);
        //console.log("req",req);
        await this.$store.dispatch("LoginDept", req).then(res=>{
          //console.log(res);
          if (res == "success") {
              this.$notify({
                title: "成功",
                message: "登入成功!",
                type: "success",
              });
              this.$router.push("/Home");
            } else {
              this.$notify({
                title: "錯誤",
                message: msg,
                type: "warning",
              });
            }
            this.$store.dispatch("loadingHandler", false);
        }).catch(err=>{
          this.$notify.error({
            title: "錯誤",
            message: "網路發生錯誤",
            type: "Error"});
          console.log(err);
        });

        this.$store.dispatch("loadingHandler", false);

      }


      




    },


    async loginePortal(aCode) {

      //const userIP = await getIPs({ urls: "stun:stun.stunprotocol.org:3478" });
      // const userIP = await getIPs();
      // console.log("Client IP",userIP);

      // console.log("Client IP4",await getIPv4());
      let req1 = {
        "AuthCode": aCode,
        "ClientIP": "127.0.0.1"
      }


      // this.$store.dispatch("loadingHandler", true);
      
      //console.log("ePortal req1",req1);
      await this.$store.dispatch("LoginePortal", req1).then(res=>{
        //console.log(res);
        if (res == "success") {
            this.$notify({
              title: "成功",
              message: "登入成功!",
              type: "success",
            });
            this.$router.push("/Home");
          } else {
            this.$notify({
              title: "錯誤",
              message: msg,
              type: "warning",
            });
          }
          this.$store.dispatch("loadingHandler", false);
      }).catch(err=>{
        this.$notify.error({
          title: "錯誤",
          message: "網路發生錯誤",
          type: "Error"});
        console.log(err);
      });

      this.$store.dispatch("loadingHandler", false);

    },


    async login() {


        

      if (!!this.userInfo.username && !!this.userInfo.password) {
        // const start = Date.now();
        this.$store.dispatch("loadingHandler", true);
        await this.$store
          .dispatch("Login", this.userInfo)
          .then((msg) => {
            if (msg == "success") {
              this.$notify({
                title: "成功",
                message: "登入成功!",
                type: "success",
              });
              this.$router.push("/Home");
            } else {
              this.$notify({
                title: "錯誤",
                message: msg,
                type: "warning",
              });
            }
            this.$store.dispatch("loadingHandler", false);
          })
          .catch((errMsg) => {
            this.$notify.error({
              title: "帳密錯誤或無此帳號",
              message: 帳密錯誤或無此帳號,
            });
            this.$store.dispatch("loadingHandler", false);
          });
          // const millis = Date.now() - start;
          // console.log(`登入頁載入時間 = ${Math.floor(millis / 1000 )}`);
      } else {
        this.$notify.error({
          title: "錯誤",
          message: "請確實輸入帳號或密碼！",
        });
      }

    },
    //取得試用者IP位置
    async getIP(){
      // You can pass in your custom stun server urls
      const userIP = await getIPs({ urls: "stun:stun.stunprotocol.org:3478" });
      this.userInfo.userIP = userIP[0].address;
      
      // => [{address: '95.108.174.12', v6: false}, {address: '2a02:6b8::408:5830:47a6:d045:a9ac', v6: true}]
    },

    async loginTBB() {
      
      console.log("進入LoginTBB", this.$route.query.authCode);

      const instance = axios.create({
        withCredentials: true
      })


      let req = { "sAPIUser":"TBBBIDS", "sAPIPass":"Abcd1234", "sAPName":"BIDS"};
       console.log(req);
       await instance.post("http://10.16.22.108/TBBWebService/rest/token", req, {
        headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Headers': '*',
                  'Access-Control-Allow-Credentials': 'true',
                  'Access-Control-Allow-Methods':'*',
                  'Accept': 'application/json'
                }
       })
      .then(res=>{
          console.log("取得token", res);
          if( res.errorCode=="0") {
            console.log("Token = " , res.data.appAccessToken);
            this.tbbToken = res.data.appAccessToken;
            // 取得使用者資訊... 
            instance.post("http://10.16.22.108/TBBWebService/rest/ePortal/checkLogin", {
              "sAuthCode": this.sAuthCode,
              "sClientIP": "127.0.0.1"
            }, {
              headers: {
                'Authorization': `Bearer ${this.tbbToken}` 
              }
            }).then( Response=> {
              if( Response.errorCode=="0") {
                console.log("TbbUserInfo",Response.data);
                localStorage.setItem('tbbUser', JSON.stringify(Response.data));
              }
              else {
                console.log("Error, SSO 登入錯誤 Error Code / Message ==> ", errorCode, errorMessage);
              }
            }).catch(err=>{
              console.log("Err",err);
            });
          }
          else {
            this.$notify.error({
              title: "錯誤",
              message: "取得SSO Token錯誤: " + res.errorMessage ,
            });
          }
        })
        .catch(err=>{
          this.$notify.error({
              title: "錯誤",
              message: "取得SSO Token錯誤: " +  err ,
            });
        });
      
    }
  },
  async mounted(){
    // this.getIP()
    this.sAuthCode = this.$route.query.authCode;
    // console.log("sAuthCode",this.sAuthCode);
    let t1 = encodeURI(this.sAuthCode);
    // console.log("encodeURI_t1", t1);
    let t2 = encodeURIComponent(this.sAuthCode);

    let debugEnv = process.env.VUE_APP_BASE_DEBUG;
    //console.log("debugEnv", debugEnv);
    // console.log("encodeURIComponent_t2", t2);
    //if ( this.sAuthCode  ) {      
      // 當有authCode 時, 去呼叫TBB SSO
      if( debugEnv =="False" && this.sAuthCode )
      {        
        this.$store.dispatch("loadingHandler", true);
        this.loginHide = true;
        //console.log("開始呼叫TBB ePortal");
        await this.loginePortal(t2 );
        //console.log("結束TBB ePortal");
        this.$store.dispatch("loadingHandler", false);
      }    
      else
       {
        if( debugEnv =="False" && !this.sAuthCode )
          this.loginHide = true;
        else 
          this.loginHide = false;
       }
        
      //console.log("loginHide", this.loginHide);
  }
};
</script>

<style lang="scss" scoped>
// * {
//   outline: red 1px solid;
// }
.login {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  // background: linear-gradient(-45deg, transparent 55%, #191972 0) right,
  //   linear-gradient(45deg, transparent 15px, #00abb9 0) left;   
  background: linear-gradient(135deg,#191970 3%, #fff 50%, #FFF6EF ) center center / 100% 100%;
   //background: linear-gradient(135deg,#191970 3%, #fff 50%, #00abb9 ) center center / 100% 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  &__header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &--title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5%;


      strong {
        font-size: 36px;
        letter-spacing: 5px;
        color: white;
        margin-left: 3%;        
        padding: 0px 0px;
        font-weight: 700;
        text-shadow: black 0.1em 0.1em 0.2em;
      }
    }
  }

  &__func {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (min-width: 450px) {
      align-items: center;
    }

    &--infoCard {
      width: 100%;
      display: flex;
      margin-top: 30%;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      div {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        @media (min-width: 450px) {
          flex-direction: row;
        }

        label {
          min-width: 100px;
          max-width: 100px;
          font-size: 20px;
          color: white;
          letter-spacing: 10px;
        }

        input {
          width: 300px;
          border: none;
          padding: 8px;
          font-size: 16px;
          letter-spacing: 0.2rem;
        }
      }

      button {
        width: calc(100% - 20px);
        max-width: 400px;
        background: #191970;
        padding: 4px;
        border-radius: 8px;
        font-size: 20px;
        color: white;
        font-weight: bold;
        letter-spacing: 5px;
        transition: 0.6s;
        border: 1px solid white;
        cursor: pointer;

        &:hover {
          background: #dfdff5;
          border: 1px solid #191970;
          color:  #191970;
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    text-align: center;

    p {
      margin-bottom: 0;
      font-size: 18px;
      color: grey;
      letter-spacing: 2.5px;
    }
  }
}
</style>
