import request from "../request";
import sysrequest from "../sysrequest";
import axios from 'axios';

const api = {
  login(data ){
    return sysrequest({
      url: "/bid/demo/token/",
      method: "post",
      data,
    });
  },

  // login(username, password){
  //   return request({
  //     url: "/bid/demo/token/",
  //     method: "post",
  //     data: {
  //       username: username,
  //       password: password
  //     },
  //   });
  // },


  // TBB由SSO來的部門及人員資訊, 
  // 必須用系統面的TOKEN登入
  createToken(data) {
    return sysrequest({
      url: "/bid/demo/token/create_token/",
      method: "post",
      data,
    });
  },


  eportal_login(data) {
    return sysrequest({
      url: "/bid/demo/eportal/eportal_login/",
      method: "post",
      data,
    });
  },



  async getTBBtoken(data) {


    axios.post("tbb/TBBWebService/rest/token", {
      "sAPIUser": data.APIUser,
      "sAPIPass": data.APIPass,
      "sAPName": data.APName
    }).then( Response=> {
      if( Response.errorCode=="0") {
        // console.log("TbbUserInfo",Response.data);
        // localStorage.setItem('tbbUser', JSON.stringify(Response.data));
      }
      else {
        console.log("Error, SSO 登入錯誤 Error Code / Message ==> ", errorCode, errorMessage);
      }
    });

  },

  loginAuthCode(data) {
    axios.post("tbb/TBBWebService/rest/ePortal/checkLogin", {
      "sAuthCode": data.sAuthCode,
      "sClientIP": data.sIP
    }).then( Response=> {
      if( Response.errorCode=="0") {
        //console.log("TbbUserInfo",Response.data);
        localStorage.setItem('tbbUser', JSON.stringify(Response.data));
      }
      else {
        console.log("Error, SSO 登入錯誤 Error Code / Message ==> ", errorCode, errorMessage);
      }
    });
  },

  refreshSession(data) {
    axios.post("tbb/TBBWebService/rest/ePortal/refreshSession", {
      "sessionID": data.sesssionID,
      "sAccount": data.sAccount,
      "sClientIP": data.sIP
    }).then( Response=> {
      if( Response.errorCode=="0") {
        //console.log("TbbUserInfo",Response.data);
        // localStorage.setItem('tbbUser', JSON.stringify(Response.data));
      }
      else {
        console.log("Error, SSO 登入錯誤 Error Code / Message ==> ", errorCode, errorMessage);
      }
    });

  },

  logoutTBB(data) {
    axios.post("tbb/TBBWebService/rest/ePortal/user/logout", {
      "sessionID": data.sesssionID,
      "sAccount": data.sAccount,
      "sClientIP": data.sIP
    }).then( Response=> {
      if( Response.errorCode=="0") {
        //console.log("TbbUserInfo",Response.data);
        // localStorage.setItem('tbbUser', JSON.stringify(Response.data));
      }
      else {
        console.log("Error, SSO 登入錯誤 Error Code / Message ==> ", errorCode, errorMessage);
      }
    });

  },


  getAllUser(data) {
    
    if( data.isSys)
      return sysrequest({
        url: '/bid/demo/users/search_user/',
        method: 'post',
        data,
      });

    else    
      return request({
        url: "/bid/demo/users/search_user/",
        method: "post",
        data,
      });
  },

  getMenu(){    
    return sysrequest({
      url: "/bid/demo/system/menu/",
      method: "get"
    });
  },

  getActions(data) {  
    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/action/search_action/",
        method: "post",
        data,
      });    
    else
      return request({
        url: "/bid/demo/action/search_action/",
        method: "post",
        data,
      });
  },  
  
  
  /* 設定 TBB 的部門角色.. DC, DO 等等.. */

  getDeptName(data) {    
      return sysrequest({
        url: "/bid/demo/audit/index/",
        method: "get",
        data,
      });
  },

  getDeptInfo(data) {    
    return request({
      url: "/bid/demo/department/search_department/",
      method: "post",
      data,
    });
  },

  getDeptList(data) {   
    if( data.isSys) {
      return sysrequest({
        url: "/bid/demo/department/search_department/",
        method: "post",
        data,
      });
    } 
    else {
      return request({
        url: "/bid/demo/department/search_department/",
        method: "post",
        data,
      });
    }
  },  

  addDeptInfo(data) {    
    return request({
      url: "/bid/demo/department/add_department_info/",
      method: "post",
      data,
    });
  },

  updateDeptInfo(data) {    
    return request({
      url: "/bid/demo/department/update_department_info/",
      method: "post",
      data,
    });
  },  

  deleteDeptInfo(data) {    
    return request({
      url: "/bid/demo/department/delete_department_info/",
      method: "post",
      data,
    });
  },    

  
  updateRole(data) {  
    return request({
      url: "/bid/demo/role/update_role/",
      method: "post",
      data,
    });
  },


  getRole(data){
    return request({
      url: "/bid/demo/role/search_role/",
      method: "post",
      data,
    });
  },
  addRole(data){
    return request({
      url: "/bid/demo/role/add_role/",
      method: "post",
      data,
    });
  },  
  deleteRole(data){
    return request({
      url: "/bid/demo/role/delete_role/",
      method: "post",
      data,
    });
  },    
  getRoleList(data){
    if( data.isSys) {
      return sysrequest({
        url: "/bid/demo/role/search_role/",
        method: "post",
        data,
      });
    }
    else {
      return sysrequest({
        url: "/bid/demo/role/search_role/",
        method: "post",
        data,
      });    
    }


  },

  getFunc(data){
    return request({
      url: "/bid/demo/action/search_action/",
      method: "post",
      data,
    });
  },

// 上架資訊Api

  addApi(data) {
    return request({
      url: "/bid/demo/manage/add_api/",
      method: "post",
      data,
    });
  },

  deleteApi(data){
    return request({
      url: "/bid/demo/manage/delete_api/",
      method: "post",
      data,
    });
  },
  updateApi(data){
    return request({
      url: "/bid/demo/manage/update_api/",
      method: "post",
      data,
    });
  },  

  getApi(data){

    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/manage/search_api/",
        method: "post",
        data,
      });

    else       
      return request({
        url: "/bid/demo/manage/search_api/",
        method: "post",
        data,
      });
  },

  // 系統操作紀錄
  actionRecord(data){
    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/audit/search_audit/",
        method: "post",
        data,
      });
    else
      return request({
        url: "/bid/demo/audit/search_audit/",
        method: "post",
        data,
      });
  },  

  // 系統api統計
  apiStatic(data){
    return request({
      url: "/bid/demo/audit/api_statistics/",
      method: "post",
      data,
    });
  },  

  // 系統登入紀錄
  loginRecord(data){
    if( data.isSys )
      return sysrequest({
        url: "/bid/demo/audit/search_token/",
        method: "post",
        data,
      });
    else
      return request({
        url: "/bid/demo/audit/search_token/",
        method: "post",
        data,
      });
  },  

  genToken(data) {
    return sysrequest({
      url: "/bid/demo/token/create_system_token/",
      method: "post",
      data,
    });    
  },

  // 使用者

  getInfo(data){
    if( data.isSys)
      return sysrequest({
        url: '/bid/demo/users/search_user/',
        method: 'post',
        data,
      });

    else
      return request({
        url: "/bid/demo/users/search_user/",
        method: "post",
        data,
      });
  },

  addUser(data) {
    return request({
      url: "/bid/demo/users/add_user/",
      method: "post",
      data,
    });
  },
  deleteUser(data){
    return request({
      url: "/bid/demo/users/delete_user/",
      method: "post",
      data,
    });
  },
  updateUser(data){
    return request({
      url: "/bid/demo/users/update_user/",
      method: "post",
      data,
    });
  },  

  updatePassword(data){
    return request({
      url: "/bid/demo/users/update_password/",
      method: "post",
      data,
    });
  },  

  // 新聞

  searchNews(data) {
    
    if( data.isSys)
      return sysrequest({
        url: '/bid/demo/news/search_news/',
        method: 'post',
        data,
      });

    else

      return request({
        url: '/bid/demo/news/search_news/',
        method: 'post',
        data,
      });
  },

  // 搜尋公司
  searchCompany(data) {
    return request({
        url: "/bid/demo/company_data/search_company/",
        method: "post",
        data,
    });
  },  

    /* 公司基本資料  */
  getComBasicInfo(data) {
    return request({
    url: "/bid/demo/company_data/company_info/",
    method: "post",
    data,
    });
  },

  /* 國外公司基本資料*/
  getForeignComBasicInfo(data) {
    return sysrequest({
      url: "/bid/demo/company_data/foreign_company_info/",
      method: "post",
      data,
      });
  }, 


  

    /* 公司變更  */
    getComChange(data) {
      return request({
      url: "/bid/demo/company_data/company_change/",
      method: "post",
      data,
      });
    },

    /* 國外公司變更  */
    getForeignComChange(data) {
      if( data.isSys) {
        return sysrequest({
          url: "/bid/demo/company_data/foreign_company_change/",
          method: "post",
          data,
          });
      }
      else {
        return request({
          url: "/bid/demo/company_data/foreign_company_change/",
          method: "post",
          data,
          });
      }


    },    


  searchRelCompany(data) {
    return request({
        url: "/bid/demo/company_data/company_relation/",
        method: "post",
        data,
    });
},      

relCompanyInfo(data) {
  return request({
      url: "/bid/demo/company_data/company_relation/",
      method: "post",
      data,
  });
},   


  /* 產業關聯查詢的下拉選單 Kenny*/
  getIndusSelections(data) {
    return sysrequest({
      url: '/bid/demo/industry/selection/',
      method: 'post',
      data,
    });
  },  

  /* 產業關聯新聞*/
  searchIndustryNews(data) {
    return request({
      url: "/bid/demo/industry/search_industry_news/",
      method: "post",
      data,
      });
  }, 

  /* 上市 基本資料*/
  searchMopsBaseInfo(data) {
    return request({
      url: "/bid/demo/mops/search_base_info/",
      method: "post",
      data,
      });
  },   

  /* 上市 財務資料*/
  searchMopsFincialInfo(data) {
    return request({
      url: "/bid/demo/mops/search_fincial_info/",
      method: "post",
      data,
      });
  },   

  /* 上市 基本資料下拉bar*/
  MopsBaseIndex(data) {
    return sysrequest({
      url: "/bid/demo/mops/base_info_index/",
      method: "get",
      data,
      });
  }, 

  /* 上市 財務資料下拉bar*/
  MopsfincialIndex(data) {
    return sysrequest({
      url: "/bid/demo/mops/base_fincial_index/",
      method: "get",
      data,
      });
  }, 
  



  getNews(data){
    return sysrequest({
        url: "/bid/demo/news/search_news/",
        method: "post",
        data,
    });
  },


  // 取得新聞網站的API
  getOptions(data) {
    return sysrequest({
      url: '/bid/demo/news/index/',
      method: 'get',
      data,
    });
  },

  /* 取得全台地址*/
  getAddress(data) {
    return sysrequest({
      url: '/bid/demo/system/administrative/',
      method: 'post',
      data,
    });
  },  

  getApiCat() {
    return sysrequest({
      url: "/bid/demo/manage/index/",
      method: "get"
    });
  },  

  /* 取得即時發查 的所有API名稱, 以供下拉*/  
  getRtbiApi(data) {
    return sysrequest({
      url: '/bid/demo/rtbi/index/',
      method: 'get',
      data,
    });
  }, 
  
  /* 取得 rtbi 發查的記錄*/  
  getRtbi(data) {
    if( data.isSys)
      return sysrequest({
        url: '/bid/demo/rtbi/search_rtbi/',
        method: 'post',
        data,
      });
    else
      return request({
        url: '/bid/demo/rtbi/search_rtbi/',
        method: 'post',
        data,
      });
  },   
  

    
};
  
export default api;