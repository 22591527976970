import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import {
    getToken
} from "./api/auth";
import getPageTitle from "./api/get-page-title";

NProgress.configure({
    showSpinner: false,
});

router.beforeEach(async (to, from, next) => {
    // hasToken 要每次進來都抓不然不會改變

    const hasToken = getToken();
    NProgress.start();
    document.title = getPageTitle(to.meta.title);
    store.dispatch("SaveHistory",from);
    // 如果有登入進來拿資料然後去想去的地方

    if (to.name) {        
        if (to.name !== 'login') {
            if (!hasToken) {
                    console.log("尚未登入!, 跳轉到登入頁面");
                    next("/")
                } else {                    
                    if( to.name =="home" ) {                    
                        next()
                    }  
                    if( ! store.state.menuList) {
                        // console.log("發現menuList不見了.. 重新load menu");
                        store.dispatch("ReloadMenu");
                        // console.log("重新load menu完成");
                    }
            }    
            
        }
        else {

       
        }
        next()
    } else {
        next()
    }
});
router.afterEach(() => {
    NProgress.done();
})