<template>
    <div id="comFinBox">
        <Breadcrumb :title="$route.meta.title" />
        <div class="apiBox__listBox">
        <section class="filterWrap">  
            <div class="itemWrap" style="width:30%">
                <label>資料年月：</label>
                <el-select v-model="selYearMonth"  clearable placeholder="請選擇年月" no-data-text="目前無名單" >
                    <el-option v-for="(y,index) in lstDate" :key="index" :label="y" :value="y" ></el-option>                                       
                </el-select>                      
            </div>                            
            <div class="itemWrap" style="width:30%">
            <label>市場別：</label>
            <el-select v-model="listQuery.ComType" clearable placeholder="請選擇產業別" no-data-text="目前無名單">                                   
                <el-option key="99" label="全部" value="" ></el-option>    
                <el-option v-for="(y,index) in lstComType" :key="index" :label="y" :value="y" ></el-option>                                       
            </el-select>
            </div>
            <div class="itemWrap" style="width:30%">
            <label>產業別：</label>
            <el-select v-model="listQuery.IndusType" clearable placeholder="請選擇產業別" no-data-text="目前無名單">              
                <el-option key="99" label="全部" value="" ></el-option>    
                <el-option v-for="(y,index) in lstIndusType" :key="index" :label="y" :value="y" ></el-option>                                       
            </el-select>
            </div>

            
        </section>   
        <section class="filterWrap">
            <div class="itemWrap" style="width:30%">
            <label>關鍵字：</label>
            <el-input v-model="keyword" placeholder="關鍵字查詢" style="width: 200px" clearable></el-input>
            </div>    
            <div class="itemWrap" style="width:30%">            
            <el-checkbox v-model="IsIndex"   :key="ckidx" ><label>只呈現財務指標</label></el-checkbox>                  
            
            </div>                        
            <div class="itemWrap" style="width:30%">
            <el-button @click="doSearch" type="primary" class="searchLog"><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>
            </div>
        </section>    
        <div class="content">
            <el-table ref="multipleTable" border :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}" style="width: 100%"  empty-text="暫無數據" :data="tableData">
              <el-table-column label="代號"  prop="StockCode" min-width="5%">
              </el-table-column>   
              <el-table-column label="季別"  prop="PerStockYear" min-width="5%">
              </el-table-column>                              
              <el-table-column label="市場別"  prop="ComType" min-width="5%">
              </el-table-column>
              <el-table-column label="產業別"  prop="IndusType" min-width="10%">
              </el-table-column>              
              <el-table-column label="公司簡稱"  min-width="10%">  
                <template slot-scope="scope">
                    <a class="goDetailPage" >{{ scope.row.ComName }}</a>
                </template>                
              </el-table-column>
              <el-table-column label="指標1" prop="PT1" min-width="5%" >  
              </el-table-column>
              <el-table-column label="指標2" prop="PT2" min-width="5%" >  
              </el-table-column>
              <el-table-column label="指標3" prop="PT3" min-width="5%" >  
              </el-table-column>
              <el-table-column label="指標4" prop="PT4" min-width="5%" >  
              </el-table-column>
              <el-table-column label="指標5" prop="PT5" min-width="5%" >  
              </el-table-column>
              <el-table-column label="指標6" prop="PT6" min-width="5%" >  
              </el-table-column>
              <el-table-column label="指標7" prop="PT7" min-width="5%" >  
              </el-table-column>
              <el-table-column label="日期" prop="DataDt"  min-width="15%">  
                <template slot-scope="scope">
                    {{ filterDate(scope.row.DataDt)}}
                </template>                                  
              </el-table-column>                                        
            </el-table>
        </div>
        <div class="pageBox">
          <Pagination
            @currentChange="pageChange"
            :pageSize="this.listQuery.Page_Size"
            :propsCurrentPage="this.listQuery.Page_Num"
            :totalLists="totalLists">
          </Pagination>
        </div>   
        <div class="desc">
            <h3>【 Y 】標記警示意義</h3>
            <ul>
                <li>指標1：變更交易方法或處以停止買賣者（有關交易方法變更之實施日期以本公司公告日為準）。</li>
                <li>指標2：最近期財務報告每股淨值低於10元且最近連續三個會計年度虧損者。</li>
                <li>指標3：最近期財務報告每股淨值低於10元且負債比率高於60％及流動比率小於1.00者（金融保險業除外）。</li>
                <li>指標4：最近期財務報告每股淨值低於10元且最近兩個會計年度及最近期之營業活動淨現金流量均為負數者。</li>
                <li>指標5：最近月份資金貸與他人餘額占最近期財務報告淨值比率達30％以上者（金融保險業除外）。</li>
                <li>指標6：最近月份背書保證餘額占最近期財務報告淨值比率達150％以上者（金融保險業除外）。</li>
                <li>指標7：其他經臺灣證券交易所綜合考量應公布者</li>
            </ul>
        </div> 

        </div>

    </div>
</template>
<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from '../../src/components/Breadcrumb.vue';
import Home from './Home.vue';

export default {
    components: {Breadcrumb,Pagination, Home},
    data() {
        return {
            month:"",
            tableData: [],
            totalLists: 0,
            // rangeDate: [],
            // lstYears: ['2022','2023','2024','2025','2026','2027','2028','2029','2030'],
            // lstMonths: ['01','02','03','04','05','06','07','08','09','10','11','12'],
            // selYear:"2023",
            // selMonth:"03",
            keyword:"",
            IsIndex: true,
            ckidx: "chk01",
            selYearMonth:"",
            listQuery: {
                DataDt:"",
                ComType:"",
                IndusType:"",
                SearchContent:[this.keyword],
                Page_Size:10,
                Page_Num:1
            },
            lstComType: [],
            lstIndusType: [],
            lstDate: [],            
        }
    },
    computed: {
        filterDate() {
            return (date) => {
                return this.$dayjs(date).format("YYYY-MM");
            };
        },

    },
    watch: {
    },
    methods: {
        millionStr(intPart) {
            let newPart = Math.trunc(intPart / 1000000);
            return newPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');            
        },        
        async doSearch(){
            this.listQuery.Page_Num=1;
            this.getList();
        },
        pageChange(val) {
            this.listQuery.Page_Num = val;
            this.getList();
        },
        getSelection() {
            let qry = {};
            this.$api.apimgrs.MopsfincialIndex(qry).then((res)=>{
                this.lstComType = res.ComType;
                this.lstIndusType = res.IndusType;
                this.lstDate = res.DateList;
                this.selYearMonth = res.DateList[0];
                // console.log(res.data);
                //this.totalLists = res.total;
            }, err => {
                this.$notify.error({
                title: "錯誤",
                message: "網路發生錯誤",
                type: "Error"});
            });

        },        
        async getList() {
            
            let qry = {Page_Size:this.listQuery.Page_Size,Page_Num:this.listQuery.Page_Num, IsIndex: this.IsIndex};
            if(this.keyword.length > 0)
                qry.SearchContent = [this.keyword];
            // if( this.selYear.length > 0 && this.selMonth.length > 0 )
            //     qry.DataDt = this.selYear+"-"+this.selMonth+"-01";
            if( this.selYearMonth) {
                qry.DataDt = this.selYearMonth;
            }
            else {
                this.$notify.error({
                title: "請選擇資料年月",
                message: "請選擇資料年月",
                type: "information"});
                return;                
            }
            this.$store.dispatch("loadingHandler", true);
            if( this.listQuery.ComType.length > 0 )
                qry.ComType = this.listQuery.ComType;
            if( this.listQuery.IndusType.length > 0)
                qry.IndusType = this.listQuery.IndusType;            
            console.log(qry);
            let tableDateTemp = [];
            await this.$api.apimgrs.searchMopsFincialInfo(qry).then((res)=>{
                tableDateTemp = res.data;
                // console.log(tableDateTemp);                                
                this.totalLists = res.total;
            }, err => {
                this.$notify.error({
                title: "錯誤",
                message: "網路發生錯誤",
                type: "Error"});
            });

            this.tableData=[];
            
            tableDateTemp.forEach(x=>{
                if( x.Ind01IsRed=="Y")
                    x.PT1 = "Y";
                else
                    x.PT1 = " ";
                if( x.Ind0201IsRed=="Y" || x.Ind0202IsRed=="Y" || x.Ind0203IsRed=="Y")
                    x.PT2 = "Y";
                else
                    x.PT2 = " ";                    
                if( x.Ind0301IsRed=="Y" || x.Ind0302IsRed=="Y" )
                    x.PT3 = "Y";
                else
                    x.PT3 = " ";                    
                if( x.Ind0401IsRed=="Y" || x.Ind0402IsRed=="Y" || x.Ind0403IsRed=="Y")
                    x.PT4 = "Y";
                else
                    x.PT4 = " ";                    
                if( x.Ind05IsRed=="Y")
                    x.PT5 = "Y";                                                            
                else
                    x.PT5 = " ";                    
                if( x.Ind06IsRed=="Y")
                    x.PT6 = "Y";   
                else
                    x.PT6 = " ";                    
                if( x.Ind07IsRed=="Y")
                    x.PT7 = "Y";                                           
                else
                    x.PT7 = " ";    
                this.tableData.push(x);
            });
            


            // console.log(this.tableData);
            this.$store.dispatch("loadingHandler", false);
        },        
    },
    async mounted() {
        this.$store.dispatch("loadingHandler", true);
        this.getSelection();
        this.$store.dispatch("loadingHandler", false);        
    }
}

</script>



<style lang="scss" scoped>

// * {
//     outline: red solid 1px;
// }

#comFinBox {
  background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  min-height: 100vh;
  padding: 0px 0%;


  .filterWrap{

    display: flex;
    flex-direction: row;
    padding: 0 5%;
    margin-bottom: 2%;

    .itemWrap{
        margin: 5px 10px;
        // width: 500px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        label{
            white-space:nowrap;
        }
        &--add {
            display: flex;
            flex-direction: row;
            width: 90%;
            justify-content: flex-end;
            margin-bottom: 20px;
        }

    }
  }

  .content {
    display: flex;
    flex-direction: row;
    .el-table {
        padding: 0px 5%;
    }
  }

  .desc {
    padding: 0px 5%;
  }
  .pageBox {
    display: flex;
    flex-direction: row;
    #page {
        padding: 0px 5%;
    }
  }

}


</style>