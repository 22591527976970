<template>
    <div id="comBasicBox">
        <Breadcrumb :title="$route.meta.title" />        
        <div class="filterWrap">     
            <div class="itemWrap" style="width:30%">
                <label>資料年月：</label>
                <el-select v-model="selYearMonth"  clearable placeholder="請選擇年月" no-data-text="目前無名單" >
                    <el-option v-for="(y,index) in lstDate" :key="index" :label="y" :value="y" ></el-option>                                       
                </el-select>
                <!-- <el-select v-model="selYear"  clearable placeholder="請選擇年" no-data-text="目前無名單" >
                    <el-option v-for="(y,index) in lstYears" :key="index" :label="y" :value="y" ></el-option>                                       
                </el-select>
                <el-select v-model="selMonth"  clearable placeholder="請選擇月" no-data-text="目前無名單" >
                    <el-option v-for="(y,index) in lstMonths" :key="index" :label="y" :value="y" ></el-option>                                       
                </el-select>                        -->
            </div>                    
            <div class="itemWrap" style="width:25%">
            <label>市場別：</label>
            <el-select v-model="listQuery.ComType" clearable placeholder="請選擇產業別" no-data-text="目前無名單">                                   
                <el-option key="99" label="全部" value="" ></el-option>    
                <el-option v-for="(y,index) in lstComType" :key="index" :label="y" :value="y" ></el-option>                                       
            </el-select>
            </div>
            <div class="itemWrap" style="width:25%">
            <label>產業別：</label>
            <el-select v-model="listQuery.IndusType" clearable placeholder="請選擇產業別" no-data-text="目前無名單">              
                <el-option key="99" label="全部" value="" ></el-option>    
                <el-option v-for="(y,index) in lstIndusType" :key="index" :label="y" :value="y" ></el-option>                                       
            </el-select>
            </div>
            <div class="itemWrap" style="width:20%">
                <label>關鍵字：</label>
                <el-input v-model="keyword" placeholder="關鍵字查詢" style="width: 200px" clearable></el-input>
            </div>            
            <div class="itemWrap" style="width:10%">
                <el-button @click="doSearch" type="primary" class="searchLog"><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>
            </div>
            
        </div>       
        <div class="content">
            <el-table ref="multipleTable" border :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}" style="width: 100%"  empty-text="暫無數據" :data="tableData">
            <el-table-column label="代號"  prop="StockCode" min-width="10%">
            </el-table-column>                
            <el-table-column label="市場別"  prop="ComType" min-width="10%">
            </el-table-column>                
            <el-table-column label="住址" prop="ComAdd" min-width="30%" >  
            </el-table-column>
            <el-table-column label="公司名稱"  min-width="30%">
                <template slot-scope="scope">                  
                <a class="goDetailPage" >{{ scope.row.ComName }}</a>
                </template>
            </el-table-column>              
            <el-table-column label="簡稱"  min-width="10%">  
                <template slot-scope="scope">
                    <a class="goDetailPage" @click="doShowApiDetails(scope.row)">{{ scope.row.ComAbbr }}</a>
                </template>                
            </el-table-column>
            <el-table-column label="資本額(M)" prop="PUCap"  min-width="15%">  
                <template slot-scope="scope">
                    {{ millionStr(scope.row.PUCap)}}
                </template>                                                  
            </el-table-column>
            <el-table-column label="統編" prop="UniComNm"  min-width="15%">  
            </el-table-column>            
            <el-table-column label="產業別" prop="IndusType"  min-width="10%">                  
            </el-table-column>                          
            <el-table-column label="日期" prop="DataDt"  min-width="10%">  
                <template slot-scope="scope">
                    {{ filterDate(scope.row.DataDt)}}
                </template>                                  
            </el-table-column>                                        
            </el-table>
        </div>
        <div class="pageBox">
        <Pagination
            @currentChange="pageChange"
            :pageSize="this.listQuery.Page_Size"
            :propsCurrentPage="this.listQuery.Page_Num"
            :totalLists="totalLists">
        </Pagination>
        </div>        
        
    </div>    
</template>

<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from '../../src/components/Breadcrumb.vue';
import moment from "moment";

export default {
    components: {Breadcrumb,Pagination},
    data() {
        return {
            month:"",
            tableData: [],
            totalLists: 0,
            // rangeDate: [],
            
            // lstYears: ['2022','2023','2024','2025','2026','2027','2028','2029','2030'],
            // lstMonths: ['01','02','03','04','05','06','07','08','09','10','11','12'],
            // selYear:"2023",
            // selMonth:"03",
            selYearMonth:"",
            keyword:"",
            listQuery: {
                DataDt:"",
                ComType:"",
                IndusType:"",
                SearchContent:[this.keyword],
                Page_Size:10,
                Page_Num:1
            },
            lstComType: [],
            lstIndusType: [],
            lstDate: [],
        }
    },
    computed: {
        filterDate() {
            return (date) => {
                return this.$dayjs(date).format("YYYY-MM");
            };
        },

    },
    watch: {
    },
    methods: {
        millionStr(intPart) {
            let newPart = Math.trunc(intPart / 1000000);
            return newPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');            
        },        
        async doSearch(){
            this.listQuery.Page_Num=1;
            this.getList();
        },
        pageChange(val) {
            this.listQuery.Page_Num = val;
            this.getList();
        },
        getSelection() {
            let qry = {};
            console.log(qry);
            this.$api.apimgrs.MopsBaseIndex(qry).then((res)=>{
                this.lstComType = res.ComType;
                this.lstIndusType = res.IndusType;
                this.lstDate = res.DateList;
                this.selYearMonth = res.DateList[0];
                // console.log(res.data);
                //this.totalLists = res.total;
            }, err => {
                this.$notify.error({
                title: "錯誤",
                message: "網路發生錯誤",
                type: "Error"});
            });

        },

        async getList() {
            
            let qry = {Page_Size:this.listQuery.Page_Size,Page_Num:this.listQuery.Page_Num};
            if(this.keyword.length > 0)
                qry.SearchContent = [this.keyword];
            // if( this.selYear.length > 0 && this.selMonth.length > 0 )
            //     qry.DataDt = this.selYear+"-"+this.selMonth+"-01";

            if( this.selYearMonth) {
                qry.DataDt = this.selYearMonth;
            }
            else {
                this.$notify.error({
                title: "請選擇資料年月",
                message: "請選擇資料年月",
                type: "information"});
                return;                
            }

            this.$store.dispatch("loadingHandler", true);

            if( this.listQuery.ComType.length > 0 )
                qry.ComType = this.listQuery.ComType;
            if( this.listQuery.IndusType.length > 0)
                qry.IndusType = this.listQuery.IndusType;            
            console.log(qry); 
            await this.$api.apimgrs.searchMopsBaseInfo(qry).then((res)=>{
                this.tableData = res.data;
                // console.log(res.data);
                this.totalLists = res.total;
            }, err => {
                this.$notify.error({
                title: "錯誤",
                message: "網路發生錯誤",
                type: "Error"});
            });
            this.$store.dispatch("loadingHandler", false);
        },

       
    },
    async mounted() {
        this.$store.dispatch("loadingHandler", true);
        this.getSelection();
        this.$store.dispatch("loadingHandler", false);
    }
}

</script>

<style lang="scss" scoped>

// * {
//     outline: red solid 1px;
// }

#comBasicBox {
  background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  min-height: 100vh;
  padding: 0px 0%;


  .filterWrap{

    display: flex;
    flex-direction: row;
    padding: 0 5%;
    margin-bottom: 2%;

    .itemWrap{
        margin: 5px 10px;
        // width: 500px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        label{
            white-space:nowrap;
        }
        &--add {
            display: flex;
            flex-direction: row;
            width: 90%;
            justify-content: flex-end;
            margin-bottom: 20px;
        }

    }
  }

  .content {
    display: flex;
    flex-direction: row;
    padding: 0 5%;
  }

  .pageBox {
    display: flex;
    flex-direction: row;
    padding: 0 5%;  
  }

}


</style>