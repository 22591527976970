<template>
<div class="projSearchBox">
    <Breadcrumb :title="$route.meta.title" />
    <div class="searchWrap">    
        <div class="itemWrap" style="width:25%">
            <label>專卷：</label>
            <el-select v-model="listQuery.folder" clearable placeholder="請選擇專卷名稱" no-data-text="目前無名單" @change="changeFolder">                                   
                <el-option key="99" label="請選擇專卷" value="" ></el-option>    
                <el-option v-for="(y,index) in folerList" :key="index" :label="y.FolderCategory" :value="y.FolderID" ></el-option>                                       
            </el-select>
        </div>
        <div class="itemWrap" style="width:25%">
            <label>主題：</label>
            <el-select v-model="listQuery.topic" clearable placeholder="請選擇主題名稱" no-data-text="目前無名單" :disabled="listQuery.folder===''">              
                <el-option key="99" label="全部" value="" ></el-option>    
                <el-option v-for="(y,index) in topicList" :key="index" :label="y.TopicName" :value="y.TopicID" ></el-option>                                       
            </el-select>
        </div>  
        <div class="itemWrap" style="width:25%;margin-left: 10px;">
            <el-button type="primary" @click="searchTopicNews">查詢</el-button>
        </div>   
        <div class="itemWrapRight" style="width:25%">
            <el-dropdown split-button type="success" >
            <font-awesome-icon icon="user-cog"  style="margin-left:15px;margin-right:10px" /><span style="margin-right:15px;">操作</span>
            <el-dropdown-menu slot="dropdown" >
                <el-dropdown-item  @click.native="deleteTopicNews()" ><font-awesome-icon icon="times" style="margin-right:10px;" />刪除新聞</el-dropdown-item>                
                <el-dropdown-item divided @click.native="openRelationAnalysis()" ><font-awesome-icon icon="chart-pie" style="margin-right:10px;" />關聯分析</el-dropdown-item>                
                <el-dropdown-item ><download-csv :data="tableData" name="專卷查詢.csv"><font-awesome-icon icon="download" style="margin-right:10px;" />匯出CSV</download-csv></el-dropdown-item>
            </el-dropdown-menu>
            </el-dropdown>
        </div>                     
    </div>
    <div class="projSearchTable">
        <div class="TableCont">
          <el-table style="width: 100%" ref="multipleTable" :data="tableData"  :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}"  empty-text="暫無數據" @select="handleSelectionChange" @select-all="selectAll" >            
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column label="新聞標題" min-width="50%">
                <template slot-scope="scope">
                    <a class="goDetailPage" @click="goDetailPage(scope.row)">{{ scope.row.NewsTitle }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="NewsSite"  label="新聞網站" min-width="25%" >
            </el-table-column>            
            <el-table-column prop="NewsChannel"  label="新聞頻道" min-width="15%" >
            </el-table-column>           
            <el-table-column  label="新聞日期"  min-width="10%">
                <template slot-scope="scope">
                <div>{{ scope.row.NewsTime | moment("YYYY-MM-DD") }}</div>
                </template>            
            </el-table-column>
        </el-table>
        
      </div>
      <!-- <CompanyInfo :comid="ComId"  :comname="ComName" v-if="ComName.length > 0" /> -->
      <Pagination @currentChange="pageChange" :pageSize="listQuery.Page_Size" :propsCurrentPage="listQuery.Page_Num" :totalLists="totalLists"> </Pagination>        
    </div>
</div>
</template>

<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from '../../src/components/Breadcrumb.vue';
export default {
    components: {Breadcrumb,Pagination  },
    data() {
        return {
        totalLists: 0,
        tableData: [],
        query:"",
        totalLists: 0,
        listQuery: {
            folder:"",
            topic:"",
            Page_Size:10,
            Page_Num:1,
        },
        folerList: [],
        topicList: [],        
        selectData: [],
        };
    },
    methods : {

        /* 刪除專卷新聞 */
        async deleteTopicNews() {
            if( this.selectData.length >0 ) {
                // 建立一個空的陣列，用來儲存所有的 promise 物件
                var promises = [];

                console.log(this.selectData);
                this.selectData.forEach(x=>{
                    if( x.length > 0 ) {
                        x.forEach(y=>{                            
                            promises.push(this.deleteSingleTopicNews(y));
                        });
                    }
                });

                Promise.all(promises).then(()=>{
                    this.$notify({
                        title: "刪除成功",
                        message: "刪除成功",
                        type: "success",
                    }); 
                    this.selectData = [];
                    this.listQuery.Page_Num = 1;
                    this.getList(true);
                })                
                .catch(()=>{
                    this.$notify({
                        title: "刪除失敗",
                        message: "刪除失敗",
                        type: "error",
                    }); 
                });

            }
            else {
                this.$notify({
                    title: "請選擇要刪除的新聞",
                    message: "請選擇要刪除的新聞",
                    type: "warning",
                });                 
            }
        },

        deleteSingleTopicNews(data) {
            const qry = {ID: data.ID.toString()};
            console.log(qry);
            return new Promise((resolve, reject)=>{
                this.$api.folder.deleteTopicNews(qry).then((res)=>{                
                    resolve("success");
                })
                .catch((err)=>{
                    reject(err);
                });
            }) 
        },

        /* 前往新聞詳細頁 */
        goDetailPage(data) {
            let routeUrl = this.$router.resolve({
                path: "/detailNews",
                query: { id: data.NewsID },
            });
            window.open(routeUrl.href, "_blank");
        },          

        openRelationAnalysis() {
          
          //const tName = this.themeList.find(x=>x.ReelTopicID==this.projTheme);
          // console.log(tName);
          //關聯分析.. 呼叫.. /bid/bia/reel/news_topology==> reel.TopicAnalysis(TopicId)
          let routeUrl = this.$router.resolve({
            path: "/topicTopology",
            query: { topicId: this.listQuery.topic },
          });
        //   console.log(routeUrl);
          window.open(routeUrl.href, "_blank");         
          // this.$router.push({name:'proj-search', params: {TopicId: this.projTheme  }});
        },

        async searchTopicNews() {
            if(this.listQuery.folder == "") {
                this.$notify({
                        title: "請選擇專卷",
                        message: "請選擇專卷",
                        type: "warning",
                    });
                return;
            }
            this.selectData = [];
            this.getList(false);
        },

        async pageChange(val) {
            this.listQuery.Page_Num = val;
            await this.getList();
        }, 

        async changeFolder() {
            if ( this.listQuery.folder == "" ) {
                this.$notify({
                        title: "請選擇專卷",
                        message: "請選擇專卷",
                        type: "warning",
                    });
                return;
            }
            await this.getTopicList(true);      
        },

        async getTopicList(isSys)
            {
            this.$store.dispatch("loadingHandler", true);
            this.topicList = [];

            let req = {Page_Size: 100, Page_Num: 1, FolderID: this.listQuery.folder };     
            if ( !isSys ) 
                req.isSys = false;
            else 
                req.isSys = true;
            // console.log(req);

            await this.$api.folder.searchTopic(req).then((res)=>{                
                // console.log(res);        
                this.topicList = res.data;
                this.totalLists = res.total;
                // console.log(this.topicList);        
            });        

            this.$store.dispatch("loadingHandler", false);
        },

        async getFolderList(isSys) {
            this.folerList = [];

            let req = {Page_Size: 100, Page_Num: 1 };     
            if ( !isSys ) 
                req.isSys = false;
            else 
                req.isSys = true;            

            await this.$api.folder.searchFolder(req).then((res)=>{                
                this.folerList = res.data;
                this.totalLists = res.total;                
            });
        },  

        async getList(isSys) {
            this.$store.dispatch("loadingHandler", true);
            this.tableData1 = [];

            let req = {Page_Size: this.listQuery.Page_Size, Page_Num: this.listQuery.Page_Num, FolderID: this.listQuery.folder };     
            if ( this.listQuery.topic != "" ) 
                req.TopicID = this.listQuery.topic;

            if ( !isSys ) 
                req.isSys = false;
            else 
                req.isSys = true;            

            await this.$api.folder.searchTopicNews(req).then((res)=>{                                
                this.tableData = res.data;
                this.totalLists = res.total;       
                this.makeTableShowChecked();
            });      


            this.$store.dispatch("loadingHandler", false);
        },    

        makeTableShowChecked(){
            // console.log("makeTableShowChecked", this.selectData);
            if( this.selectData.length > 0) {        
                this.$nextTick(()=>{
                    this.tableData.forEach((item) => {
                        const checkedDataIDs = this.selectData[this.listQuery.Page_Num-1];
                        if( checkedDataIDs == undefined)
                        return;
                        // console.log("temp",temp);
                        checkedDataIDs.forEach((selectedItem) => {
                        if (item.ID === selectedItem.ID) {
                            // console.log(item);
                            this.$refs.multipleTable.toggleRowSelection(item, true);
                        }
                        });
                    });
                });

            }
        },   
        
        handleSelectionChange(val) {            
            this.selectData[this.listQuery.Page_Num - 1] = val;            
        },    
        
        selectAll(val){
            console.log("selectAll", val);
            this.selectData[this.listQuery.Page_Num - 1] = val;        
        }        
    },

    async mounted() {        
        this.$store.dispatch("loadingHandler", true);    
        this.getFolderList(true);
        this.$store.dispatch("loadingHandler", false);
    },    
}
</script>


<style lang="scss" >
// * {
//   outline: red solid 1px;
// }

.projSearchBox {
//   height:100vh;
  background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  display: flex;
  flex-direction: column;
  
//   padding: 0px 5%;



  .projSearchTable {
    display: flex;
    flex-direction: column;
    justify-content: center;    
    margin-top: 20px;
    padding: 0px 5%;


  }

  .searchWrap {
    display: flex;
    flex-direction: row;    
    justify-content: flex-start;
    padding: 0px 5%;   

        .itemWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top:10px;

            label {
                width: 100px;
                white-space: nowrap;
                margin-right: 10px;
                text-align: right;
            }
     
        }

        .itemWrapRight {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
            margin-top:10px;
        }           

    


    

  }

  .goDetailPage {
    color: #0645ad;
    text-decoration: underline;
    cursor: pointer;
}

}
</style>