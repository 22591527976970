import request from "../request";
import sysrequest from "../sysrequest";
import axios from 'axios';

const api = {
    /* 公司拓墣圖*/
  topology(data) {
    return request({
      url: "/bid/demo/company_data/topology/",
      method: "post",
      data,
      });
  }, 
};



export default api;
