<template>
    <div class="container" >
      <div class="bottom"> 
        查詢統編：<el-input v-model="queries"></el-input>
        <el-button type="primary" @click="ClickHandler" style="margin-top:20px;" ><font-awesome-icon icon="search"  style="margin-right:5px;" /> 查詢 </el-button>
        <el-button type="danger" @click="handleExport" style="margin-top:20px;" > 匯出 </el-button>
      </div>  
      <div class="info">
        <div class="leftbox">
          <div id="cy"> 
          </div> 
        </div>
      </div>
      <el-drawer :title="drawerTitle" :visible.sync="drawerOpen" :direction="drawDirection" size="500px" :before-close="handleDrawerClose"> 
        <el-table ref="multipleTable" :data="infoList" :show-header="false" :cell-style="{padding: '3px', height: '35px'}" :header-cell-style="{ background: 'transparent'}" style="width: 100%;"  empty-text="暫無數據">
          <el-table-column label="標題" prop="title" min-width="35%"> </el-table-column>
          <el-table-column label="內容" prop="value" min-width="65%" ></el-table-column>
        </el-table>
      </el-drawer>
    </div>    
</template>
<script>
import * as cytoscape from 'cytoscape';
import d3Force from 'cytoscape-d3-force';
import cxtmenu from 'cytoscape-cxtmenu';
import saveAs  from "file-saver";

export default {
    data() {
        return {
             //cyObj : {},
             queries: this.$route.query.ComId,
             queryType: "",
             SelectedNodes:{},        
             isShowData:true,
             dataNodes : {},             
             infoList : [],
             drawDirection : "rtl",
             drawerOpen : false,
             drawerTitle : "持股摘要",

        };
    },
    computed: {
      getBG() {
        return (id) => {
          let setBG = "";
          switch (id) {
            case 0:
              setBG = "#BBFFFF";
              break;
            case 1:
              setBG = "#BBFFBB";
              break;
            case 2:
              setBG = "#FFFFCE";
              break;
            case 3:
              setBG = "#FFD9EC";
              break;
          }
          return setBG;
        };
      },


    },
    methods: {
      handleExport() {
        console.log('test');
        var b64key = 'base64,';
        var b64Data = this.cyObj.png({output:'base64',bg:'#f7dae4'});

        const imgBlob = this.b64toBlob(b64Data, 'image/png');
        // const blobUrl = URL.createObjectURL(imgBlob);

        // window.location = blobUrl;

        // var imgBlob = base64ToBlob( b64, 'image/png' );

        saveAs( imgBlob, 'graph.png' );
      },
      b64toBlob(b64Data, contentType='', sliceSize=512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      },

      handleDrawerClose(){
        this.cyObj.panBy( { x:300, y:0});
        this.drawerOpen = false;
      },

      getRandomInt(max) {
        return Math.floor(Math.random() * max);
      },  

      async ClickHandler() {
        this.queryType = "Company";
        this.refreshCy();
      },

      async draw() {

          this.dataNodes = await this.getMapData(this.queries, this.queryType);
          //console.log(this.dataNodes);
          //await this.getMap();
          
          cytoscape.use( d3Force );
          if (typeof cytoscape('core', 'cxtmenu') !== 'function') {
            cytoscape.use( cxtmenu );
          }

          //cytoscape.use( cxtmenu );
          const vm = this;
          //console.log(this);

          var cy = cytoscape({
            container: document.getElementById('cy'), // container to render in
            style: [ // the stylesheet for the graph
              {
                selector: 'node',
                style: {
                  'content': 'data(name)',
                  'text-valign': 'center',
                  'text-halign': 'right',
                  'height': '15px',
                  'width': '15px',
                  'background-color': 'data(bg)', // '#666',
                  'border-width': '1px',
                  'border-color': '#333',              
                  'color': '#000',
                  'label': 'data(name)',

                }
              },                
              {
                selector: 'edge',
                style: {
                  'width': 'data(width)',
                  'line-color': '#ccc',
                  'target-arrow-color': '#888',
                  'curve-style': 'bezier',
                }
              },
              {
                selector: ':selected',
                css: {
                  'background-color': 'SteelBlue',
                  'line-color': 'black',
                  'target-arrow-color': 'black',
                  'source-arrow-color': 'black'
                }
              }
            ],
            wheelSensitivity: 0.1,
            layout: {
              name: 'd3-force',
              animate: true,                
              fixedAfterDragging: true,
              linkId: function id(d) {
                return d.id;
              },
              linkDistance: 80,
              manyBodyStrength: -300,
              ready: function(){},
              stop: function(){},
              tick: function(progress) {
                //console.log('progress - ', progress);
              },
              randomize: false,
              infinite: true
            }
          });

          //20220408, 左鍵先取消
          //cy.on('click','node', this.nodeClick);

          cy.on('click','edge', function(evt){
            var node = evt.target;
            var ed = vm.dataNodes.find( x => x.data.id==node.id());
            //console.log(ed);
            if( ed != null )
            {
              //console.log( ed.data.source + '@' + ed.data.target);
              let setKeyWord = [];
              var source = vm.dataNodes.find(x=> x.data.id==ed.data.source )
              var target = vm.dataNodes.find(x=> x.data.id==ed.data.target );
              setKeyWord.push(source.data.name);
              setKeyWord.push(target.data.name);
              //vm.queries = setKeyWord;
              //console.log("paraid=" + ed.data.paraid);
              //console.log(vm.queries);
            }
            
          });

          cy.on('select', 'node', (e)=>{
            this.selectedCtxMenu = e.cyTarget;
            console.log('selectedCtxMenu = ' + this.selectedCtxMenu);
          });

          cy.on('cxttap', "node", this.showEnable);

          // cy.on('cxttap', "node", function(evt) { 
          //   var node = evt.target;
          //   console.log(node.id());
          // });

          let defaults = {
            menuRadius: function(ele){ return 80; }, // the outer radius (node center to the end of the menu) in pixels. It is added to the rendered size of the node. Can either be a number or function as in the example.
            selector: 'node', // elements matching this Cytoscape.js selector will trigger cxtmenus
            commands: [
              {
                fillColor: 'rgba(149, 191, 119, 0.75)', // optional: custom background color for item
                content: '基本資料', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                // select: function(ele){ // a function to execute when the command is selected
                //   console.log("選取"+ele.id()+"基本資料");
                // },
                select : await this.getBasicInfo,
                enabled: true // whether the command is selectable
              },
              {
                fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                content: '移除', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                select : await this.removeNode,
                enabled: true // whether the command is selectable
              }, 
              {
                fillColor: 'rgba(159, 163, 34, 0.75)', // optional: custom background color for item
                content: '收合', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                select : await this.collaspNodeClick,
                enabled: true // whether the command is selectable
              },
              {
                fillColor: 'rgba(219, 178, 90, 0.75)', // optional: custom background color for item
                content: '展開', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                select: await this.expandNodeClick,
                enabled: true // whether the command is selectable
              },
              {
                fillColor: 'rgba(230, 101, 69, 0.75)', // optional: custom background color for item
                content: '持股摘要', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                // select: function(ele){ // a function to execute when the command is selected
                //   //console.log( ele.id() ) // `ele` holds the reference to the active element
                //   console.log("選取"+ele.id()+"持股摘要");
                // },
                select : await this.getSupervisorInfo,
                enabled: true // whether the command is selectable
              }
              // {
              //   fillColor: 'rgba(256, 200, 200, 0.75)', // optional: custom background color for item
              //   content: '中心點', // html/text content to be displayed in the menu
              //   contentStyle: {}, // css key:value pairs to set the command's css in js if you want
              //   select: function(ele){ // a function to execute when the command is selected
              //     cy.center( '#'+ele.id() );              
              //     vm.layoutRedraw();
              //   },
              //   enabled: true // whether the command is selectable
              // }                    
            ],
            fillColor: 'rgba(0, 0, 0, 0.75)', // the background colour of the menu
            activeFillColor: 'rgba(1, 105, 217, 0.75)', // the colour used to indicate the selected command
            activePadding: 20, // additional size in pixels for the active command
            indicatorSize: 24, // the size in pixels of the pointer to the active command, will default to the node size if the node size is smaller than the indicator size, 
            separatorWidth: 3, // the empty spacing in pixels between successive commands
            spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
            adaptativeNodeSpotlightRadius: false, // specify whether the spotlight radius should adapt to the node size
            minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
            maxSpotlightRadius: 38, // the maximum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
            openMenuEvents: 'cxttap', // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
            itemColor: 'white', // the colour of text in the command's content
            itemTextShadowColor: 'transparent', // the text shadow colour of the command's content
            zIndex: 9999, // the z-index of the ui div
            atMouse: false, // draw menu at mouse position
            outsideMenuCancel: false // if set to a number, this will cancel the command if the pointer is released outside of the spotlight, padded by the number given 
          };

          if (typeof cytoscape('core', 'cxtmenu') == 'function') {
              cy.cxtmenu(defaults);
          }
          //cy.cxtmenu(defaults);

          this.cyObj = cy;
      },

      showEnable(evt) {
        var node = evt.target;
        console.log(node.id());
        this.selectedCtxMenu = this.dataNodes.find(x=>x.data.id==node.id());
        //console.log(aaa);       
      },


      //移除Node
      async removeNode(ele){
        const nodeId = ele.id();
        this.cyObj.remove('edge[source=\'' + nodeId + '\']');
        this.cyObj.remove('edge[target=\'' + nodeId + '\']');
        this.cyObj.remove('#' + nodeId);
      },

      // 持股摘要
      async getSupervisorInfo(ele){
        this.cyObj.panBy({x: -300, y: 0});
        var uniComNm = ele.id();
        let comInfo =  this.dataNodes.find(x=>x.group=="nodes" && x.data.id==uniComNm && x.data.type=="Supervisor");
        console.log("comInfo",comInfo);
        if( comInfo ){
          this.infoList = [];
          this.drawerOpen = true;
          this.drawerTitle = comInfo.data.name + "持股摘要";
          console.log(comInfo);
          let suName = comInfo.data.name;
          let owner = comInfo.data.info.forEach(x=>{
            
            this.infoList.push( { title: "職稱", value: x.JobTitle  });
            this.infoList.push( { title: "姓名", value: suName  });
            this.infoList.push( { title: "所代表法人", value: x.LegPersonRep  });
            this.infoList.push( { title: "持有股份數(股)", value: x.HoldStkNm  });
            this.infoList.push( { title: "持有比例", value: x.Percentage * 100  });
          });
          console.log(this.infoList);
        }
        else {
          this.$notify.error({
            title: "通知",
            message: "只有董監事才會有持股摘要",
            type: "information",
          });
          this.cyObj.panBy({x: 300, y: 0});
        }

        

      },

      // 基本資料
      async getBasicInfo(ele) {
        var uniComNm = ele.id();
        
        this.cyObj.panBy({ x:-300, y:0});

        let comInfo =  this.dataNodes.find(x=>x.data.id==uniComNm);
        console.log("comInfo",comInfo);
        const qryCom = { "UniComNm": uniComNm };
        console.log("qryCom",qryCom);
        
        await this.$api.company.getComBasicInfo(qryCom).then((res) => {
          console.log(res);
          this.infoList = [];
          this.drawerOpen = true;
          this.drawerTitle = comInfo.data.name + "基本資料";
          let profile = res.Profile;
          //console.log(profile);
          this.infoList.push( { title: "統一編號", value: profile.UniComNm  });
          this.infoList.push( { title: "實收資本額(元)", value: profile.PUCap  });
          this.infoList.push( { title: "代表人姓名", value: profile.RepName  });
          this.infoList.push( { title: "公司所在地", value: profile.ComLoc  });
          this.infoList.push( { title: "登記機關", value: profile.RegAuth  });
          this.infoList.push( { title: "核准設立日期", value: profile.ApvDt  });
          this.infoList.push( { title: "最後核准變更日期", value: profile.FnlApvDt  });
          this.infoList.push( { title: "所營事業資料", value: profile.BizItem  });                
        }, err => {
          this.$notify.error({
            title: "錯誤",
            message: "網路發生錯誤",
            type: "Error",
          });
        });
        // console.log("選取"+ele.id()+"基本資料");
        // console.log(ele);
      },

      // Node 左鍵點開
      async nodeClick(e) {
        //this.selectedNode2 = e.target.id();
        var nd = this.dataNodes.find( x => x.data.id==e.target.id());
        let newNode = [];
        if( nd != null)
        {
          //vm.queries = nd.data.name;
          this.SelectedNodes = nd.data;
          // console.log("node=", nd.data.name);
          // console.log("type=", nd.data.type);

          let exNodes = await this.getMapData(nd.data.id, nd.data.type)
          console.log("exNodes", exNodes);
          exNodes.forEach(x => {  
            if( x.group=="nodes") {  
              var isExist = this.dataNodes.find( y => y.data.id== x.data.id );   
              if( isExist ===undefined) {          
                if( x.data.type=="Company")
                  x.data.bg="#7aa7f5";                  
                newNode.push(x);
              }
            }
            if( x.group=="edges") {
              // // 檢查是否重覆的Source->Target..
              var isExist = this.dataNodes.find( y => y.data.source== x.data.target && y.data.target == x.data.source ); 
              if( isExist === undefined ) 
              {
                x.data.width = 1 + parseFloat(x.data.width);
                x.data.id = this.getRandomInt(10000);
                newNode.push(x);
              }
            }
            
          });
          if( newNode.length==0) {
            // 表示無新的資料...
            this.$notify.info({
              title: "通知",
              message: "無新的關連性資料",
              type: "information",
            });
          }

          Array.prototype.push.apply(this.dataNodes, newNode);               
          this.cyObj.add(newNode);  

        }



      },

      // Node 右鍵, 展開
      async expandNodeClick(ele) {
            //this.selectedNode2 = e.target.id();
            
            var nd = this.dataNodes.find( x => x.data.id== ele.id());
            console.log(nd);
            let newNode = [];
            if( nd != null)
            {
              //vm.queries = nd.data.name;
              this.SelectedNodes = nd.data;

              let ndx = nd.position ? nd.position.x : Math.floor((Math.random() * 500) ); 
              let ndy = nd.position ?  nd.position.y: Math.floor((Math.random() * 500) );


              let exNodes = await this.getMapData(nd.data.id, nd.data.type)
              console.log("exNodes", exNodes);

              exNodes.forEach(x => {  
                if( x.group=="nodes") {
                  var isExist = this.dataNodes.find( y => y.data.id== x.data.id );   
                  if( isExist ===undefined) {               
                    if( x.data.type=="Company")
                      x.data.bg="#7aa7f5";
                    // ndy += 30; 
                    let ndPos = {x:Math.floor((Math.random() * 300) ) -150 + ndx, y: Math.floor((Math.random() * 300) ) -150 + ndy };
                    x.position = ndPos;
                    newNode.push(x);
                  }
                }
                if( x.group=="edges") {
                  // // 檢查是否重覆的Source->Target..
                  var isExist = this.dataNodes.find( y => (y.data.source== x.data.target && y.data.target == x.data.source) || (y.data.source== x.data.source && y.data.target == x.data.target) ); 
                  if( isExist === undefined ) 
                  {
                    x.data.width = 1 + parseFloat(x.data.width);
                    x.data.id = this.getRandomInt(10000);
                    newNode.push(x);
                  }
                }
                
              });
              if( newNode.length==0) {
                // 表示無新的資料...
                this.$notify.info({
                  title: "通知",
                  message: "無新的關連性資料",
                  type: "information",
                });
              }

              Array.prototype.push.apply(this.dataNodes, newNode);
              this.cyObj.add(newNode);               
            }

      },    
      
      // Node 右鍵, 收合
      async collaspNodeClick(ele) {
        var nd = this.dataNodes.find( x => x.data.id== ele.id());
        if( nd != null)
        {
          //vm.queries = nd.data.name;
          this.SelectedNodes = nd.data;
          // let exNodes = await this.getMapData(nd.data.id, nd.data.type);
          // console.log("exNodes",exNodes);
          // console.log("data.id", nd.data.id);
          // console.log("datanodes", this.dataNodes);
          var delNode = this.dataNodes.filter(x=> x.group=="edges" && x.data.source==nd.data.id);
          console.log("delNode",delNode);
          delNode.forEach(x=>{                         
            let n = this.dataNodes.find(y=>y.group=="nodes" && y.data.id==x.data.target);
            // console.log("delNode Node", n.data, n.group);
            //this.cyObj.remove('#'+ n.data.id);
            console.log(n);
            if( n.data.type!="LegPersonRep") {
              // 刪除edges
              this.cyObj.remove('edge[target=\'' + n.data.id + '\']');
              // 刪除Nodes                  
              //this.cyObj.remove('#'+ n.data.id);
              this.cyObj.remove('node[id=\'' + n.data.id + '\']');
            }
          }); 
          
          // 同步 cyObj 跟 this.dataNodes
          const rNodes = this.cyObj.nodes().map(x=>x.id());
          const rEdges = this.cyObj.edges().map(x=>x.id());
          let data = [];
          this.dataNodes.forEach(x=> {
            if( x.group=="nodes") {
              if( rNodes.includes(x.data.id) ) {
                data.push( { group: 'nodes', data: x.data });
              }
            }
            if( x.group=="edges") {
              if( rEdges.includes(x.data.id)){
                data.push( { group: 'edges', data: x.data});
              }
            }
          });
          this.dataNodes = data;
        }
      },
      
      async getMapData(QueryTerm,Type) {

        let datas = [];  

        //console.log(QueryTerm,Type);

        if( Type=="LegPersonRep")
          Type="Company";
        if(Type.length==0)
          Type="Company";  
        let comQuery= {
          "SearchValue": QueryTerm,
          "DataType": Type,
          "Layer": 1
        };
        //console.log(comQuery);        
        await this.$api.company_data.topology(comQuery).then((res) => {
            //console.log(res.nodes);
            // console.log(res.nodes.filter(x=>x.id != "缺額"));
            let oNode = res.nodes.filter(x=>x.id != "缺額");
            if( oNode.length > 0){
              let oEdges = res.edges.filter(x=>x.target!="缺額");
              if(oNode!=null && oNode.length>0)
              {
                  for (let i = 0; i < oNode.length; i++)
                  { 
                      if( oNode[i].type=="Start")
                        oNode[i].bg = "#191970";  
                      if( oNode[i].type=="Supervisor")
                        oNode[i].bg = "#ffffcc";
                      if( oNode[i].type=="LegPersonRep")
                        oNode[i].bg = "#4ef27c";       
                      datas.push({
                          group: 'nodes',
                          data: oNode[i],
                          position: { x: this.getRandomInt(800), y: this.getRandomInt(500) }
                      })
                  }              
              };

              //console.log("Edges",oEdges);

              if(oEdges!=null && oEdges.length>0)
              {
                  for (let i = 0; i < oEdges.length; i++)
                  {
                      oEdges[i].width = 1 + parseFloat(oEdges[i].width);
                      datas.push({
                          group: 'edges',
                          data: oEdges[i]
                      })
                  }
              }  

            }    
        }, err => {
          this.$notify.error({
            title: "錯誤",
            message: "網路發生錯誤",
            type: "Error",
          });
        });
        //let oNode = this.derrickData.nodes.filter(x=> x.name==QueryTerm);
        //this.SelectedNodes = oNode[0];
        //console.log("Node",oNode);
 
        //console.log("datas",datas);
        return datas;

      },
      
      async refreshCy() {
          await this.draw();
          this.cyObj.elements().remove();
          //console.log("data",this.dataNodes);
            
          this.cyObj.add(this.dataNodes);  
          this.cyObj.reset();           
          this.layoutRedraw();
      },    

      layoutRedraw() {
          var layout = this.cyObj.layout({
            name: 'd3-force',
            animate: true,
            fixedAfterDragging: true,
            linkId: function id(d) {
                return d.id;
            },
            linkDistance: function() {
              return Math.floor((Math.random() * 150) + 50);
            },
            manyBodyStrength: -300,
            randomize: true,
            infinite: true
          });
          layout.run(); 
      },

    },
    async mounted () {
        console.log("Starting...");
        //console.log(this.SelectedNodes);
        console.log(this.$route.query);
        await this.refreshCy();
        
        console.log("End...");
    }
}
</script>
<style lang="scss" scoped>

#cy {
    // height: calc(100% - 300px);;
    height: 90vh;
    width: 100%;
    display: block;
    background-color: #f7dae4;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
}
.container {
  height:100vh;
  background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%) center center / 100% 100%;

  display:flex;
  flex-direction: column;
  align-items: center;
  //justify-content: flex-end;
}
.info {
  display: flex;
  flex-direction: row;
  width:100%;
  height: 90%;
}
.leftbox {
  width:100%;
  
}
.rightbox {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  align-items: center;
  width: 30%;
}
.bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  justify-content: center;
  font-size: 1.2rem;
}
 .el-input {
  width: 350px;
  margin: 20px 10px;
}
.showData {
  border: black solid 1px ;
  height: 90%;
  width:100%;
}
</style>
