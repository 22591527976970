<template>
  <div class="warningInfo">
    <Breadcrumb :title="$route.meta.title" />
    <div class="searchWrap">
      
      <div  class="searchNews">
        <div class="itemWrap">
          <label>日期</label>
          <el-date-picker type="daterange" :editable="true" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="開始日期" 
            end-placeholder="結束日期"
            v-model="rangeDate"
          ></el-date-picker>
        </div>
        <div class="itemWrap">
          <label>網站</label>
          <el-select v-model="selSites" placeholder="請選擇新聞網站" no-data-text="目前無名單" multiple clearable>
            <!-- <el-option key="999" label="全部" value="" ></el-option>  -->
            <el-option v-for="(item,index) in webSiteOptions" :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="itemWrap">
          <label>關鍵字</label>
          <el-input v-model.trim="keyword" placeholder="請輸入新聞關鍵字" clearable></el-input>
        </div>
        <div class="itemWrap" style="margin-left:15px;">
          <el-button type="primary" @click="handleSearch">
            <font-awesome-icon icon="search"  style="margin-right:5px;" />查詢
          </el-button>
          <el-button type="danger" @click="openAddProjSort = true" >
            <font-awesome-icon icon="plus" style="margin-right: 5px"/>加入專卷
          </el-button>
        </div>
      </div>   

      <div class="content">
        <!-- *新聞資料* -->
        <el-table style="width: 100%" ref="multipleTable" :data="tableData"  :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}"  empty-text="暫無數據" @select="handleSelectionChange" >
          <el-table-column type="selection" width="50" ></el-table-column>
          <el-table-column prop="NewsTitle" label="新聞標題" >
            <template slot-scope="scope">
              <a class="goDetailPage" @click="goDetailPage(scope.row)">{{scope.row.NewsTitle }}</a>
            </template></el-table-column>
          <el-table-column prop="NewsTime" label="新聞時間" min-width="15%">
            <template slot-scope="scope">
              <div>{{ scope.row.NewsTime | moment("YYYY-MM-DD") }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="NewsSite" label="新聞網站" min-width="20%"></el-table-column>
          <el-table-column prop="NewsChannel" label="新聞頻道" min-width="10%"> </el-table-column>
        </el-table>
        
      </div>
      <Pagination @currentChange="pageChange" :pageSize="this.listQuery.Page_Size" :propsCurrentPage="this.listQuery.Page_Num" :totalLists="totalLists" ></Pagination>
    </div> 

    <el-dialog title="請選擇專卷主題" :visible.sync="openAddProjSort" width="40%" center>
      <div style="display:flex; flex-direction:column; align-items:center;">
        <div class="projMaintain__searchBox--sort">
          <label>專卷分類：</label>
          <el-select v-model="folder" placeholder="請選擇專卷" no-data-text="無數據" @change="getTopicList">
            <el-option label="請選擇" value=""></el-option>
            <el-option :label="item.FolderCategory" :value="item.FolderID" v-for="item in folerList" :key="item.FolderID"></el-option>
          </el-select>
        </div>
        <!-- 專卷主題 -->
        <div class="projMaintain__searchBox--theme" style="margin-top:20px;">
          <label>專卷主題：</label>
          <el-select v-model="topic" placeholder="請選擇專卷主題" v-if="folerList.length > 0" no-data-text="無數據" >
            <el-option :label="item.TopicName" :value="item.TopicID" v-for="item in topicList" :key="item.TopicID"></el-option>
          </el-select>
          <el-select v-model="topic" placeholder="請先選擇專卷分類" :disabled="!folder" v-else></el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTopic()"><font-awesome-icon icon="plus"  style="margin-right:5px;" />加入主題</el-button>
        <el-button type="danger" @click="openAddProjSort = false"><font-awesome-icon icon="times" style="margin-right:5px;" />關閉</el-button>
      </span>
    </el-dialog>  

  </div>
</template>

<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from '../../src/components/Breadcrumb.vue';
import moment from "moment";

export default {
  components: {Breadcrumb,Pagination},
  data() {
    return {
      placeholder:"",
      
      totalLists: 0,
      openAddProjSort : false,
      searChsanInputListID:"",
      searChsanSearchType:"",
      webSiteOptions: [],
      newsSelectValue:"searchNews",
      rangeDate:[moment().add(-3, "days").format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
      listQuery: {
        // Start_Date : this.rangeDate[0],
        // End_Date : this.rangeDate[1],
        Page_Num : 1,
        Page_Size : 10,        
        SearchContent: []
      },
      folder:"",
      topic:"",      
      folerList: [],
      topicList: [],       
      selSites: [],    
      keyword: "",
      tableData: [],
      // userRole: this.$store.state.userInfo.Role,
      multipleSelection:[],
      manageAllSelection:{
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      },
      selectData: [],
      // selectPage: [],
    };
  },
  computed: {
    composeName(row){
      console.log(row);
    },
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format('YYYY-MM-DD HH:mm:ss');
      };
    },


  },
  methods: {  
    // 儲存專卷
    saveTopic() {
      // console.log("選取的專卷ID",this.projTheme);
      console.log("選取的NewsId", this.selectData)
      // console.log("頁數有勾選的",this.selectPage);
      let errList=[];
      let qry = { "FolderID": this.folder, "TopicID": this.topic, "NewsID": "", "isSys": false };      
      this.selectData.forEach(x=>{
        x.forEach(y=>{
          qry.NewsID = y.DataID;
          qry.NewsTitle = y.NewsTitle;
          qry.NewsSite = y.NewsSite;
          qry.NewsChannel = y.NewsChannel;
          qry.NewsTime = y.NewsTime;
          // console.log(qry);
          this.$api.folder.addTopicNews(qry).then(res=>{
            // console.log("success",res);
          },err => {
            errList.push(y);
          });
        });
      });


      if(errList.length > 0) {
        this.$notify.error({
          title: "錯誤",
          message: "儲存中發生錯誤",
          type: "Error"});
      }
      else {
        this.$notify({
          title: "成功",
          message: "儲存成功!",
          type: "success",
        });
        this.openAddProjSort = false;
      }

    }, 

    async handleSearch(){
      this.listQuery.Page_Num = 1;
      this.selectData = [];   
      await this.getList(true)
    },

    async getWebSiteOptions() {
      return new Promise((resolve)=>{
        let temp = {
          Type: ['NewsSite'],
        };
        this.$api.apimgrs.getOptions(temp).then((res) => {
          this.webSiteOptions = res.NewsSite;          
          resolve()
        });
      })
    },

    async getList(isSys) {      
      
      if ( !isSys ) 
        this.listQuery.isSys = false;
      else 
        this.listQuery.isSys = true;

      this.$store.dispatch('loadingHandler', true);

      

      if( !! this.rangeDate) {
        this.listQuery.Start_Date = this.rangeDate[0];
        this.listQuery.End_Date = this.rangeDate[1];
      }
      else {
        delete this.listQuery.Start_Date;
        delete this.listQuery.End_Date;
      }
      
      

      if( this.selSites.length == 0) {
        delete this.listQuery.NewsSite
      }
      else {
        this.listQuery.NewsSite = this.selSites;
      }

      if( this.keyword.length==0)
        delete this.listQuery.SearchContent;
      else {      
        let sc = [];
        sc.push(this.keyword);
        this.listQuery.SearchContent = sc;
      }

      await this.$api.apimgrs.searchNews(this.listQuery).then((res)=>{
        this.tableData = res.data;
        this.totalLists = res.total;
        // console.log(res.data);
        // this.$store.dispatch('loadingHandler', false);
        this.makeTableShowChecked();

      }, err => {
        this.$notify.error({
        title: "錯誤",
        message: "網路發生錯誤",
        type: "Error"});
      });
      this.$store.dispatch('loadingHandler', false);

    },

    // makeTableShowChecked(){
    //   if( this.manageAllSelection.length > 0)
    //     this.$nextTick(()=>{
    //         this.tableData.forEach((item) => {
    //         this.manageAllSelection.forEach((selectedItem) => {
    //                 if (item.DataID === selectedItem.DataID) {
    //                 this.$refs.multipleTable.toggleRowSelection(item, true);
    //                 }
    //             });
    //         });
    //     })
    // },     

    makeTableShowChecked(){
      // console.log("makeTableShowChecked")
      if( this.selectData.length > 0) {        
        this.$nextTick(()=>{
          this.tableData.forEach((item) => {
            const checkedDataIDs = this.selectData[this.listQuery.Page_Num-1];
            if( checkedDataIDs == undefined)
              return;
            // console.log("temp",temp);
            checkedDataIDs.forEach((selectedItem) => {
              if (item.DataID === selectedItem.DataID) {
                // console.log(item);
                this.$refs.multipleTable.toggleRowSelection(item, true);
              }
            });
          });
        });

      }
    },      

    goDetailPage(data) {
        console.log(data);
        let routeUrl = this.$router.resolve({
            path: "/detailNews",
            query: { id: data.DataID },                
        });
        window.open(routeUrl.href, "_blank");
    },   
  
    
    
    async getTopicList(isSys) {
      this.$store.dispatch("loadingHandler", true);
      this.topicList = [];

      let req = {Page_Size: 100, Page_Num: 1, FolderID: this.folder };     
      if ( !isSys ) 
          req.isSys = false;
      else 
          req.isSys = true;
      //console.log(req);

      await this.$api.folder.searchTopic(req).then((res)=>{                
          //console.log(res);        
          this.topicList = res.data;
          this.totalLists = res.total;
          //console.log(this.topicList);        
      });        

      this.$store.dispatch("loadingHandler", false);
    },

    async getFolderList(isSys) {
      this.folerList = [];

      let req = {Page_Size: 100, Page_Num: 1 };     
      if ( !isSys ) 
          req.isSys = false;
      else 
          req.isSys = true;      

      await this.$api.folder.searchFolder(req).then((res)=>{                
          this.folerList = res.data;          
      });
    },      

    handleSelectionChange(val) {    
      this.selectData[this.listQuery.Page_Num - 1] = val;
    },    

    async pageChange(val) {
      this.listQuery.Page_Num = val;      
      await this.getList(false);
    }, 
  },
  async mounted() {
    await this.getWebSiteOptions();    
    this.selectData = [];   
    await this.getList(true);
    await this.getFolderList(true);
  },
};
</script>

<style lang="scss" scoped>
// * {
//   outline: red solid 1px;
// }

.goDetailPage {
  font-size: 1.1rem;
}

.warningInfo {
  background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  min-height: 100vh;
  padding: 0px 0px;



  .searchWrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .searchNews{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      

      .itemWrap{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label{
          white-space: nowrap;
          display: block;
          text-align: right;
          margin-right: 10px;
        }
      }
    }

    .content {
      display: flex;
      justify-content: center;
      margin: 15px 5%;  
      width: 90%;    


    }

  }

      #page {
        width: 90%;
      }
  .WarningListPopUp {
    width: 100%;
    height: 100vh;
    background-color: rgba(133, 133, 133, 0.7);
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup {
      position: relative;
      text-align: center;
      width: 20%;
      padding: 5%;
      background-color: white;
      border-radius: 10px;
      .el-icon-close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  a{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    
  }
}
</style>
