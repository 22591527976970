import defaultSettings from "@/settings";

const title = defaultSettings.title || "商情資料查詢系統";

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}
