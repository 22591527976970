import request from "../request";
import sysrequest from "../sysrequest";
import axios from 'axios';
const api = {
    searchA01(data) {        
        return sysrequest({
            url: "/bid/demo/immediate_search/minds01/",
            method: "post",
            data,
        });      
    }, 
    searchA02(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds02/",
            method: "post",
            data,
        });
    },
    searchA03(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds03/",
            method: "post",
            data,
        });
    },
    searchA04(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds04/",
            method: "post",
            data,
        });
    },
    searchA05(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds05/",
            method: "post",
            data,
        });
    },
    searchA06(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds06/",
            method: "post",
            data,
        });
    },
    searchA07(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds07/",
            method: "post",
            data,
        });
    },
    searchA08(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds08/",
            method: "post",
            data,
        });
    },
    searchA09(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds09/",
            method: "post",
            data,
        });
    },
    searchA10(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds10/",
            method: "post",
            data,
        });
    },
    searchA11(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds11/",
            method: "post",
            data,
        });
    },
    searchA12(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds12/",
            method: "post",
            data,
        });
    },
    searchA13(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds13/",
            method: "post",
            data,
        });
    },
    searchA14(data) {
        return sysrequest({
            url: "/bid/demo/immediate_search/minds14/",
            method: "post",
            data,
        });
    },
};
export default api;