<template>
  <div id="infoDetail">
    <Breadcrumb :title="$route.meta.title" />
    <el-row class="detailPage">
      <el-col :span="16">
        <div class="detailPage__leftBox">
          <div class="detailPage__leftBox--newsInfo">
            <label>新聞標題：<strong>{{ detailList.NewsTitle }}</strong></label>
            <el-row style="width:100%;font-size:1.1rem;">
              <el-col :span="12">
                <label>新聞來源：<strong>{{ detailList.NewsAuthor }}</strong></label>
              </el-col>
              <el-col :span="12">
                <label>新聞時間：<strong>{{detailList.NewsTime | moment("YYYY-MM-DD HH:mm")}}</strong></label>
              </el-col>
            </el-row>
          </div>
          <div class="detailPage__leftBox--newsContent">
            <!-- 高亮操作 -->
            <div class="markKeyWord">
              <div class="markKeyWord__markIcon">
                <el-tooltip effect="dark" content="人名" placement="top">
                  <font-awesome-icon @click="handleTextHighlight('titlePeople')" icon="user"/> 
                </el-tooltip>

                <el-tooltip effect="dark" content="組織" placement="top">
                  <font-awesome-icon @click="handleTextHighlight('org')" icon="building"/> 
                </el-tooltip>

                <el-tooltip effect="dark" content="地點" placement="top">
                  <font-awesome-icon @click="handleTextHighlight('place')" icon="map-marker-alt"/>
                </el-tooltip>

                <el-tooltip effect="dark" content="國家" placement="top">
                  <font-awesome-icon @click="handleTextHighlight('nation')" icon="flag"/>
                </el-tooltip>
              </div>
            </div>
            <!-- 新聞詳細內文 -->
            <span v-html="newsDescription"></span>
            <!-- <text-highlight :queries="queries" :class="hightLightClassStyle">
              {{ newsDescription }}
            </text-highlight> -->
            <div class="detailPage__footer">
              <el-button type="danger" @click="closeDetail"><font-awesome-icon icon="times"  style="margin-right:5px;" />關閉</el-button>
            </div>
          </div>
        </div>
      </el-col>
      
      <!-- 關鍵字 -->
      <el-col :span="8">
        <div class="detailPage__rightBox">
          <div class="detailPage__rightBox--tagBox">
            <el-collapse v-model="activeName" >
              <el-collapse-item title="人名" name="1" class="item1 tagbox">
                <div class="d-flex" v-if="rightTP.length > 0">
                  <el-tag v-for="(item, index) in rightTP" :key="index">
                    <span > {{ item }}</span>                    
                  </el-tag>
                </div>
                <p class="noData" v-else>無資料</p>
              </el-collapse-item>

              <el-collapse-item title="組織" name="2" class="item2 tagbox">
                <div class="d-flex" v-if="rightOrg.length > 0">
                  <el-tag v-for="(item, index) in rightOrg" :key="index">                    
                      <span > {{ item }}</span>                      
                    
                  </el-tag>
                </div>
                <p class="noData" v-else>無資料</p>
              </el-collapse-item>

              <el-collapse-item title="地點" name="3" class="item3 tagbox">
                <div class="d-flex" v-if="Place.length > 0">
                  <el-tag v-for="(item, index) in Place" :key="index">
                    {{ item }}
                  </el-tag>
                </div>
                <p class="noData" v-else>無資料</p> 
              </el-collapse-item>

              <el-collapse-item title="國家" name="4" class="item4 tagbox">
                <div class="d-flex" v-if="Nation.length > 0">
                  <el-tag v-for="(item, index) in Nation" :key="index">
                    <span> {{ item }}</span>
                  </el-tag>
                </div>
                <p class="noData" v-else>無資料</p>
              </el-collapse-item>

            </el-collapse>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import Breadcrumb from '../../src/components/Breadcrumb.vue';

export default {
    components: {Breadcrumb},

  data() {
    return {
      pcomid:"",
      pcomname:"",
      paddress:"",
      prepname:"",

      projSort : "",
      projTheme : "",
      sortList : [],
      themeList: [],      
      openWatchList: false,
      // userRole: this.$store.state.userInfo.Role,
      newsDescription:"",//新聞內文
      newsContent:"", //新聞內文
      queries: [],//欲高亮的字串
      Nation: [],//國家
      Place: [],//地點
      TitlePeople: [], //人名
      rightTP: [], 
      openAddProjSort : false,
      Org: [],//組織
      rightOrg: [],
      activeName: "1",
      detailList: {},
      hightLightClassStyle: "",
      listQuery:{
        DataID: this.$route.query.id,
        Page_Num:1,
        Page_Size:10
      },
      manageAllSelection:{
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      }
    };
  },
  computed:{
    isOperator(){
      // let result = null;
      // if(this.userRole==='operator'){
      //   result = true
      // }else{
      //    result = false
      // }
      // return result
      return false;
    },
    getBG() {
      return (id) => {
        let setBG = "";
        switch (id) {
          case 0:
            setBG = "#BBFFFF";
            break;
          case 1:
            setBG = "#BBFFBB";
            break;
          case 2:
            setBG = "#FFFFCE";
            break;
          case 3:
            setBG = "#FFD9EC";
            break;
          case 4:
            setBG = "#C0C0C0";
            break;
        }
        return setBG;
      };
    },
  },
  methods: {

    addWatchListItem(data){
      this.pcomid = "";
      this.pcomname = data.fullname;
      this.paddress = "";
      this.paddress = "";
      this.openWatchList = true;
    },

    closeDialog() {
      this.openWatchList = false;
    } , 
    getThemeList() {
      this.projTheme = "";
      this.projThemeInfo = {};
      this.getReelTopic();
    },
    getReelTopic() {
      let qry = {
        "CreateUniComNm": this.$store.state.userInfo.UniComNm,
        "Page_Num":1,
        "Page_Size":10,
        "ReelTypeID":[this.projSort]
      }
      
      console.log(qry);
      this.$api.reel.getReelTopic(qry).then((res)=>{
        this.themeList = res.data;
        //console.log(this.tableData);
        //this.totalLists = res.total;
      });
      this.$store.dispatch('loadingHandler', false);    
    },         

    toggleDialog() {
      this.pcomid = "";
      this.pcomname = "";
      this.paddress = "";
      this.paddress = "";      
      this.openWatchList = true;
    },
    getList() {
      this.$store.dispatch("loadingHandler", true);
      // console.log(this.listQuery);
      
      this.$api.apimgrs.getNews(this.listQuery).then((res) => {
        // console.log(res.data[0]);
        this.detailList = res.data[0];
        this.newsDescription = res.data[0].NewsContent
        this.newsContent = res.data[0].NewsContent;
        this.manageAllSelection.searchNews.push(res.data[0])
        this.setTagTitle(res);
        this.markAllEntities();
        this.$store.dispatch("loadingHandler", false);
      });
    },
    // 處理右側標籤資料
    setTagTitle(res) {
      this.Nation = res.data[0].CtyName == "" ? [] : res.data[0].CtyName;
      this.Place = res.data[0].LocName == "" ? [] : res.data[0].LocName;
      //this.TitlePeople = res.data[0].PplName == "" ? [] : res.data[0].PplName;
      //this.Org = res.data[0].ComName == "" ? [] : res.data[0].ComName;

      // 人名      
      let UniqName = [];
      let fb=[];
      if( res.data[0].PplName == "")
        UniqName = [];
      else {
        
        res.data[0].PplName.forEach(x => {
          let obj = new Object();
          // console.log(x)
          if( x.para.length==0)
            obj.fullname = x.name;
          else
            obj.fullname = x.para;

          if( x.name.length>1)
            obj.briefname = x.name;

          if( obj.fullname!=obj.briefname)
            fb.push(obj.briefname);
          UniqName.push(obj);
        });
      }   
      this.rightTP = [];
      UniqName.forEach(x => {
        if( fb.indexOf(x.fullname)<0 )
          this.rightTP.push(x.fullname);
      });

      
      this.TitlePeople = UniqName;

      // 組織名      
      let UniqCom = [];
      let fb2=[];
      if( res.data[0].ComName == "")
        UniqCom = [];
      else {
        res.data[0].ComName.forEach(x => {
          let obj = new Object();
          if( x.para.length > 0 ) {
            obj.fullname = x.para;
          }
          else
            obj.fullname = x.name;
          
          obj.briefname = x.name;
          if( obj.fullname!=obj.briefname)
            fb2.push(obj.briefname);          
          UniqCom.push(obj);
        });
      } 
      this.rightOrg = [];
      UniqCom.forEach(x => {
        if( fb2.indexOf(x.fullname)<0 )
          this.rightOrg.push(x.fullname);
      });
      //console.log(UniqCom);
      this.Org = UniqCom;
      
    },
    getThemeItem(val) {
      //console.log(val);
      // this.$store.dispatch("loadingHandler", true);
      // // 撈取子層資料帶入modal輸入框
      // const getFather = this.sortList.filter(
      //   (res) => res.name == this.projSort
      // )[0];
      // this.projThemeInfo = getFather.children.filter(
      //   (resp) => resp.name == this.projTheme
      // )[0];
      // this.getList(this.projThemeInfo.id);
      // this.openMemo = false;
    },   
    // 儲存專卷
    saveTopic() {
      let newIds = [];
      console.log(this.$route);
      newIds.push(this.$route.query.id)
      const qry = {data : [ { ReelTopicID: this.projTheme, NewsID: newIds } ] };
       console.log(qry);
      this.$api.reel.addTopicNews(qry).then((res)=>{
        this.$notify({
          title: "成功",
          message: "儲存成功!",
          type: "success",
        });
      }, err => {
        this.$notify.error({
        title: "錯誤",
        message: "網路發生錯誤",
        type: "Error"});
      });
    },     
    markAllEntities(){
      let setKeyWord = [];
      console.log(this.TitlePeople);
      console.log(this.org);
      console.log(this.Place);
      console.log(this.Nation);    
      this.highlight3(this.Place, 2);
      this.highlight3(this.Nation, 3);

      let tp = [];
      // console.log("tp", this.TitlePeople);
      this.TitlePeople.forEach(x=>{

        tp.push(x.fullname);
        tp.push(x.briefname);
      }); 
      this.highlight3(tp, 0);
      

      let orgtemp = [];
      this.Org.forEach(x=>{
        orgtemp.push(x.fullname);
        orgtemp.push(x.briefname);
      }); 
      this.highlight3(orgtemp, 1);
      
    },
    highlight3(tags, bg){
      // console.log(tags);
      var idx = 0;
      tags.forEach(tag => {
        idx = this.newsDescription.indexOf(tag);

        if( idx >=0 )
        {
          this.newsDescription = this.newsDescription.replaceAll(tag,"<span style='background-color:" +  this.getBG(bg) + ";'>" + tag + "</span>");
        }        
      });      
    },
    // 文字高亮
    handleTextHighlight(tagName) {
      this.newsDescription = this.detailList.NewsContent;
      let hightLightText = "";

      switch (tagName) {
        case "nation":
          this.highlight3(this.Nation, 3);
          break;
        case "place":
          this.highlight3(this.Place, 2);
          break;
        case "titlePeople":
          let tp = [];          
          this.TitlePeople.forEach(x=>{
            if(x.fullname.length>1) 
              tp.push(x.fullname); 
            if(x.briefname.length>1)                           
              tp.push(x.briefname);
          }); 

          let uniq = tp.filter( (item,pos) => tp.indexOf(item) == pos);
          this.highlight3(uniq, 0);
          break;
        case "org":
          let orgtemp = [];
          this.Org.forEach(x=>{
            if( x.fullname.length>1)
              orgtemp.push(x.fullname);
            if( x.briefname.length>1)
              orgtemp.push(x.briefname);
          }); 
          let uniq2 = orgtemp.filter( (item,pos) => orgtemp.indexOf(item) == pos);
          this.highlight3(uniq2, 1);
          break;
      }

    },

    closeDetail() {
      // this.$confirm("確定要關閉此頁面嗎？", "提示", {
      //   confirmButtonText: "確定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   window.close();
      // });
      window.close();
    },
  },
  async mounted() {
    //console.log(this.$route);
    this.getList();    
  },
};
</script>

<style lang="scss" >
// * {
//   outline: 1px solid red;
// }

.fa-shadow {
  text-shadow: 3px 3px rgba(255, 165, 0, 0.75);
}

#infoDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 1024px;
  //padding: 0% 5%;
  background: linear-gradient(45deg, #fff 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  box-sizing: border-box;

  .detailPage {
    padding: 0px 5%;
    &__leftBox {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;

      &--newsInfo {
        width: 100%;
        display: flex;
        font-size: 1.3rem;
        margin-bottom: 30px;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        label {
          padding-bottom: 8px;
        }
      }

      &--newsContent {
        width: 100%;
        // padding: 18px;
        font-size: 1.2rem;
        line-height: 35px;
        box-sizing: border-box;
        min-height: 300px;

        overflow-y: auto;
        // border: 1px solid #d32b68;
        box-sizing: border-box;

        p {
          margin: 0;
          letter-spacing: 1.6px;
          line-height: 1.6rem;
        }

        .markKeyWord {
          width: 100%;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          &__markIcon {
            padding: 8px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            svg {
              padding: 0 8px;
              transition: 0.4s;
              cursor: pointer;

              &:nth-child(1) {
                color: #4dffff;                
                filter: drop-shadow(2px 2px 1px black);
              }
              &:nth-child(2) {
                color: #79ff79;
                filter: drop-shadow(2px 2px 1px black);
              }
              &:nth-child(3) {
                color: #ffe66f;
                filter: drop-shadow(2px 2px 1px black);
              }
              &:nth-child(4) {
                color: #ff95ca;
                filter: drop-shadow(2px 2px 1px black);
              }

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }

        .titlePeople > mark {
          background: rgb(187, 255, 255) !important;
          font-weight: 700;
          font-size: 1.2rem;
        }
        .org > mark {
          background: rgb(187, 255, 187) !important;
          font-weight: 700;
          font-size: 1.2rem;
        }
        .place > mark {
          background: rgb(255, 255, 206) !important;
          font-weight: 700;
          font-size: 1.2rem;
        }
        .nation > mark {
          background: rgb(255, 217, 236) !important;
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
    }

    &__rightBox {
      width: 100%;
      padding: 95px 8px 8px 8px;
      box-sizing: border-box;

      &--tagBox {
        width: 100%;
        // border: 1px solid #ccc;
        // border-radius: 4px;
        // margin-bottom: 15px;
        // margin-top: 15px;
        // box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.5);

        &:last-child {
          margin: 0;
        }

        .noData {
          margin: 0 16px;
          font-size: 1rem;
        }

        .tagbox {
          border: 1px solid #ccc;
          border-radius: 4px;
          margin-bottom: 15px;
          margin-top: 15px;
          box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.5);          
          margin-bottom: 2%;
        }

        .item1 {

          .el-collapse-item {
            padding-bottom: 0px;
            &__header {
              background: #bbffff;              
              border-bottom: 1px solid #ccc;
              padding: 0 16px;
            }
          }
        }
        .item2 {
          .el-collapse-item {
            &__header {
              background: #bbffbb;
              border-bottom: 1px solid #ccc;
              padding: 0 16px;
            }
          }
        }
        .item3 {
          .el-collapse-item {
            &__header {
              background: #ffffce;
              border-bottom: 1px solid #ccc;
              padding: 0 16px;
            }
          }
        }
        .item4 {
          .el-collapse-item {
            &__header {
              background: #ffd9ec;
              border-bottom: 1px solid #ccc;
              padding: 0 16px;
            }
          }
        }

        .d-flex {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-right: 4px;
          .el-tag {
            margin: 8px;
            &--light {
              cursor: pointer;
            }
          }
        }
      }

      &--tag {
        padding: 4px 0;
        .collapseTag {
          padding: 4px 8px;
          background: #eee;
          border-radius: 10px;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &__footer {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


}
</style>
