<template>
    <div class="authBox">
      <Breadcrumb :title="$route.meta.title" />      
      
      <div class="authBox--searchBox">
        <div class="itemWrap">
          <label>使用者名稱</label>
          <el-input v-model.trim="userQuery.UserName" placeholder="請輸入使用者名稱" clearable ></el-input>
        </div>
        <div class="itemWrap" style="margin-left:15px;">
          <el-button type="primary" @click="handleSearch" ><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>
        </div>
      </div>

      <div class="authBox--listBox">
        <el-table :data="tableData" ref="multipleTable" :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}" style="width: 100%"  empty-text="暫無數據">
        <!-- <el-table-column type="index" label="序號" width="60" ></el-table-column> -->
        <el-table-column label="使用者名稱" prop="UserName" min-width="20%"></el-table-column>
        <el-table-column label="API名稱" prop="AccessApiNameStr" >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
            <div class="accountManage__listBox--Content--userFunc" >
                <!-- <el-tooltip effect="dark" content="刪除" placement="left" style="color:#000;text-shadow:2px 2px 2px grey">
                <el-button type="text" @click="deleteUser(scope.row)">
                    <i class="el-icon-delete"></i>
                </el-button>
                </el-tooltip>                 -->
                <el-tooltip effect="dark" content="編輯" placement="right" style="color:#000;text-shadow:2px 2px 2px grey" >
                <el-button @click="editUser(scope.row)" style="background-color:transparent;border:0px;" ><i class="el-icon-edit"></i>
                </el-button>                 
                </el-tooltip>
            </div>
            </template>
        </el-table-column>
        </el-table>
        <Pagination @currentChange="pageChange" :pageSize="Page_Size" :propsCurrentPage="Page_Num" :totalLists="totalLists"> </Pagination>          
      </div>

      <el-dialog :title="setAccountTitle" :visible.sync="setAccount" width="60%" center>
          <div style="display:flex; flex-direction:column; align-items:center;">
              <div class="popBox">
                  <div class="popBox--popRightBox" >
                      <div style="margin-bottom: 2%;">請選擇授權使用的Api</div>            
                      <el-card :body-style="{ padding: '0px' }" >
                          <div class="popRightBox--content">
                            <el-checkbox-group v-model="checkList" class="el-checkbox-groupBox">
                              <el-checkbox :label="item.Name" :value="item.EndPoint" v-for="item in allApis" :key="item.ID">
                                {{ item.Name }}
                              </el-checkbox>
                            </el-checkbox-group>                 
                          </div>
                      </el-card>                         
                  </div>                
              </div>
              <span slot="footer" class="dialog-footer" style="margin-top: 3%;">
                  <!-- <el-button type="primary" @click="addUserAccount()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button> -->
                  <el-button type="primary" @click="editUseApi()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button>
                  
                  <el-button type="danger" @click="setAccount = false"><font-awesome-icon icon="times" style="margin-right:5px;" />取消</el-button>
              </span>            
          </div>
      </el-dialog>


    </div>
</template>

<script>
import Breadcrumb from "../components/Breadcrumb.vue";
import Pagination from "../components/Pagination.vue";
export default {
  // name : 'account-management',
  components: {Breadcrumb,Pagination},
  data() {
    var checkAccount = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (! value) {
        return callback( new Error('請輸入電子郵件' ))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback( new Error('請輸入正確的電子郵件格式' ))
        }
      }, 100 )
    };
    return {
      setAccount: false,
      setAccountTitle: "新增帳號API授權",   
      userMode: "add",         
      tableData: [],
      totalLists: 0,
      checkList: [],
      allApis: [],
      Page_Num: 1,
      Page_Size: 10,      



      treedata: [],
      /* 查詢帳號 */
      userQuery: {
        //UserID: this.$store.state.userInfo.UserID,
        //UniComNm: this.$store.state.userInfo.UniComNm,
        UserName: "",
        Role: "user",
        DeptName: "all",
        Page_Size: 10,
        Page_Num:1
      },
      addUserQuery: {
        UserName:"",
        Role:"user",
        DeptName:"",
        Contact:"",
        Account:"",        
        Memo:""
      },
      rules_setAccount: {
          UserName: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          DeptName: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          Contact: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          Account: [{ required: true, validator: checkAccount, trigger: "blur" }],
      },      
    };
  },
  computed: {
    
  },
  methods: {
    handleNodeClick() {

    },
     

    addUser() {
        this.addUserQuery.Account="";        
        this.userMode="add";
        this.setAccount= true;
    },

    async editUser(val) {
        this.setAccountTitle = "編輯帳號API授權";
        this.userMode ="edit";
        this.addUserQuery = val;

        console.log(val);
        // 清空userTree 
          // let that2 = this;
          // setTimeout(function () {              
          //   that2.$refs.userTree.setCheckedKeys([]);
          // }, (100));   

      
        if(!! val.AccessApiName)
        {
          this.checkList =val.AccessApiName;
        }  
        else
        {
          this.checkList = [];
        }

        this.setAccount = true;

    },

    async deleteUser(val) {
        //console.log(val);
        let deleteId = val.UserID;
        let qry = { UserID: deleteId};
        //console.log(qry);
        await this.$api.apimgrs.deleteUser(qry).then(res=>{
            console.log(res);
            this.$notify({
              title: "成功",
              message: "刪除使用者成功",
              type: "success",
            });
            this.setAccount= false;
        }).catch(err=>{
            this.$notify({
              title: "發生錯誤",
              message: "刪除使用者發生錯誤",
              type: "error",
            });
        });
        await this.getList();

    },

    async editUseApi() {
      if( this.checkList.length==0) {
            this.$notify({
              title: "請選擇授權Api",
              message: "尚未勾選Api",
              type: "warning",
            });
            return;
      };

      // this.addUserQuery  表示使用者選了那一個.....
      // console.log("chkList",this.checkList );

      let piNo = this.checkList.map(y=>{
        return this.allApis.filter(x=>x.Name==y)[0].ID.toString();
      })


      //console.log("piNo",piNo);
      this.$store.dispatch("loadingHandler", true);
      let qry = { UserID:this.addUserQuery.UserID, AccessApi : piNo };
      //console.log(qry);
          await this.$api.apimgrs.updateUser(qry).then(res=>{
              //console.log(res);
              this.$notify({
                title: "成功",
                message: "編輯API授權成功",
                type: "success",
              });
              this.setAccount= false;
          }).catch(err=>{
              this.$notify({
                title: "發生錯誤",
                message: "編輯API授權發生錯誤",
                type: "error",
              });
          });
      await this.getList(true);
      this.$store.dispatch("loadingHandler", false);
    },

    async addUserAccount() {
        
        let ut = this.$refs.userTree.getCheckedKeys();
        if( ut.length==0)
        {
            this.$notify({
              title: "請選擇授權API",
              message: "尚未勾選API",
              type: "warning",
            });
        }
        let accessApi = ut.join(',');
        let qry = this.addUserQuery;
        qry.AccessApi = accessApi;
        // 20221026, 新增User時, 不要有UserID, 
        //delete qry.UserID;
        console.log(qry);
        this.$store.dispatch("loadingHandler", true);
        if( this.userMode=="add") {
          await this.$api.apimgrs.addUser(qry).then(res=>{
              console.log(res);
              this.$notify({
                title: "成功",
                message: "新增使用者成功",
                type: "success",
              });
              this.setAccount= false;
          }).catch(err=>{
              this.$notify({
                title: "發生錯誤",
                message: "新增使用者發生錯誤",
                type: "error",
              });
          });
        }
        else { // 編輯
          await this.$api.apimgrs.updateUser(qry).then(res=>{
              //console.log(res);
              this.$notify({
                title: "成功",
                message: "編輯使用者成功",
                type: "success",
              });
              this.setAccount= false;
          }).catch(err=>{
              this.$notify({
                title: "發生錯誤",
                message: "編輯使用者發生錯誤",
                type: "error",
              });
          });
        }
        this.$store.dispatch("loadingHandler", false);

        await this.getList(true);
        
        //console.log(accessApi);
    },

    async handleSearch() {
        //this.userQuery.Role="user";   
        this.Page_Num = 1;     
        await this.getList(true);
    },

    async pageChange(val) {
      this.$store.dispatch("loadingHandler", true);
      this.Page_Num = val;
      await this.getList(true);
      this.$store.dispatch("loadingHandler", false);
    },  
    
    async getTreeData() {

      let req= {Page_Size:100, Page_Num:1, isSys: true, IsApply: "T"};


      console.log(req);
      this.allApis = []
      await this.$api.apimgrs.getApi(req).then((res)=>{
        this.allApis = res.data;
        // this.totalLists = res.total;
      });
      console.log(this.allApis);
        let childs = [];
        this.allApis.forEach(x=>{
            childs.push({label: x.Name, id: x.ID, endpoint: x.EndPoint});
        });
        let node = { label: "Api列表", children : childs, id: 0  };    
        this.treedata.push( node );    
    },     

 
 
    async getList(isSys) {
      this.$store.dispatch("loadingHandler", true);
      this.tableData = [];
      // let _comId = window.localStorage.getItem("comID");
      // if(!! _comId) {
      //   req.ComID = _comId;
      // }

      

      let req = {Page_Size: this.Page_Size, Page_Num: this.Page_Num}; 

      if ( !isSys ) 
        req.isSys = false;
      else 
        req.isSys = true;


      if(this.userQuery.UserName.length>0) {            
          req.UserName = this.userQuery.UserName;
      }  

      if(this.userQuery.DeptName!=="all") {            
        req.DeptID=this.userQuery.DeptName;
      }    

      //console.log(req);
      let temp = []
      await this.$api.apimgrs.getAllUser(req).then((res)=>{
        // 不顯示admin 
        res.data.forEach(x=>{
          if( x.UserName!="admin") {
            x.AccessApiNameStr = x.AccessApiName.join(' / ');
            temp.push(x);            
          }
        });

        this.tableData = temp; 
        this.totalLists = res.total;
        console.log(this.tableData);
      });

      this.$forceUpdate();
      
      this.$store.dispatch("loadingHandler", false);
    },





  },
  async mounted() {
    this.$store.dispatch("loadingHandler", true);    
    await this.getList(true);      
    this.$store.dispatch("loadingHandler", false);
    await this.getTreeData();
  },

};
</script>

<style lang="scss" scoped>
// * {
//     outline: red solid 1px;
// }


.el-checkbox-groupBox {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 3%;
  .el-checkbox {
    min-width: 23rem;
    margin: 3px 3px;
  }
}


.authBox {
  height:150vh;
  // background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  background-image: linear-gradient(to bottom,#FFF 55% ,#fff 80% , transparent 50% );
  
  background-size: cover;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;



  &--searchBox {
    padding: 0px 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2%;
    
    

    .itemWrap{
    display: flex;
    align-items: center;
    margin-right: 2%;
        label{
            white-space:nowrap;
            margin-right: 15px;
        }
    }
  }

  &--listBox {
    padding: 0px 5%;
  }
}

</style>