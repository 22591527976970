<template>
    <div class="apiBox">
        <Breadcrumb :title="$route.meta.title" />    
        <div class="apiBox__listBox">
          <section class="filterWrap">
              <!-- 時間 -->        
              <div class="itemWrap">
                <label>查詢日期：</label>
                <el-date-picker :editable="true" type="daterange" value-format="yyyy-MM-dd" range-separator="至" 
                    start-placeholder="開始日期" 
                    end-placeholder="結束日期"
                    v-model="rangeDate"
                ></el-date-picker>
              </div>
              <!-- 名單類型 -->
              <div class="itemWrap" style="margin-left:2rem;"> 
                <label>使用者名稱：</label>
                <el-select v-model="UserID" clearable placeholder="請選擇單位" no-data-text="目前無名單">                    
                    <el-option v-for="item in deptList"
                    :key="item.UserID"
                    :label="item.UserName"
                    :value="item.UserID"
                    ></el-option>
                </el-select>
              </div>
          </section>                
          <section class="filterWrap">        
              <div class="itemWrap">
                <label>API分類：</label>
                <el-select v-model="catType" clearable placeholder="請選擇分類" no-data-text="目前無名單" style="width:200px;" @change="changeCat" @clear="clearAction">                                
                    <el-option v-for="(item,idx) in allCatApi" :key="idx" :label="item.Type" :value="item.Type" ></el-option>
                </el-select>
              </div>              
              <div class="itemWrap" style="margin-left:2rem;">
                <label>API名稱：</label>
                <el-select v-model="selectedApi" clearable placeholder="請選擇名稱" no-data-text="目前無名單" style="width:250px;" :disabled="enableSelectApi" >                                
                    <el-option v-for="item in allApis" 
                    :key="item.ID"
                    :label="item.Name"
                    :value="item.ID"
                    ></el-option>
                </el-select>
              </div>            
              <div class="itemWrap" style="margin-left:2rem;">
                  <label>關鍵字：</label>
                  <el-input v-model="keyword" clearable></el-input>
              </div>
              <div class="itemWrap" style="margin-left:2rem;">
                <el-button @click="doSearch" type="primary" class="searchLog"><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>
              </div>
          </section>
          <section class="tableBox">
            <div class="content" >  
              <el-table ref="multipleTable" border :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}" style="width:100%;"  empty-text="暫無數據" :data="tableData">
                <el-table-column label="使用者名稱"  prop="User.UserName" min-width="10%" >
                </el-table-column>
                <el-table-column label="API分類"  prop="Api.Type" min-width="10%" >
                </el-table-column>               
                <el-table-column label="API名稱" prop="Api.Name" min-width="20%" >  
                </el-table-column>
                <el-table-column label="發查編號" prop="Sid" min-width="25%"  >  
                  <template slot-scope="scope">
                      <a class="goDetailPage" @click="doShowApiDetails(scope.row)">{{ scope.row.Sid }}</a>
                  </template>                 
                </el-table-column>
                <el-table-column label="是否完成"  min-width="5%">  
                  <template slot-scope="scope">
                      {{ scope.row.IsComplete=='T' ? "Y" : "N" }}
                  </template>                                   
                </el-table-column>            
                <el-table-column label="申請時間" min-width="10%" >
                  <template slot-scope="scope">
                      {{ scope.row.CreateDt | moment("YYYY-MM-DD") }}
                  </template>                                   
                </el-table-column>
                <el-table-column label="完成時間" min-width="10%" >  
                  <template slot-scope="scope">
                      {{ scope.row.LastMADt | moment("YYYY-MM-DD") }}
                  </template>                       
                </el-table-column>                     
              </el-table>
            </div>
            <div class="pageBox">
              <Pagination
                @currentChange="pageChange"
                :pageSize="this.listQuery.Page_Size"
                :propsCurrentPage="this.listQuery.Page_Num"
                :totalLists="totalLists">
              </Pagination>
            </div>        
          </section>

        </div>

        <el-dialog title="API發查明細" :visible.sync="showApiModel" width="80%" center>
          <div style="display:flex; flex-direction:column; align-items:center;">
            <el-form :model="apiModel"  ref="ruleForm_setAccount" label-width="120px">
                <el-row>
                <el-col :span="12">
                    <el-form-item label="系統名稱"  >
                        <el-input v-model="apiModel.UserName" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="API名稱" >          
                        <el-input v-model="apiModel.Name" disabled></el-input>
                    </el-form-item>
                </el-col>                
                </el-row>
                <el-row type="flex">
                <el-col :span="12">
                    <el-form-item label="SID" >
                    <el-input v-model="apiModel.Sid" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="是否完成" >
                    <el-input v-model="apiModel.IsComplete"  disabled></el-input>
                    </el-form-item>
                </el-col>                
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="申請時間" >
                    <el-input v-model="apiModel.CreateDt"  disabled></el-input>
                    </el-form-item>
                </el-col>                  
                <el-col :span="12">
                    <el-form-item label="完成時間" >
                    <el-input v-model="apiModel.LastMADt"  disabled></el-input>
                    </el-form-item>
                </el-col>
                </el-row>                
                <el-form-item label="發查內容" >
                <el-input v-model="apiModel.Query" type="textarea" :autosize="{ minRows: 3, maxRows: 5}"  disabled></el-input>
                </el-form-item>
            </el-form>      
          </div>
          <span slot="footer" class="dialog-footer">            
            <el-button type="danger" @click="showApiModel = false"><font-awesome-icon icon="times" style="margin-right:5px;" />關閉</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";

export default {

  components: { Breadcrumb ,Pagination},
  data() {
    return {
      rangeDate: [],
      tableData: [],
      tableUsage: [],
      totalLists: 0,
      enableSelectApi: true,
      allApis: [],
      keyword: "",
      deptList: [],
      totalLists: 0,
      totalUsageLists: 0,
      allCatApi:[],
      apiModel: {
        UserName:"",
        Name:"",
        Sid:"",
        IsComplete:"",
        CreateDt:"",
        LastMADt:"",
        Query:""
      },  
        
      catType:"",
      selectedApi: "",
      showApiModel: false,
      usageQuery : {
        Page_Size:10,
        Page_Num:1        
      },

      UserID:"",
      listQuery: {
        Page_Size:10,
        Page_Num:1
        
      },
      apiCat: [],
    };
  },
  computed: {
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      };
    },
  },

  methods: {

    async clearAction() {
      this.selectedApi = "";
      this.enableSelectApi = true;
    },

    async doSearch(){
      this.listQuery.Page_Num = 1;
      await this.getList();
    },

    doShowApiDetails(data) {
        
        this.apiModel.UserName = data.User.UserName;
        this.apiModel.Name = data.Api.Name;
        this.apiModel.Sid = data.Sid;
        this.apiModel.IsComplete = data.IsComplete=="T" ? "Y": "N";
        this.apiModel.CreateDt = moment(data.CreateDt).format("YYYY-MM-DD HH:mm:ss") ;
        this.apiModel.LastMADt = data.LastMADt==null ? "" : moment(data.LastMADt).format("YYYY-MM-DD HH:mm:ss") ;
        this.apiModel.Query = data.Query;        
        this.showApiModel = true;

    },    

    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.getList();
    },

    pageChange1(val) {
      this.usageQuery.Page_Num = val;
      this.getList();
    },    

    async showUsage(data) {
      //console.log(data);
      let req= { UserID: data.UserID, EndPoint: data.EndPoint, Page_Size: 10, Page_Num:1, Start_Date: this.rangeDate[0], End_Date: this.rangeDate[1] }

      let retResult = [];
      //console.log(req);
      await this.$api.apimgrs.actionRecord(req).then(res=>{
        this.tableUsage = res.data;
        //console.log(res);
        this.totalUsageLists = res.total;
      });
      //console.log(this.tableUsage);

    },

    async getApiCat() {
      
      await this.$api.apimgrs.getApiCat().then( res=>{     
        console.log(res); 
        this.allCatApi = res.ApiType;
        this.apiCat = res.ApiType.map(x=>x.Type);
      });      
    },

    async changeCat(value) {
      console.log(value);
      this.allCatApi.forEach(x=>{
        if( x.Type==value) {
          this.allApis = x.ApiList;
          this.selectedApi = "";
        }
      });
      this.enableSelectApi = false;
      // this.allApis = this.allCatApi[value].ApiList;
      
    },



    /* 取得即時發查 的所有API名稱, 以供下拉*/  
    async getRtbiApiList() {

      let req= {Page_Size:100, Page_Num:1, isSys: true};
      //console.log(req);
      this.allApis = []
      await this.$api.apimgrs.getRtbiApi().then((res)=>{
        
        this.allApis = res.ApiType[0].ApiList;
        //this.apiCat.push(res.ApiType[0].Type);
        // this.totalLists = res.total;
      });

      //console.log(this.allApis);        

    },     

    // 發查單位
    async getAllUser() {      
      let req={ "Page_Size": 20, "Page_Num": 1, "isSys": true};      

        await this.$api.apimgrs.getAllUser(req).then((res)=>{
          
          this.deptList = res.data.filter(x=>x.UserName!="admin")
          
          //this.totalLists = res.total;
        });
    },  

    async getList(isSys) {
      this.$store.dispatch("loadingHandler", true);
      let req = { Page_Num : this.listQuery.Page_Num, 
                    Page_Size: this.listQuery.Page_Size};
      // console.log("rangeDate",this.rangeDate);
      if(this.rangeDate && this.rangeDate.length==2 ) 
      {
        if(this.rangeDate[0].length > 0 && this.rangeDate[1].length > 0)
        {
          req.Start_Date = this.rangeDate[0];
          req.End_Date = this.rangeDate[1]
        }
      }

      if( ! isSys )
        req.isSys = false;
      else
        req.isSys = true;

      if( this.selectedApi.length > 0)
          req.ApiID = this.selectedApi; 

      if( this.catType.length > 0)
        req.Type = this.catType;

      if( this.keyword.length > 0)
        req.Sid = this.keyword;
      else 
        delete req.Sid;

      this.tableData = [];

      if( this.UserID.length >0 )  
        req.UserID= this.UserID;

      await this.$api.apimgrs.getRtbi(req).then((res)=>{                  
          this.tableData = res.data.filter(x=>x.User.UserName!="admin");          
          this.totalLists = res.total;
          console.log(this.tableData);
        }
      );

      this.$store.dispatch("loadingHandler", false);
      
    }, 

  },

  async mounted() {
    
    await this.getList(true);  
    


    // this.getRtbiApiList(); /* 取得即時發查 的所有API名稱, 以供下拉*/  
    this.getAllUser(); //發查單位
    this.getApiCat();// 取得api 分類


    
    
  },
}
</script>


<style lang="scss" scoped >
// * {
//     outline: red solid 1px;
// }

.apiBox {
  // outline: green solid 3px;
  // background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  background-image: linear-gradient(to bottom,#FFF 55% ,#fff 80% , transparent 50% );
  background-size: cover;
  height: 100vh;
  display: flex;   
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;   
  width:100%;
  
  &__listBox {
    
    display: flex;
    flex-direction: column;
    align-items: center;    
    width:100%;
    // outline: red solid 1px;

    
    .filterWrap{
      
      display: flex;
      flex-direction: row;
      justify-content: flex-start;   
      width:90%;         
      padding: 0px 5%;
      

      .itemWrap{
          
          // width: 500px;    
          
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1%;
          // padding: 0px 5%;
          
          label{
              margin-right: 15px;
              white-space:nowrap;
          }
          &:nth-child(4){
            width:10%;
          }

      }
    }

    .tableBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;   
      width:100%;

      .content {
        display: flex;
        justify-content: center;
        margin: 15px 5%;  
        width: 90%;          
        
        .el-table {
          // padding: 0px 5%;
          overflow: none;
        }
      }
      .pageBox {
        display: flex;      
        flex-direction: row;
        justify-content: flex-end;      
        width: 100%;

        #page {
          padding: 0px 5%;
        }
      }
    }


  }  



  .el-form {
    width:90%;
  }

  .goDetailPage {
      color: #0645ad;
      text-decoration: underline;
      cursor: pointer;
   } 

  
}
</style>