import Vue from "vue";
import Vuex from "vuex";
import { getToken, removeToken, setToken } from "../api/auth";
import API from "../api/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    token: getToken(),
    userInfo: {},
    menuList: null,
    routerFrom:""
    
  },
  mutations: {
    SETLOADING(state, payload) {
      state.loading = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USERINFO(state, payload) {
      state.userInfo = payload;
    },
    SET_MENU(state, payload) {
      // console.log("Payload",payload);
      state.menuList = payload;
    },
    SET_ROUTERFROM(state, payload){
      state.routerFrom = payload;
    }
  },
  actions: {
    loadingHandler(state, payload) {
      state.commit("SETLOADING", payload);
    },
    Login({ commit }, userInfo) {
      //console.log(userInfo);
      return new Promise((resolve, reject) => {

        // console.log("userInfo",userInfo);
        //產生formData 物件...
        var formData = new FormData();
        formData.append('username', userInfo.username);
        formData.append('password', userInfo.password);
        // const start = Date.now();
        API.apimgrs.login(formData).then(res=>{  
            // console.log(res);
           //const millis = Date.now() - start;
          if (!!res) {
            // console.log("res success SETTOKEN",res);
            setToken(res.access_token);
            commit("SET_TOKEN", res.access_token);
            window.localStorage.setItem("userID", res.UserID);
            window.localStorage.setItem("comID", res.ComID);
            window.localStorage.setItem("userRole", res.Role);
            window.localStorage.setItem("userName", res.UserName);
            window.localStorage.setItem("userInfo", res);
            commit("SET_USERINFO", res);
            
            // 設定Menu, 從
            const menu  = res.Menu;
            //console.log("Menu",menu);
            let mainMenu = menu.filter((main) => main.Pid === 0);
            //console.log("mainMenu",mainMenu);
            const childrenMenu = menu.filter((children) => children.Pid !== 0);
            const menuList = mainMenu.map((p) => {
              p.children = childrenMenu.filter((c) => c.Pid == p.ID);
              return p;
            });   

            // console.log(menuList);
            const strMenuList = JSON.stringify(menuList);
            // console.log(strMenuList);
            window.localStorage.setItem("menuList", strMenuList);

            const strMenuFlatter = JSON.stringify(res.Menu);
            window.localStorage.setItem("menuFlatter", strMenuFlatter);
            
            commit("SET_MENU", menuList);   
            
            
            resolve("success");
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          //console.log(err.response);
          reject(err.response);
        });
      });
    },

    // 台企, 用部門別來登入...
    LoginDept({ commit }, userInfo) {
      console.log(userInfo);
      return new Promise((resolve, reject) => {

        const start = Date.now();
        API.apimgrs.createToken(userInfo).then(res=>{  
          console.log(res);
           const millis = Date.now() - start;
          //  console.log(`呼叫登入API ... = ${Math.floor(millis / 1000 )}`);
          if (res) {            
            setToken(res.access_token);
            
            commit("SET_TOKEN", res.access_token);
            
            window.localStorage.setItem("userID", res.UserID);
            window.localStorage.setItem("comID", res.ComID);
            window.localStorage.setItem("userRole", res.Role);
            window.localStorage.setItem("userName", res.UserName); 
            window.localStorage.setItem("userInfo", res);           

            console.log("登入者",res);      
            commit("SET_USERINFO", res);
            
            console.log("Menu",res.Menu);
            const strMenuFlatter = JSON.stringify(res.Menu);
            window.localStorage.setItem("menuFlatter", strMenuFlatter);
            let mainMenu = res.Menu.filter((main) => main.Pid === 0);
            // console.log("mainMenu",mainMenu);
            const childrenMenu = res.Menu.filter((children) => children.Pid !== 0);
            // console.log("childrenMenu",childrenMenu);
            const menuList = mainMenu.map((p) => {
              p.children = childrenMenu.filter((c) => c.Pid == p.ID);
              return p;
            });

            // console.log(menuList);
            const strMenuList = JSON.stringify(menuList);
            // console.log(strMenuList);
            window.localStorage.setItem("menuList", strMenuList);
            commit("SET_MENU", menuList);
            resolve("success");
          } 
          else {
            resolve(res);
          }
        })
        .catch((err) => {          
          reject(err.response);
        });
      });
    },    

    // 台企, 用ePortal SSO來登入...
    LoginePortal({ commit }, userInfo) {
      // console.log("呼叫Store.LoginePortal",userInfo);
      return new Promise((resolve, reject) => {
        console.log("呼叫Store.LoginePortal", userInfo);
        const start = Date.now();
        
        API.apimgrs.eportal_login(userInfo).then(res=>{  
          console.log("已登入後端權限", res);
           const millis = Date.now() - start;
          //  console.log(`呼叫登入API ... = ${Math.floor(millis / 1000 )}`);
          if (res) {            
            setToken(res.access_token);
            
            commit("SET_TOKEN", res.access_token);
            
            window.localStorage.setItem("userID", res.UserID);
            window.localStorage.setItem("comID", res.ComID);
            window.localStorage.setItem("userRole", res.Role);
            window.localStorage.setItem("userName", res.UserName);     
            window.localStorage.setItem("userInfo", res);
            console.log("登入者",res);       
            commit("SET_USERINFO", res);
            
            console.log("Menu",res.Menu);
            let mainMenu = res.Menu.filter((main) => main.Pid === 0);
            // console.log("mainMenu",mainMenu);
            const childrenMenu = res.Menu.filter((children) => children.Pid !== 0);
            // console.log("childrenMenu",childrenMenu);
            const menuList = mainMenu.map((p) => {
              p.children = childrenMenu.filter((c) => c.Pid == p.ID);
              return p;
            });

            // console.log(menuList);
            const strMenuList = JSON.stringify(menuList);
            // console.log(strMenuList);
            window.localStorage.setItem("menuList", strMenuList);

            const strMenuFlatter = JSON.stringify(res.Menu);
            window.localStorage.setItem("menuFlatter", strMenuFlatter);

            commit("SET_MENU", menuList);
            resolve("success");
          } 
          else {
            resolve(res);
          }
        })
        .catch((err) => {          
          reject(err.response);
        });
      });
    },    

    Logout({ commit }) {
      removeToken();
      commit("SET_TOKEN", "");
      
    },
    GetInfo({ commit }) {
      // console.log("GetInfo打api");
      return new Promise((resolve, reject) => {
        
        const req = { UserID: window.localStorage.getItem("userID"),Page_Size:"10", Page_Num:"1" };
        // console.log("post",req);
        resolve();
        API.apimgrs.getInfo(req)
          .then((res) => {     
            //console.log("res.data[0]",res.data[0]);       
            // commit("SET_USERINFO", res.data[0]);
            resolve();
          })
          .catch(() => {
            console.log("GetInfo Error!");
            reject();
          });
      });
    }, 



    ReloadMenu({ commit }) {
      // console.log(window.localStorage.getItem("menuList"));
      let mList = JSON.parse(window.localStorage.getItem("menuList"));
      if( mList) {
        // console.log("找出MenuList",mList);
        commit("SET_MENU", mList);
      }
      

      // reload時 , userInfo也不見了.. 所以要
      // this.$store.state.userInfo 


    },
    GetMenu({ commit }) {      
      API.apimgrs.getMenu().then((res) => {
        
        const { menu } = res;
        // console.log("menu",menu);
        let mainMenu = menu.filter((main) => main.Pid === 0);
        //console.log("mainMenu",mainMenu);
        const childrenMenu = menu.filter((children) => children.Pid !== 0);
        const menuList = mainMenu.map((p) => {
          p.children = childrenMenu.filter((c) => c.Pid == p.ID);
          return p;
        });
        // console.log(menuList);
        commit("SET_MENU", menuList);
      }).catch(err=>{
        console.log("err GetMemu",err);
      });
    },
    SaveHistory({ commit },routerFrom) {
      commit("SET_ROUTERFROM", routerFrom.name);
    }
  },
  
});

export const getters = {
  userInfo: state => state.userInfo,
}
