<template>
    <div class="projMgtBox">
        <Breadcrumb :title="$route.meta.title" />

        <div class="searchWrap">    
            <div class="itemWrap" style="width:30%">
                <label>專卷：</label>
                <el-select v-model="listQuery.folder" clearable placeholder="請選擇專卷名稱" no-data-text="目前無名單" @change="changeFolder">                                   
                    <el-option key="99" label="請選擇專卷" value="" ></el-option>    
                    <el-option v-for="(y,index) in folderList" :key="index" :label="y.FolderCategory" :value="y.FolderID" ></el-option>                                       
                </el-select>
                <!-- <el-dropdown split-button type="success" >
                    <font-awesome-icon icon="user-cog"  style="margin-left:5px;margin-right:5px" /><span style="margin-right:5px;"></span>
                    <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item @click.native="openFolderModal('add')" ><font-awesome-icon icon="plus" style="margin-right:10px;" />新增</el-dropdown-item>                
                        <el-dropdown-item @click.native="openFolderModal('edit')"><font-awesome-icon icon="edit" style="margin-right:10px;" />編輯</el-dropdown-item>                
                        <el-dropdown-item @click.native="openFolderModal('del')" ><font-awesome-icon icon="times" style="margin-right:10px;"/>刪除</el-dropdown-item>                
                    </el-dropdown-menu>
                </el-dropdown> -->
            </div>
            <div class="itemWrap" style="width:30%">
                <label>主題：</label>
                <el-select v-model="listQuery.topic" clearable placeholder="請選擇主題名稱" no-data-text="目前無名單" :disabled="listQuery.folder===''">              
                    <el-option key="99" label="全部" value="" ></el-option>    
                    <el-option v-for="(y,index) in topicList" :key="index" :label="y.TopicName" :value="y.TopicID" ></el-option>                                       
                </el-select>
                <!-- <el-dropdown split-button type="success" >
                    <font-awesome-icon icon="user-cog"  style="margin-left:5px;margin-right:5px" /><span style="margin-right:5px;"></span>
                    <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item @click.native="openTopicModal('add')" ><font-awesome-icon icon="plus" style="margin-right:10px;" />新增</el-dropdown-item>                
                        <el-dropdown-item @click.native="openTopicModal('edit')"><font-awesome-icon icon="edit" style="margin-right:10px;" />編輯</el-dropdown-item>                
                        <el-dropdown-item @click.native="openTopicModal('del')" ><font-awesome-icon icon="times" style="margin-right:10px;"/>刪除</el-dropdown-item>                
                    </el-dropdown-menu>
                </el-dropdown>                 -->
            </div> 
            <div class="itemWrap" style="width:10%;margin-left: 10px;">
                <el-button type="primary" @click="searchTopicNews">查詢</el-button>
            </div>
            <div class="itemWrapRight" style="width:30%">
                <el-dropdown split-button type="success" >
                    <font-awesome-icon icon="user-cog"  style="margin-left:15px;margin-right:10px" /><span style="margin-right:15px;">操作</span>
                    <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item  @click.native="deleteTopicNews()" ><font-awesome-icon icon="times" style="margin-right:10px;" />刪除新聞</el-dropdown-item>                

                        <el-dropdown-item divided @click.native="openFolderModal('add')" ><font-awesome-icon icon="plus" style="margin-right:10px;" />新增專卷</el-dropdown-item>                
                        <el-dropdown-item @click.native="openFolderModal('edit')"><font-awesome-icon icon="edit" style="margin-right:10px;" />編輯專卷</el-dropdown-item>                
                        <el-dropdown-item @click.native="openFolderModal('del')" ><font-awesome-icon icon="times" style="margin-right:10px;"/>刪除專卷</el-dropdown-item>                

                        <el-dropdown-item divided @click.native="openTopicModal('add')" ><font-awesome-icon icon="plus" style="margin-right:10px;" />新增主題</el-dropdown-item>                
                        <el-dropdown-item @click.native="openTopicModal('edit')"><font-awesome-icon icon="edit" style="margin-right:10px;" />編輯主題</el-dropdown-item>                
                        <el-dropdown-item @click.native="openTopicModal('del')" ><font-awesome-icon icon="times" style="margin-right:10px;"/>刪除主題</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>                                          
        </div>    
        
        <div class="projMgtTable">
            <div class="TableCont">
                <el-table style="width: 100%" ref="multipleTable" :data="tableData"  :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}"  empty-text="暫無數據"  >            
                    <el-table-column type="selection" width="50"></el-table-column>
                    <el-table-column label="新聞標題" min-width="50%">
                        <template slot-scope="scope">
                            <a class="goDetailPage" @click="goDetailPage(scope.row)">{{ scope.row.NewsTitle }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="NewsSite"  label="新聞網站" min-width="25%" >
                    </el-table-column>            
                    <el-table-column prop="NewsChannel"  label="新聞頻道" min-width="15%" >
                    </el-table-column>           
                    <el-table-column  label="新聞日期"  min-width="10%">
                        <template slot-scope="scope">
                        <div>{{ scope.row.NewsTime | moment("YYYY-MM-DD") }}</div>
                        </template>            
                    </el-table-column>
                </el-table>                
            </div>            
            <Pagination @currentChange="pageChange" :pageSize="listQuery.Page_Size" :propsCurrentPage="listQuery.Page_Num" :totalLists="totalLists"> </Pagination>        
        </div>

        <!-- modal -->
        <!-- 專卷分類 -->
        <el-dialog :title="folderModalTitle + '專卷'" :visible.sync="folderModal" width="50%" center>
        <el-form :model="folderModel" :rules="rules_folderModal" ref="ruleForm_folderModal" label-width="150px">
            <el-form-item label="專卷名稱" prop="name">
            <el-input v-model="folderModel.name"></el-input>
            </el-form-item>
            <el-form-item label="備註">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4}" v-model="folderModel.memo"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addFolder" v-if="folderModalTitle == '新增'"><font-awesome-icon icon="plus"  style="margin-right:5px;" />儲存</el-button>
            <el-button type="primary" @click="editFolder" v-else><font-awesome-icon icon="edit"  style="margin-right:5px;" />修改</el-button>
            <el-button type="danger" @click="folderModal = false"><font-awesome-icon icon="times"  style="margin-right:5px;" />取消</el-button>
        </span>
        </el-dialog>

        <!-- 專卷主題 -->
        <el-dialog :title="topicModalTitle + '專卷主題'" :visible.sync="topicModal" width="50%" center>
        <el-form :model="topicModel" :rules="rules_topicModal" ref="ruleForm_topicModal" label-width="150px">
            <!-- <el-form-item label="專卷分類">
            <strong class="themeModal">{{projSort}}</strong>
            </el-form-item> -->
            <el-form-item label="專卷主題" prop="theme">
            <el-input v-model="topicModel.theme"></el-input>
            </el-form-item>
            <el-form-item label="備註" prop="remark">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4}" v-model="topicModel.remark"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addTopic" v-if="topicModalTitle == '新增'">儲存</el-button>
            <el-button type="primary" @click="editTopic" v-else>修改</el-button>
            <el-button type="danger" @click="topicModal = false">取消</el-button>
        </span>
        </el-dialog>        
    </div>
    </template>
    

    <script>
    import Pagination from '../components/Pagination.vue';
    import Breadcrumb from '../../src/components/Breadcrumb.vue';
    export default {
        components: {Breadcrumb,Pagination  },
        data() {
            return {
                totalLists: 0,
                tableData: [],
                query:"",
                totalLists: 0,
                listQuery: {
                    folder:"",
                    topic:"",
                    Page_Size:10,
                    Page_Num:1,
                },
                folderList: [],
                topicList: [],  
                folderModal: false,
                folderModalTitle: "",   
                folderModel: {
                    id : "",
                    name: "",
                    memo: "",
                },
                rules_folderModal: {
                    name: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
                },     
                topicModal: false,
                topicModalTitle: "",
                topicModel: {
                    id: "",
                    folder: "",
                    theme: "",
                    remark: "",
                },
                rules_topicModal: {
                    theme: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
                    // remark: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
                },    
                
                manageAllSelection: [],
            
            };
        },
        methods : {


            // 專卷分類
            openFolderModal(val) {
                //console.log(val);
                let selectedItem = this.folderList.find(x=>x.FolderID==this.listQuery.folder);
                console.log(selectedItem);
                switch (val) {
                    case "edit":
                        if (!!this.listQuery.folder) {
                            this.folderModalTitle = "編輯";
                            this.folderModal = true;
                            this.folderModel.id = this.listQuery.folder;
                            this.folderModel.name = selectedItem.FolderCategory;
                            this.folderModel.memo = selectedItem.Memo;
                        } else {
                            this.$notify({
                            title: "警告",
                            message: "請先選擇欲修改之專卷分類！",
                            type: "warning",
                            });
                        }
                        break;
                    case "add":
                        this.folderModalTitle = "新增";
                        this.folderModal = true;
                        this.folderModel.name = "";
                        this.folderModel.memo = "";
                        break;
                    case "del":
                        if (!!this.listQuery.folder) {
                            let sItem = this.folderList.find(x=>x.FolderID==this.listQuery.folder);
                            this.$confirm(
                            "確定要刪除專卷分類：『" + sItem.FolderCategory + "』嗎？",
                            "提示",
                            {
                                confirmButtonText: "確定",
                                cancelButtonText: "取消",
                                type: "warning",
                            }
                            )
                            .then(() => {
                                this.delFolder(selectedItem.FolderID);
                                this.folderList = this.folderList.filter(x=>x.FolderID != this.listQuery.folder);
                                this.listQuery.folder = "";
                            })
                            .catch(() => {});
                        } else {
                            this.$notify({
                            title: "警告",
                            message: "請先選擇欲刪除之專卷分類！",
                            type: "warning",
                            });
                        }
                        break;
                } 
                
            },

            async delFolder(folderid) {
                this.$store.dispatch("loadingHandler", true);
                const delList = {
                    FolderID: folderid
                };
                // console.log(delList);
                await this.$api.folder.deleteFolder(delList).then((res) => {
                    if (res.data) {
                        this.$notify({
                            title: "成功",
                            message: "已成功刪除！",
                            type: "success",
                        });
                        this.listQuery.folder = "";
                        this.getFolderList();
                    }
                });
                this.$store.dispatch("loadingHandler", false);
            },    

            /* 新增-專卷分類 */
            addFolder() {
                this.$refs.ruleForm_folderModal.validate((valid) => {
                    if (valid) {
                        this.$store.dispatch("loadingHandler", true);
                        //console.log(this.$store.state.userInfo);
                        const addList = {                            
                            FolderCategory: this.folderModel.name,
                            Memo: this.folderModel.memo,                
                        };
                        this.$api.folder.addFolder(addList).then((res) => {                    
                            this.listQuery.folder = "";                    
                            this.$notify({
                                title: "成功",
                                message: "新增成功！",
                                type: "success",
                            });
                            this.getFolderList();
                            this.folderModal = false;
                        });
                        this.$store.dispatch("loadingHandler", false);
                    }
                });
            },
            /* 編輯-專卷分類 */
            editFolder() {
                this.$refs.ruleForm_folderModal.validate((valid) => {
                    // console.log("valid",valid);
                    if (valid) {
                        this.$store.dispatch("loadingHandler", true);
                        const editList = {
                            FolderID : this.folderModel.id,
                            FolderCategory: this.folderModel.name,
                            Memo: this.folderModel.memo
                        };
                        console.log(editList);
                        this.$api.folder.updateFolder(editList).then((res) => {
                            if (res.data) {
                                this.$notify({
                                    title: "成功",
                                    message: "修改成功！",
                                    type: "success",
                                });
                                this.folderModal = false;
                                this.listQuery.folder = "";
                                this.getFolderList();
                            };
                            this.getFolderList();
                        });
                    }
                });
                this.folderModal = false;
                this.$store.dispatch("loadingHandler", false);
            },   

            // 專卷主題
            openTopicModal(val) {
                let selectedItem = this.topicList.find(x=>x.TopicID==this.listQuery.topic);

                switch (val) {
                    case "edit":
                        if (!!this.listQuery.topic) {
                            this.topicModalTitle = "編輯";
                            this.topicModal = true;
                            this.topicModel.id = this.listQuery.topic;
                            this.topicModel.theme = selectedItem.TopicName;
                            this.topicModel.remark = selectedItem.Memo;
                        } else {
                            this.$notify({
                            title: "警告",
                            message: "請先選擇欲修改之專卷主題！",
                            type: "warning",
                            });
                        }
                        break;
                    case "add":                        
                        this.topicModel = {};
                        this.topicModalTitle = "新增";
                        this.topicModal = true;                        
                        break;
                    case "del":
                        //let sItem = this.sortList.find(x=>x.ReelTypeID==this.projSort);
                        if (!!this.listQuery.topic) {
                            this.$confirm(
                            "確定要刪除專卷主題：『" + selectedItem.TopicName + "』嗎？",
                            "提示",
                            {
                                confirmButtonText: "確定",
                                cancelButtonText: "取消",
                                type: "warning",
                            }
                            )
                            .then(() => {
                                // console.log(selectedItem);
                                // console.log(this.projTheme);
                                this.delTopic(selectedItem.TopicID);
                            })
                            .catch(() => {});
                        } else {
                            this.$notify({
                            title: "警告",
                            message: "請先選擇欲刪除之專卷主題！",
                            type: "warning",
                            });
                        }
                        this.themeList = this.themeList.filter(x=>x.ReelTopicID!=this.projTheme);
                        this.projTheme = "";
                    break;
                }

            },            
            
            /* 新增-專卷主題 */
            addTopic() {
                this.$refs.ruleForm_topicModal.validate((valid) => {
                    if (valid) {
                        this.$store.dispatch("loadingHandler", true);
                        const addList = {     
                            FolderID : this.listQuery.folder,                                       
                            TopicName: this.topicModel.theme,
                            Memo: this.topicModel.remark
                        };                        
                        this.$api.folder.addTopic(addList).then((res) => {                                         
                            this.listQuery.topic = "";                    
                            this.$notify({
                                title: "成功",
                                message: "新增成功！",
                                type: "success",
                            });
                            this.getTopicList();
                            this.topicModal = false;                    
                        });
                        this.$store.dispatch("loadingHandler", false);
                    }
                });
            },
            /* 編輯-專卷主題 */
            editTopic() {
                this.$refs.ruleForm_topicModal.validate((valid) => {
                    if (valid) {
                        this.$store.dispatch("loadingHandler", true);
                        const editList = {
                            TopicID: this.listQuery.topic,
                            TopicName: this.topicModel.theme,
                            Memo: this.topicModel.remark,
                        };                        
                        this.$api.folder.updateTopic(editList).then((res) => { 
                            this.listQuery.topic = "";
                            this.getTopicList();                            
                            this.$notify({
                                title: "成功",
                                message: "修改成功！",
                                type: "success",
                            });
                            this.topicModal = false;                            
                            this.$store.dispatch("loadingHandler", false);
                        });
                    }
                });
            },   
            
            async delTopic(topicId) {
                this.$store.dispatch("loadingHandler", true);
                const delList = {
                    TopicID: topicId
                };                
                await this.$api.folder.deleteTopic(delList).then((res) => {                    
                    this.$notify({
                        title: "成功",
                        message: "已成功刪除！",
                        type: "success",
                    });
                    this.listQuery.topic = "";
                    this.getTopicList();                
                });
                this.$store.dispatch("loadingHandler", false);
            },            

            /* 前往新聞詳細頁 */
            goDetailPage(data) {
                let routeUrl = this.$router.resolve({
                    path: "/detailNews",
                    query: { id: data.NewsID },
                });
                window.open(routeUrl.href, "_blank");
            },               
    
            async searchTopicNews() {
                if(this.listQuery.folder == "") {
                    this.$notify({
                            title: "請選擇專卷",
                            message: "請選擇專卷",
                            type: "warning",
                        });
                    return;
                }
                this.getList(false);
            },
    
            async pageChange(val) {
                this.listQuery.Page_Num = val;
                await this.getList();
            }, 
    
            async changeFolder() {
                if ( this.listQuery.folder == "" ) {
                    this.$notify({
                            title: "請選擇專卷",
                            message: "請選擇專卷",
                            type: "warning",
                        });
                    return;
                }
                await this.getTopicList(true);      
            },
    
            async getTopicList(isSys)
                {
                this.$store.dispatch("loadingHandler", true);
                this.topicList = [];
    
                let req = {Page_Size: 100, Page_Num: 1, FolderID: this.listQuery.folder };     
                if ( !isSys ) 
                    req.isSys = false;
                else 
                    req.isSys = true;
    
                await this.$api.folder.searchTopic(req).then((res)=>{  
                    this.topicList = res.data;
                    this.totalLists = res.total;
                });        
    
                this.$store.dispatch("loadingHandler", false);
            },
    
            async getFolderList(isSys) {
                this.folderList = [];
    
                let req = {Page_Size: 100, Page_Num: 1 };     
                if ( !isSys ) 
                    req.isSys = false;
                else 
                    req.isSys = true;
    
                await this.$api.folder.searchFolder(req).then((res)=>{
                    this.folderList = res.data;
                    this.totalLists = res.total;                          
                });
            },    

            async getList(isSys) {
                this.$store.dispatch("loadingHandler", true);
                this.tableData1 = [];

                let req = {Page_Size: this.listQuery.Page_Size, Page_Num: this.listQuery.Page_Num, FolderID: this.listQuery.folder };     
                if ( this.listQuery.topic != "" ) 
                    req.TopicID = this.listQuery.topic;               

                if ( !isSys ) 
                    req.isSys = false;
                else 
                    req.isSys = true;

                await this.$api.folder.searchTopicNews(req).then((res)=>{                
                    // console.log(res);        
                    this.tableData = res.data;
                    this.totalLists = res.total;
                    // console.log(this.tableData1);    
                    
                });      


                this.$store.dispatch("loadingHandler", false);
            },   
            
            makeTableShowChecked(){
                this.$nextTick(()=>{
                    this.tableData.forEach((item) => {
                    this.manageAllSelection.forEach((selectedItem) => {
                            if (item.DataID === selectedItem.DataID) {
                            this.$refs.multipleTable.toggleRowSelection(item, true);
                            }
                        });
                    });
                })
            },  

            /*手動勾選數據Checkbox所觸發的event*/
            getSelectedRow(selection,row){
                let manageAllDataID = this.manageAllSelection.map((item)=>item.DataID);
                let isIncludes = manageAllDataID.includes(row.DataID);
                if(isIncludes){
                    // delete
                    let index = manageAllDataID.indexOf(row.DataID)
                    this.manageAllSelection.splice(index,1)
                }else{
                    //push
                    this.manageAllSelection.push(row)
                }
                // console.log("checked", this.manageAllSelection);
            },            
            
        },
    
        async mounted() {        
            this.$store.dispatch("loadingHandler", true);    
            this.getFolderList(true);
            this.$store.dispatch("loadingHandler", false);
        },    
    }
    </script>
    
    
    <style lang="scss" >
    // * {
    //   outline: red solid 1px;
    // }
    
    .projMgtBox  {
    //   height:100vh;
      background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%) center center / 100% 100%;
      display: flex;
      flex-direction: column;
      
    //   padding: 0px 5%;
    
    
    
      .projMgtTable {
        display: flex;
        flex-direction: column;
        justify-content: center;    
        margin-top: 20px;
        padding: 0px 5%;
    
    
      }
    
      .searchWrap {
        display: flex;
        flex-direction: row;    
        justify-content: flex-start;        
        width: 90%;
        padding: 0px 5%;
    
        
          .itemWrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            margin-top:10px;
    
            label {
              width: 50px;
              white-space: nowrap;
              margin-right: 10px;
              text-align: right;
            }
            .el-button-group {
                margin-left: 15px;
            }
          }

          .itemWrapRight {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
            margin-top:10px;
          }     
    
        
    
      }
    
      .goDetailPage {
        color: #0645ad;
        text-decoration: underline;
        cursor: pointer;
    }
    
    }
    </style>