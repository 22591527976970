<template>
    <div class="RelComNewsBox">
        <Breadcrumb :title="$route.meta.title" />
        <div class="tabsBox">
            <el-tabs tab-position="top" type="border-card" style="background:transparent;height:670px;width:100%" stretch >
                <el-tab-pane >
                    <span slot="label" ><i class="el-icon-date" style="margin-right:20px;"></i>關聯公司</span> 
                    <div style="max-height: 600px; overflow-y: auto;">
                        <el-table ref="multipleTable" border :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF'}"  style="width: 100%"  empty-text="暫無數據" :data="relComInfo">
                        <el-table-column label="關聯公司"  prop="LegPersonRep" min-width="30%">
                        </el-table-column>                
           
                        <el-table-column label="關聯關係"  prop="Rels" min-width="25%">
                            <template slot-scope="scope">
                                <div v-html="scope.row.Rels"></div>
                            </template>                  
                        </el-table-column>                
                        <el-table-column label="關聯人"  prop="Reps" min-width="25%">
                            <template slot-scope="scope">
                                <div v-html="scope.row.Reps"></div>
                            </template>               
                        </el-table-column>
                        <el-table-column label="異動時間" prop="DataDt" min-width="10%" > 
                            <template slot-scope="scope">                                
                                <div v-if="!! scope.row.DataDt">
                                    <a class="goDetailPage" @click="doShowChange(scope.row)">{{ scope.row.DataDt}}</a>                                    
                                </div>                                
                            </template>  
                        </el-table-column>                                                            
                        </el-table>
                    </div>                        
                </el-tab-pane>
                <el-tab-pane  >
                    <span slot="label" ><i class="el-icon-reading" style="margin-right:20px;"></i>關聯人</span>
                    <div style="max-height: 600px; overflow-y: auto;">
                        <el-table ref="multipleTable" border :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF'}" style="width: 100%"  empty-text="暫無數據" :data="relComRep">
                            <el-table-column label="關聯人"  prop="Name" min-width="25%">
                            </el-table-column>
                            <el-table-column label="關聯關係"  prop="JobTitle" min-width="25%">
                            </el-table-column>
                            <el-table-column label="所代表法人"  prop="LegPersonRep" min-width="15%">
                            </el-table-column>    
                            <el-table-column label="持股情形" prop="HoldStkNm" min-width="35%" >         
                            </el-table-column>
                            <el-table-column label="關聯公司" prop="RelComs" min-width="35%" >    
                                <template slot-scope="scope">
                                        <div v-html="scope.row.RelComs"></div>
                                    </template>                              
                            </el-table-column>                                  
                        </el-table>
                    </div>
                    

                </el-tab-pane>                 
            </el-tabs>
        </div>   
        <div class="buttonBox">
            <el-button type="danger"  @click="closeWin()" ><font-awesome-icon icon="times"  style="margin-right:5px;" />關閉</el-button>
        </div>   


        <el-dialog title="異動項目" :visible.sync="showModel" width="60%" center>
          <div style="display:flex; flex-direction:column; align-items:center;">
                <el-table ref="multipleTable" :data="changeItemList"  :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}" style="width: 100%;max-height:300px;overflow-y:auto;"  empty-text="暫無數據">
                <el-table-column label="變更項目" prop="Title" min-width="30%"> </el-table-column>
                <el-table-column label="變更內容" prop="value" min-width="70%"> </el-table-column>                                      
                </el-table>       
          </div>
          <span slot="footer" class="dialog-footer">            
            <el-button type="danger" @click="showModel = false"><font-awesome-icon icon="times" style="margin-right:5px;" />關閉</el-button>
          </span>
        </el-dialog>               
    </div>
</template>
<script>

import Breadcrumb from "../components/Breadcrumb.vue";
export default {
    components: {Breadcrumb},
    data() {
        return {
            // dateRange: ['2022-01-01','2022-12-31'],
            ComId : "",
            relComRep: [],
            relComInfo: [],
            showModel: false,
            changeItemList: [],
            listQuery: {UniComNm : this.$route.query.ComId},
        }
    },
    methods: {
        async doShowChange(data) {
            this.$store.dispatch("loadingHandler", true);
            const qryCom = { UniComNm: data.UniComNm, DataDt: data.strdate };
            console.log(qryCom);
            await this.$api.apimgrs.getComChange(qryCom).then((res)=>{
                    console.log(res.Before, res.After);
                    this.getChangeItems(res.Before, res.After);
                
            }, err=> {
                this.$notify.error({
                title: "錯誤",
                message: "網路發生錯誤",
                type: "Error"})
            });
            this.$store.dispatch("loadingHandler", false);
            this.showModel=true;

        },

        // 變更
        async getComChange(strdate) {

            
            let qryCom = { UniComNm: this.$route.query.ComId, DataDt: strdate };
                
            console.log(qryCom);
            this.$api.apimgrs.getComChange(qryCom).then((res)=>{


                    console.log(res.Before, res.After);
                    this.getChangeItems(res.Before, res.After);

                //console.log(this.changeItemList);
            }, err=> {
                this.$notify.error({
                title: "錯誤",
                message: "網路發生錯誤",
                type: "Error"})
            });
        },    
        getChangeItems(before, after) {
            this.changeItemList = [];
            
            if(before.PUCap!=after.PUCap){
                this.changeItemList.push( {Title: "實收資本額(元)", value : this.commaFormat( before.PUCap) + " -> " + this.commaFormat(after.PUCap)});
            }
            if(before.ComStatus!=after.ComStatus){
                this.changeItemList.push( {Title: "公司狀況", value : before.ComStatus + " -> " + after.ComStatus});
            }     
            if(before.BizItem!=after.BizItem){
                // this.changeItemList.push( {Title: "所營事業資料", value : before.BizItem + " -> " + after.BizItem});
                this.compareBizItems(before.BizItem,after.BizItem);
            }  
            if(before.ComName!=after.ComName){
                this.changeItemList.push( {Title: "公司名稱", value : before.ComName + " -> " + after.ComName});
            }
            if(before.RepName!=after.RepName){
                this.changeItemList.push( {Title: "代表人姓名", value : before.RepName + " -> " + after.RepName});
            }
            if(before.ComLoc!=after.ComLoc){
                this.changeItemList.push( {Title: "公司所在地", value : before.ComLoc + " -> " + after.ComLoc});
            }
            if(before.TotCap!=after.TotCap){
                this.changeItemList.push( {Title: "資本總額(元)", value : this.commaFormat(before.TotCap) + " -> " + this.commaFormat(after.TotCap)});
            }
            if(JSON.stringify(before.Manager)!=JSON.stringify(after.Manager)){
                // this.changeItemList.push( {Title: "經理人資料", value : before.Manager + " -> " + after.Manager});
                this.compareManger(before.Manager,after.Manager);
            }       
            if(JSON.stringify(before.Supervisor)!=JSON.stringify(after.Supervisor)){
                // this.changeItemList.push( {Title: "董監事資料", value : JSON.stringify(before.Supervisor) + " -> " + JSON.stringify(after.Supervisor)});
                this.compareSupervisor(before.Supervisor,after.Supervisor);
            }    
            //console.log(this.changeItemList);   
        },    

        compareManger(before, after) {

            let bName = before.map(x=>x.Name);
            let aName = after.map(y=>y.Name);
            let union = bName.concat(aName);
            //console.log(union);
            let adds = union.filter( x=> ! bName.includes(x));
            let subs = union.filter( x=> ! aName.includes(x));
            //console.log(adds, subs);
            adds.forEach(x=> {
                this.changeItemList.push( {Title: "新增經理人", value : x});
            })
            subs.forEach(x=> {
                this.changeItemList.push( {Title: "刪除經理人", value : x});
            })
        },

        compareSupervisor(before, after) {
            let bName = before.map(x=>x.Name);
            let aName = after.map(y=>y.Name);
            let union = bName.concat(aName);
            //console.log(union);
            let adds = union.filter( x=> ! bName.includes(x));
            let subs = union.filter( x=> ! aName.includes(x));
            //console.log(adds, subs);
            adds.forEach(x=> {
                this.changeItemList.push( {Title: "新增董監事", value : x});
            })
            subs.forEach(x=> {
                this.changeItemList.push( {Title: "刪除董監事", value : x});
            })  
            
            // 比對內容.. 名字相同
            const interName = bName.filter(value => aName.includes(value));
            //console.log(interName);
            interName.forEach(z=>{
                let bObj = before.find(y=>y.Name==z);
                let aObj = after.find(y=>y.Name==z);
                //console.log(bObj,aObj);
                if( bObj.LegPersonRep!=aObj.LegPersonRep ) 
                {
                    const bstr = bObj.LegPersonRep ? bObj.LegPersonRep : "無";
                    const astr = aObj.LegPersonRep ? aObj.LegPersonRep : "無";
                    if(!( bstr=="無" && astr=="無"))
                        this.changeItemList.push( {Title: "董監事 "+ z + " 所代表法人變更", value : bstr  + " -> " + astr   });
                }
                if( bObj.HoldStkNm!=aObj.HoldStkNm )
                    this.changeItemList.push( {Title: "董監事 "+ z + " 持有股份數(股)變更", value : bObj.HoldStkNm? bObj.HoldStkNm : "" + " -> " + aObj.HoldStkNm});  
                if( bObj.JobTitle!=aObj.JobTitle )
                {
                    const bstr = bObj.JobTitle ? bObj.JobTitle : "無";
                    const astr = aObj.JobTitle ? aObj.JobTitle : "無"; 
                    if(!( bstr=="無" && astr=="無"))              
                        this.changeItemList.push( {Title: "董監事 "+ z + " 職稱變更", value : bstr  + " -> " + astr});  
                }
                                
            });
        },
        
        compareBizItems(beforeStr, afterStr) {


            let bName = [];
            let aName = [];
            const regPattern=/(?<item>[A-Z]{1,2}\d{5,6}[\u4e00-\u9fa5\u3002\uff1b\uff0c\uff1a\u201c\u201d\uff08\uff09\u3001\uff1f\u300a\u300b]+)/mg; 

            let match = regPattern.exec(beforeStr);
            do {
                bName.push(match.groups.item);
            } while((match = regPattern.exec(beforeStr)) !== null);

            let match1 = regPattern.exec(afterStr);
            do {
                aName.push(match1.groups.item);
            } while((match1 = regPattern.exec(afterStr)) !== null);


            let union = bName.concat(aName);
            //console.log(union);
            let adds = union.filter( x=> ! bName.includes(x));
            let subs = union.filter( x=> ! aName.includes(x));
            //console.log(adds, subs);
            adds.forEach(x=> {
                this.changeItemList.push( {Title: "新增營業項目", value : x});
            })
            subs.forEach(x=> {
                this.changeItemList.push( {Title: "刪除營業項目", value : x});
            })
        },
        commaFormat: function(value) {
            if( value===null) 
                return "";
            else  {
                // 加上千分位符號
                return value
                    .toString()
                    .replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, function(all, pre, groupOf3Digital) {
                    return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
                    });
            }  


        },        

        
        async getList() {
            // console.log(this.listQuery);
        await this.$api.apimgrs.relCompanyInfo(this.listQuery).then((res)=>{
            //console.log(res);
            console.log(res.CompanyData, res.LegPersonRepData);  


            
            this.relComInfo = [];
            let temp = [];

            res.LegPersonRepData.forEach(x=>{
                let rep = x.RelationPeople.map(y=>y.Name).join('<br/>');
                let jt = x.RelationPeople.map(y=>y.JobTitle).join('<br/>');                
                this.relComInfo.push( { LegPersonRep: x.LegPersonRep, Rels: jt, Reps: rep, DataDt: x.DataDt, UniComNm: x.UniComNm });
            });
                
            console.log(this.relComInfo);

            this.relComRep = [];
            res.CompanyData.forEach(x => {
                let relcoms = x.RelationCompany.map(y=>y).join('<br/>');                
                this.relComRep.push( { Name: x.Name, JobTitle: x.JobTitle , LegPersonRep: x.LegPersonRep, HoldStkNm: x.HoldStkNm, RelComs: relcoms });
            });
            
            }, err=> {
                this.$notify.error({
                title: "錯誤",
                message: "網路發生錯誤",
                type: "Error"})
            });            

        },

        closeWin() {
            window.close();
        },
    },
    async mounted() {
        this.$store.dispatch("loadingHandler", true);
        // console.log(this.$route.query);
        this.ComId = this.$route.query.ComId;

        // await this.getRegularCustomer();
        // await this.getCity();  
        await this.getList();
        this.$store.dispatch("loadingHandler", false);
    },
}

</script>

<style lang="scss" scoped>

// * {
//     outline: red solid 1px;
// }


.RelComNewsBox {
  height:150vh;
  background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0%;

    .tabsBox {
        padding: 0px 5%;
    }
    .buttonBox {
        display: flex;
        flex-direction: row;
        margin: 0px 0px;
        padding: 20px 20px;
        justify-content: center;
        align-items: center;
    }
}

  .goDetailPage {
      color: #0645ad;
      text-decoration: underline;
      cursor: pointer;
   } 
// linear-gradient(to right top, #65dfc9, #6cdbeb);
</style>
