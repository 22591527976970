<template>
  <div class="iSearchBox">
      <Breadcrumb :title="$route.meta.title" />  
      <div class="searchWrap">    
        <div class="itemWrap" style="width:100%">
        <label>請選擇發查網站：</label>
          <el-select v-model="listQuery.webid" clearable placeholder="請選擇發查網站" no-data-text="目前無名單" @change="changeWeb" style="min-width: 300px;" >                                   
              <!-- <el-option key="99" label="請選擇發查網站" value="" ></el-option>     -->
              <el-option v-for="(y,index) in webList" :key="index" :label="y.website" :value="y.webid" ></el-option>                                       
          </el-select>
        </div>
      </div>  
      <div class="showBox" v-if="showBox[0]" >
        <label>公司名稱 : </label><el-input v-model="form1.name"></el-input>
        <label>查詢日期 : </label>
        <el-date-picker type="daterange" :editable="true" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="開始日期" 
            end-placeholder="結束日期"
            v-model="form1.rangeDate"
          ></el-date-picker>
        <el-button type="primary" @click="search1">查詢</el-button>        
      </div>
      <div class="showBox" v-if="showBox[1]" >
        <label>統一編號 : </label><el-input v-model="form2.brand_id"></el-input>
        <el-button type="primary" @click="search2">查詢</el-button>        
      </div>      
      <div class="showBox" v-if="showBox[2]" >
        <label>統一編號 : </label><el-input v-model="form3.brand_id"></el-input>
        <el-button type="primary" @click="search3">查詢</el-button>        
      </div>         
         
      <div class="showBox" v-if="showBox[3]" >
        <label>自然人姓名/事業負責人名稱 : </label><el-input v-model="form4.iname"></el-input>
        <label>自然人身份證字號 : </label><el-input v-model="form4.iid"></el-input>
        <el-button type="primary" @click="search4">查詢</el-button>        
      </div> 
      <div class="showBox" v-if="showBox[4]" >
        <label>自然人姓名/事業負責人名稱 : </label><el-input v-model="form5.iname"></el-input>        
        <el-button type="primary" @click="search5">查詢</el-button>        
      </div>       
      <div class="showBox" v-if="showBox[5]" >
        <label>自然人姓名/完整公司名稱 : </label><el-input v-model="form6.company_name"></el-input>        
        <el-button type="primary" @click="search6">查詢</el-button>        
      </div>   
      <div class="showBox" v-if="showBox[6]" >
        <label>身份證字號 : </label><el-input v-model="form7.iid"></el-input>        
        <label>身分證發證年月日 : </label><el-input v-model="form7.icard_date"></el-input>  
        <label>身分證發證地點 : </label><el-input v-model="form7.icard_place"></el-input>
        <label>身分證發證原因 : </label><el-input v-model="form7.icard_reason"></el-input>      
        <el-button type="primary" @click="search7">查詢</el-button>        
      </div>                 
      <div class="showBox" v-if="showBox[7]" >
        <label>公司股票代號 : </label><el-input v-model="form8.company_stock_id"></el-input>        
        <el-button type="primary" @click="search8">查詢</el-button>        
      </div>  
      <div class="showBox" v-if="showBox[8]" >
        <label>公司股票代號 : </label><el-input v-model="form9.company_stock_id"></el-input>        
        <el-button type="primary" @click="search9">查詢</el-button>        
      </div>        
      <div class="showBox" v-if="showBox[9]" >
        <label>公司股票代號 : </label><el-input v-model="form10.company_stock_id"></el-input>        
        <el-button type="primary" @click="search10">查詢</el-button>        
      </div>      
      <div class="showBox" v-if="showBox[10]" >
        <label>公司股票代號 : </label><el-input v-model="form11.company_stock_id"></el-input>        
        <el-button type="primary" @click="search11">查詢</el-button>        
      </div> 
      <div class="showBox" v-if="showBox[11]" >
        <label>統一編號 : </label><el-input v-model="form12.brand_id"></el-input>        
        <el-button type="primary" @click="search12">查詢</el-button>        
      </div>           
      <div class="showBox" v-if="showBox[12]" >
        <label>統一編號 : </label><el-input v-model="form13.brand_id"></el-input>        
        <el-button type="primary" @click="search13">查詢</el-button>        
      </div>           
      <div class="showBox" v-if="showBox[13]" >
        <label>統一編號 : </label><el-input v-model="form14.brand_id"></el-input>        
        <el-button type="primary" @click="search14">查詢</el-button>        
      </div>  

      <div class="resultBox" v-show="showResult">
        <div class="itemWrap" style="width:100%">
          <label>查詢完成 : </label><label class="wrapStyle">{{ result.IsComplete }}</label>
        </div>
        <div class="itemWrap" style="width:100%">
          <label>查詢訊息 : </label><label class="wrapStyle">{{ result.output }}</label>
        </div>
        <div class="itemWrap" style="width:100%">
          <label>附件 : </label><el-button type="danger" @click="downloadFile(result.attachment)">下載</el-button>
        </div>
      </div>
  </div>

</template>

<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from "../components/Breadcrumb.vue";
import moment from "moment";

export default {
  name : 'immediateSearch',
  components: {Breadcrumb,Pagination},
  data() {
      return {
          totalLists: 0,
          tableData: [],
          showBox: [false,false,false,false,false,false,false,false,false,false,false,false,false,false],
          form1: {
            name: "",
            rangeDate: []
          },
          form2: {
            brand_id: ""
          },
          form3: {
            brand_id: ""
          },    
          form4: {
            iname: "",
            iid: ""
          },     
          form5: {
            iname: ""            
          },         
          form6: {
            company_name: ""            
          },    
          form7: {            
            iid: "",
            icard_date: "",
            icard_place: "",
            icard_reason: "",
          },
          form8: {
            company_stock_id: ""            
          },
          form9: {
            company_stock_id: ""            
          },      
          form10: {
            company_stock_id: ""            
          },    
          form11: {
            company_stock_id: ""            
          },       
          form12:{
            brand_id: ""
          },
          form13:{
            brand_id: ""
          },
          form14:{
            brand_id: ""
          },
          query:"",
          totalLists: 0,
          listQuery: {
              webid:"",              
              Page_Size:10,
              Page_Num:1,
          },
          result: {
            IsComplete: false,
            output: "",
            attachment:""
          },
          showResult: false,
          webList: [
            {webid:"1",website:"行政院環保署列管汙染源資料"},
            {webid:"2",website:"財政部稅務入口網公示資料"},
            {webid:"3",website:"經濟部商業司(公司)資料"},
            {webid:"4",website:"司法院家事事件公告查詢"},
            {webid:"5",website:"司法院法學資料檢索系統"},
            {webid:"6",website:"勞動部違反勞動法法令事業單位查詢系統"},
            {webid:"7",website:"身份證領補換查詢"},
            {webid:"8",website:"公開資訊觀測站-公司基本資料"},
            {webid:"9",website:"公開資訊觀測站-背書保證與資金貸放餘額資訊"},
            {webid:"10",website:"公開資訊觀測站-財務重點專區"},
            {webid:"11",website:"公開資訊觀測站-董監事持股餘額明細資料"},
            {webid:"12",website:"國際貿易局出進口廠商登記資料"},
            {webid:"13",website:"經濟部商業司(工廠)資料"},
            {webid:"14",website:"經濟部商業司(商業)資料"}        
          ],
          topicList: [],        
          selectData: [],
      };
  },
  computed: {
  },
  watch: {
  },
  methods: {    
    downloadFile(pdf){
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = "file.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    
    changeWeb() {
      console.log(this.listQuery.webid);
      for(let i=1;i<=this.showBox.length;i++) {
        if(i==this.listQuery.webid) {
          this.showBox[i-1] = true;
        } else {
          this.showBox[i-1] = false;
        }
      }   
      this.showResult = false;   

    },
    search1() {
      // console.log(this.form1);
      let qry = {
        "company_name": this.form1.name,
        "start_date": this.form1.rangeDate[0],
        "end_date": this.form1.rangeDate[1]
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA01(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form1.name = "";
        this.form1.rangeDate = [];
        this.$store.dispatch("loadingHandler", false);   
      });   
      
    },
    search2() {
      let qry = {
        "brand_id": this.form2.brand_id
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA02(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form2.brand_id = "";
        this.$store.dispatch("loadingHandler", false); 
      });   
    },
    search3() {
      let qry = {
        "brand_id": this.form3.brand_id
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA03(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form3.brand_id = "";
        this.$store.dispatch("loadingHandler", false); 
      });   
    },
    search4() {
      let qry = {
        "iname": this.form4.iname,
        "iid": this.form4.iid
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA04(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form4.iname = "";
        this.form4.iid = "";
        this.$store.dispatch("loadingHandler", false); 
      });  
    },        
    search5() {
      let qry = {
        "iname": this.form5.iname        
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA05(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form5.iname = "";        
        this.$store.dispatch("loadingHandler", false); 
      });  
    },      
    search6() {
      let qry = {
        "company_name": this.form6.company_name
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA06(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form6.company_name = "";        
        this.$store.dispatch("loadingHandler", false); 
      });  
    },      
    search7() {

      let errMessage="";
      if(this.form7.icard_date=="") {
        errMessage+="請輸入身分證發證年月日 ";
      }
      if(this.form7.icard_place=="") {
        errMessage+="請輸入身分證發證地點 ";
      }
      if(this.form7.icard_reason=="") {
        errMessage+="請輸入身分證發證原因 ";
      }
      if(this.form7.iid=="") {
        errMessage+= "請輸入身分證字號 ";
      }

      if(errMessage!="") {
        // this.$message({
        //   message: errMessage,
        //   type: 'warning'
        // });
        this.$notify({
            title: "通知",
            message: errMessage,
            type: "warning",
          });        
        return;
      }

      let qry = {
        "iid": this.form7.iid,
        "icard_date": this.form7.icard_date,
        "icard_place": this.form7.icard_place,
        "icard_reason": this.form7.icard_reason
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA07(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form7.iid = "";
        this.form7.icard_date = "";
        this.form7.icard_place = "";
        this.form7.icard_reason = "";
        this.$store.dispatch("loadingHandler", false); 
      });  
    },      
    search8() {
      let qry = {
        "company_stock_id":this.form8.company_stock_id
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA08(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form8.company_stock_id = "";
        this.$store.dispatch("loadingHandler", false); 
      });  
    },    
    search9() {
      let qry = {
        "company_stock_id":this.form9.company_stock_id
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA09(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form9.company_stock_id = "";
        this.$store.dispatch("loadingHandler", false); 
      });  
    },           
    search10() {
      let qry = {
        "company_stock_id":this.form10.company_stock_id
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA10(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form10.company_stock_id = "";
        this.$store.dispatch("loadingHandler", false); 
      });  
    },     
    search11() {
      let qry = {
        "company_stock_id":this.form11.company_stock_id
      }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA11(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form11.company_stock_id = "";
        this.$store.dispatch("loadingHandler", false); 
      });  
    },    
    search12() {
      let qry = {
          "brand_id": this.form12.brand_id
        }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA12(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form12.brand_id="";
        this.$store.dispatch("loadingHandler", false); 
      });  
    },        
    search13() {
      let qry = {
          "brand_id": this.form13.brand_id
        }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA13(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form13.brand_id="";
        this.$store.dispatch("loadingHandler", false); 
      });  
    },            
    search14() {
      let qry = {
          "brand_id": this.form14.brand_id
        }
      // console.log(qry);
      this.$store.dispatch("loadingHandler", true);
      this.$api.immsearch.searchA14(qry).then((res)=>{
        // console.log(res);
        this.result.IsComplete = res.IsComplete=="T"?"完成":"未完成";
        this.result.output = JSON.stringify(res.output);
        this.result.attachment = res.attachment;
        this.showResult = true;
        this.form14.brand_id="";
        this.$store.dispatch("loadingHandler", false); 
      });  
    },         
  },
  async mounted() {
  }
}
</script>

<style lang="scss" scoped>
// * {
//   outline: 1px solid red;
// }
.iSearchBox {
  height:150vh;
  background-image: linear-gradient(to bottom,#FFF 55% ,#fff 80% , transparent 50% );
  background-size: cover;
  height: 100vh;

  .searchWrap {
    display: flex;
    flex-direction: row;    
    justify-content: flex-start;
    width: 90%;
    padding: 0px 5%;

    
      .itemWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-top:10px;

        label {
          width: 150px;
          white-space: nowrap;
          margin-right: 10px;
          text-align: right;
        }
      }     
  }

  .showBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    padding: 0px 5%;
    margin-top: 20px;

    label {
      width: 150px;
      white-space: nowrap;
      margin-right: 10px;
      text-align: right;
    }
    .el-input {
      width: 200px;
      margin-right: 10px;
    }
  }

  .resultBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    padding: 0px 5%;
    margin-top: 20px;

    label {
      width: 150px;
      white-space: nowrap;
      margin-right: 10px;
      text-align: right;
    }
    .el-input {
      width: 200px;
      margin-right: 10px;
    }

    .itemWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-top:10px;

        label {
          width: 150px;
          white-space: nowrap;
          margin-right: 10px;
          text-align: right;
        }

        .wrapStyle {
          width: 80%;
          color: #0000FF;
          font-weight: bold;          
          margin-right: 10px;
          text-align: left;
          white-space:  normal;
        }
      }      
  }

}

</style>