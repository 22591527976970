import request from "../request";
import sysrequest from "../sysrequest";
import axios from 'axios';

const api = {
  /*專卷資料*/
  addFolder(data) {
    if( data.isSys) {
      return sysrequest({
        url: "/bid/demo/folder/add_folder_info/",
        method: "post",
        data,
        });      
    }
    else {
      return request({
        url: "/bid/demo/folder/add_folder_info/",
        method: "post",
        data,
        });
    }
    
  }, 
  updateFolder(data) {
    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/folder/update_folder_info/",
        method: "post",
        data,
        });
    else  
      return request({
        url: "/bid/demo/folder/update_folder_info/",
        method: "post",
        data,
        });
  }, 

  deleteFolder(data) {
    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/folder/delete_folder_info/",
        method: "post",
        data,
        });
    else  
      return request({
        url: "/bid/demo/folder/delete_folder_info/",
        method: "post",
        data,
        });
  },   

  searchFolder(data) {
    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/folder/search_folder/",
        method: "post",
        data,
        });
    else  
      return request({
        url: "/bid/demo/folder/search_folder/",
        method: "post",
        data,
        });   
  }, 
  
  /* 專卷主題*/
  addTopic(data) {
    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/folder/add_folder_topic_info/",
        method: "post",
        data,
        });
    else  
      return request({
        url: "/bid/demo/folder/add_folder_topic_info/",
        method: "post",
        data,
        });    
  }, 

  updateTopic(data) {
    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/folder/update_folder_topic_info/",
        method: "post",
        data,
        });
    else  
      return request({
        url: "/bid/demo/folder/update_folder_topic_info/",
        method: "post",
        data,
        });
  }, 

  deleteTopic(data) {
    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/folder/delete_folder_topic_info/",
        method: "post",
        data,
        });
    else  
      return request({
        url: "/bid/demo/folder/delete_folder_topic_info/",
        method: "post",
        data,
        });
  }, 


  searchTopic(data) {    
    if( data.isSys)
      return sysrequest({
        url: "/bid/demo/folder/search_folder_topic/",
        method: "post",
        data,
        });
    else  
      return request({
        url: "/bid/demo/folder/search_folder_topic/",
        method: "post",
        data,
        });
  }, 
  
    /* 專卷主題新聞*/
    addTopicNews(data) {
      if( data.isSys)
        return sysrequest({
            url: "/bid/demo/folder/add_topic_news_info/",
            method: "post",
            data,
            });
      else
        return request({
            url: "/bid/demo/folder/add_topic_news_info/",
            method: "post",
            data,
            });
    }, 

    updateTopicNews(data) {
      if( data.isSys)
        return sysrequest({
            url: "/bid/demo/folder/update_topic_news_info/",
            method: "post",
            data,
            });
      else
        return request({
            url: "/bid/demo/folder/update_topic_news_info/",
            method: "post",
            data,
            });
      }, 

    deleteTopicNews(data) {
      if( data.isSys)
        return sysrequest({
            url: "/bid/demo/folder/delete_topic_news_info/",
            method: "post",
            data,
            });
      else
        return request({
            url: "/bid/demo/folder/delete_topic_news_info/",
            method: "post",
            data,
            });

    }, 

    searchTopicNews(data) {
      if( data.isSys)
        return sysrequest({
            url: "/bid/demo/folder/search_topic_news/",
            method: "post",
            data,
            });
      else
        return request({
                url: "/bid/demo/folder/search_topic_news/",
                method: "post",
                data,
                });
    }, 

    getTopology(data) {
      if( data.isSys)
        return sysrequest({
            url: "/bid/demo/folder/topic_news_topology/",
            method: "post",
            data,
            });
      else
        return request({
                url: "/bid/demo/folder/topic_news_topology/",
                method: "post",
                data,
                });
    },             
};



export default api;