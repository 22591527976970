<template>
  <div class="loginOperatePage">
    <Breadcrumb :title="$route.meta.title" />    
    <div class="loginOperatePage__listBox">
      <section class="filterWrap">
        <!-- 時間 -->        
        <div class="itemWrap">
          <label>查詢日期：</label>
          <el-date-picker :editable="true" type="daterange" value-format="yyyy-MM-dd" range-separator="至" 
            start-placeholder="開始日期" 
            end-placeholder="結束日期"
            v-model="rangeDate"
          ></el-date-picker>
        </div>
        <!-- 名單類型 -->
        <div class="itemWrap">
          <label>查詢單位：</label>
          <el-select v-model="listQuery.DeptID" clearable placeholder="請選擇單位" no-data-text="目前無名單">
            <el-option v-for="item in deptNameSelectData"
                :key="item.ID"
                :label="item.DeptName"
                :value="item.ID"
            ></el-option>
          </el-select>
        </div>
        <div class="itemWrap">
          <el-button @click="doSearch" type="primary" class="searchLog"><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>
        </div>
      </section>      
      <div class="header">
        <div class="exportWrap">
          <download-csv :data="CSVData" name="系統操作記錄表.csv">
            <el-button type="primary"><font-awesome-icon icon="download" style="margin-right:10px;" />匯出</el-button>
          </download-csv>
        </div>
      </div>
      <div class="content">
        <el-table ref="multipleTable" border  :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}" style="width: 100%"  empty-text="暫無數據" :data="tableData">
          <el-table-column label="單位"  prop="DeptName" width="100">
            <template slot-scope="scope">
              <div>{{ scope.row.DeptName?scope.row.DeptName:"無" }}</div>
            </template>
          </el-table-column>

          <el-table-column label="使用者" prop="UserName"  width="100">
            <template slot-scope="scope">
              <div>{{ scope.row.UserName?scope.row.UserName:"無" }}</div>
            </template>
          </el-table-column>

          <el-table-column label="操作時間" prop="CreateDt" width="180">
            <template slot-scope="scope">
              <div>{{ filterDate(scope.row.CreateDt) }}</div>
            </template>
          </el-table-column>

          <el-table-column label="操作功能" prop="EndPoint" width="150"></el-table-column>
          <el-table-column label="操作內容" prop="UseCont"></el-table-column>
        </el-table>
      </div>
      <div class="pageBox">
        <Pagination
          @currentChange="pageChange"
          :pageSize="this.listQuery.Page_Size"
          :propsCurrentPage="this.listQuery.Page_Num"
          :totalLists="totalLists">
        </Pagination>
      </div>


    </div>

  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";

export default {
  components: { Breadcrumb ,Pagination},
  data() {
    return {
      rangeDate: [moment().add(-1, "days").format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
      tableData: [],
      CSVData: [],
      totalLists: 0,
      deptNameSelectData: [],
      listQuery: {
        DeptID: "",    
        Page_Size: 10,
        Page_Num: 1
      },
    };
  },
  computed: {
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      };
    },
  },
  methods: {
    async doSearch(){
      this.$store.dispatch("loadingHandler", true);
      this.listQuery.Page_Num = 1;
      await this.getList(false);
      await this.getAllExportData();
      this.$store.dispatch("loadingHandler", false);
    },
    async pageChange(val) {
      this.$store.dispatch("loadingHandler", true);
      this.listQuery.Page_Num = val;
      await this.getList();
      this.$store.dispatch("loadingHandler", false);
    },

    deleteNullValue() {
      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }else{
        this.listQuery.Start_Date = "";
        this.listQuery.End_Date = "";
        delete this.listQuery.Start_Date;
        delete this.listQuery.End_Date;
      }
      // let keys = Object.keys(this.listQuery)
      // keys.forEach((key)=>{
      //   if(Array.isArray(this.listQuery[key]) && this.listQuery[key].length===0){
      //     delete this.listQuery[key]
      //   }
      //   if(!this.listQuery[key]){
      //     delete this.listQuery[key]
      //   }
      // })
    },

        // 查詢單位

    async getDeptment() {
      // let req={ "Page_Size": 20, "Page_Num": 1, "isSys": true};      

        await this.$api.apimgrs.getDeptName().then((res)=>{
          
          this.deptNameSelectData = res;
          
          //this.totalLists = res.total;
        });

    },    

    async getList(isSys) {
      
      this.deleteNullValue();
      this.$store.dispatch("loadingHandler", true);
      if( ! isSys)
        this.listQuery.isSys = false;
      else
        this.listQuery.isSys = true;

      if( !this.listQuery.DeptID)
        delete this.listQuery.DeptID;
      
      console.log("sysoperation",this.listQuery);
      
      await this.$api.apimgrs.actionRecord(this.listQuery).then((res) => {
        
        this.tableData = res.data;
        this.totalLists = res.total;        
      });
      this.$store.dispatch("loadingHandler", false);
    },

    getSelectOption() {
      this.$api.company
        .getUserList({UniComNm: this.$store.state.userInfo.UniComNm})
        .then((res) => {
          this.deptNameSelectData = res.department;
        });
    },
    getAllExportData(){
      let requestData = {};
      requestData = JSON.parse(JSON.stringify(this.listQuery));
      requestData.Page_Num = 1;
      requestData.Page_Size = 9999;
      requestData.isSys = true;

      if( this.listQuery.DeptID )
        requestData.DeptID = this.listQuery.DeptID;

      this.$api.apimgrs.actionRecord(requestData).then((res) => {
        let CSVData = [];
        res.data.forEach((item) => {
          const { DeptName, UserName, CreateDt, EndPoint, UseCont } = item;
          CSVData.push({ DeptName, UserName, CreateDt, EndPoint, UseCont });
        });
        this.CSVData = CSVData;
      });
      
    }
  },
  async mounted() {
    console.log(this.listQuery);
    this.$store.dispatch("loadingHandler", true);
    await this.getList(true);
    this.$store.dispatch("loadingHandler", false);
    this.getDeptment();
    this.getAllExportData()
  },
};
</script>

<style lang="scss" scoped>


.loginOperatePage {
  height:150vh;
  // background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  background-image: linear-gradient(to bottom,#FFF 55% ,#fff 80% , transparent 50% );
  background-size: cover;
  height: 100vh;
  display: flex;
  padding:  5px 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;   

  &__listBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    // outline: red solid 1px;

    .header {
      display: flex;
      width: 90%;
      justify-content: flex-end;
      margin: 0px 0px 10px 0px;
    } 

    .content, .pageBox {
      display: flex;
      width: 90%;
      flex-direction: row;
      justify-content: center;
      
    }
  }  

  .filterWrap{
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  .itemWrap{
      margin: 5px 10px;
      // width: 500px;
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      label{
          white-space:nowrap;
      }
      &:nth-child(3){
        width:15%;
      }
  }

  }
}
</style>
