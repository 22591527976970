<template>
    <div class="roleFuncBox">
      <Breadcrumb :title="$route.meta.title" />           
      <div class="roleFuncBox__listBox">
        <div class="roleFuncBox__listBox--query" >
          <label>角色名稱 : </label>
          <el-input v-model.trim="keyword" placeholder="請輸入角色名稱" clearable ></el-input>          
          <el-button type="primary" @click="btnSearch" ><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>
        </div>          
        <div class="roleFuncBox__listBox--add" >
          <el-button type="primary" @click="setAccountFunc('add')" v-if="showAddBtn"><font-awesome-icon icon="plus"  style="margin-right:5px;" />新增角色及功能權限</el-button>
        </div>      
        <div class="roleFuncBox__listBox--Content">
          <el-table :data="tableData" ref="multipleTable" style="width: 100%" :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}"  empty-text="暫無數據">            
            <el-table-column label="角色名稱" prop="Name" min-width="15%">
              <template slot-scope="scope">
                <a class="goDetailPage" @click="setAccountFunc('show', scope.row)">{{ scope.row.Name }}</a>
              </template>
            </el-table-column>            
            <el-table-column label="建立人" prop="CreateUserName" min-width="10%">        
            </el-table-column>
            <el-table-column label="建立時間" min-width="10%">
              <template slot-scope="scope">
                {{ scope.row.CreateDt | moment("YYYY-MM-DD HH:mm") }}
              </template>
            </el-table-column>
            <el-table-column label="最後修改人" prop="LastMAUserName" min-width="10%">
            </el-table-column>     
            <el-table-column label="最後修改時間" min-width="10%">
              <template slot-scope="scope">
                {{ scope.row.LastMADt | moment("YYYY-MM-DD HH:mm") }}
              </template>
            </el-table-column>                        
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <div class="roleFuncBox__listBox--Content--userFunc" >
                  <el-tooltip effect="dark" content="刪除角色" placement="left" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button type="text" @click="setAccountFunc('del', scope.row)" v-if="showDelBtn">
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </el-tooltip>     
                  <el-tooltip effect="dark" content="編輯角色" placement="right" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button type="text" @click="setAccountFunc('edit', scope.row)" v-if="showEditBtn">
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </el-tooltip>                                  

                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @currentChange="pageChange" :pageSize="Page_Size" :propsCurrentPage="Page_Num" :totalLists="totalLists"> </Pagination>          
        </div>
      </div>

    <!-- 新增或編輯帳號 -->
    <!-- <el-dialog :title="setAccountTitle" :visible.sync="setAccount" width="60%" @opened="loadFuncAtion()" center> -->
    <el-dialog :title="setAccountTitle" :visible.sync="setAccount" width="60%"  center>
      <el-form :model="userQuery" :rules="rules_setAccount" label-width="100px">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="角色名稱" prop="DeptName">
              <el-input v-model="userQuery.DeptName" :disabled="this.setAccountMode == 'show'" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" >
              <el-tree v-if="treeVisible" ref="userTree" :data="treedata" @node-click="handleNodeClick" :key="usertreekey" node-key="id" 
              :default-checked-keys="nodecheckedList"  show-checkbox  accordion empty-text="目前的功能" ></el-tree>
            </el-col>          
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="setAccountMode !=='show'"  type="primary" @click="addAction()" ><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button>
        <el-button type="danger" @click="closeDiaglog()"><font-awesome-icon icon="times" style="margin-right:5px;" />取消</el-button>
      </span>
    </el-dialog>
    
   
        
    </div>
    
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
import Pagination from "../components/Pagination.vue";
import moment from "moment";
import API from "../api/api";
export default {
  // name : 'account-management',
  components: {Breadcrumb,Pagination},
  data() {
  
    return {
      setAccount: false,
      setAccountTitle: "新增角色功能",         
      setAccountMode:"add",   
      tableData: [],
      totalLists: 0,
      Page_Num: 1,
      Page_Size: 10,   
      ActionList: [],   
      keyword: "",
      treeVisible: true,
      eltreeNodeDisabled: true,
      treedata: [],   

      parentIDs: [], // 紀錄所有的parentID, 用來判斷是否要載入子節點
      // 預設載入時要checked的節點.
      nodecheckedList:[],  

      // 使用者編輯某一個群組時, 會有值
      editRow: {},

      exceptNodeId: [],

      showAddBtn: true,
      showEditBtn: true,
      showDelBtn: true,

      usertreekey: 0,

      /* 新增帳號 */
      userQuery: {
        //UserID: this.$store.state.userInfo.UserID,
        //UniComNm: this.$store.state.userInfo.UniComNm,
        ID:"",
        DeptName: "",
        AccessAction: []
      },

      rules_setAccount: {
        DeptName: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        // Role: [{ required: true, message: "請選擇使用者角色", trigger: "change" }],
        // DeptName: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        // contractInfo: [
        //   { required: true, message: "此為必填欄位", trigger: "blur" },
        // ],
        // Account: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        // Account: [{  required: true, validator: checkAccount, trigger: "blur" }],
        // Password: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        // CheckPass: [{ required: true, validator: addValidatePass2, trigger: "blur" }],
        // memo: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
      },
    };
  },
  computed: {
    isDisable: function() {
      return this.setAccountMode == 'show';
    }
  },
  methods: {
    showButton(row) {
      // if( this.UserRole=="manager" && row.Role=="user" )
      //   return false;
      // else 
      //   return true;
      if( row.UserName==this.$store.state.userInfo.UserName)
        return true;
      else
        return false;
    },  
    
    handleNodeClick(node) {
      // node 即是CustDefs 的model 
      console.log("selnode",node.id, node.label);
      // let sNode = this.treedata.filter(x=> x.id==node.id);      
      // if( sNode.length==0) {
      //   this.$router.push("/projSearch?ProjId="+node.id);
      // }
      // else {
      //   this.$notify.error({
      //     title: "警告",
      //     message: "請選擇專卷主題",
      //     type: "warning",
      //   });
      // }
      

    },

    async btnSearch() {

      // if( this.keyword.length==0) {
      //   this.$notify.error({
      //       title: "通知",
      //       message: "請輸入群組名稱",
      //       type: "warning"}); 
        
      // }
      this.Page_Num = 1;
      await this.getList();
    },

    // 取得目前所有  功能Api的列表....
    async getActionList() {
      let req = {
        "Page_Size": 100,
        "Page_Num": 1,
        "isSys": true
      }

      this.exceptNodeId = [];
      //  getDeptment
      await this.$api.apimgrs.getActions(req).then((res)=>{
       
        this.ActionList = res.data;
        console.log(res);
        //let pList = res.data.filter( (p)=> p.pid == null );

        //console.log("res.data", res.data);
        let tempTree = [];
        this.parentIDs = [];
        // 20231012, Menu可能有一層或二層, Action可能綁在第一層或第二層中.  
        // Action可能有多個, 若Action的數量只有一個, 則把那個Action合併到Menu那一層(如首頁, 登出)
        // 

        res.data.forEach(x=>{
          // console.log('x--Name------',x.Name);
          //console.log('x, NextMenu------',x, x.NextMenu);


          if( x.NextMenu.length>0) // 若有子目錄
          {
            this.parentIDs.push(x.Action[0].ID);
            let nd2child = [];            
            x.NextMenu.forEach(y=>{    
              //console.log(y);

              let nd3child = [];
              y.Action.forEach(z=>{
                nd3child.push({id: z.ID, label: z.Action, disabled: this.eltreeNodeDisabled});
              });

              nd2child.push( { id: y.Name, label: y.Name, children: nd3child,disabled: this.eltreeNodeDisabled});              
            })
            tempTree.push( { id: x.Action[0].ID, label: x.Action[0].Action, children: nd2child, disabled: this.eltreeNodeDisabled });
          }
          else // 沒有子目錄
          {
            // 沒有子目錄, 也要找是否有Action..
            //console.log("Action",x.Action);
            

            if( x.Action.length>0) { // 一定會有Action ...
              let nd3child = [];
              if( x.Action.length > 1) {
                  x.Action.forEach(z=>{
                    nd3child.push({id:z.ID, label: z.Action, disabled: this.eltreeNodeDisabled });
                });
                tempTree.push( { id: x.Name, label: x.Name, children: nd3child, disabled: this.eltreeNodeDisabled});
              }
              else { // 只有一個Action, 合併到Menu中...
                tempTree.push( { id: x.Action[0].ID, label: x.Name, children: nd3child, disabled: this.eltreeNodeDisabled});
              }

              //
              
            }
            // else {
            //   tempTree.push( { id: x.Action[0].ID, label: x.Name, disabled: this.eltreeNodeDisabled});
            // }
            
          }
        });

        this.treedata = tempTree;

        
      });
      console.log("parentIDs", this.parentIDs);
      console.log("treedata ", this.treedata);

    },
    
  
    async pageChange(val) {
      this.$store.dispatch("loadingHandler", true);
      this.Page_Num = val;
      await this.getList();
      this.$store.dispatch("loadingHandler", false);
    },   

    formValidate(form) {
      let status = false;
      this.$refs[form].validate((valid) => {
        if (valid) {
          status = true;
        }
      });
      return status;
    },    


    // 新增或編輯群組
    async addAction() {

      if( this.userQuery.DeptName.length==0) {
        this.$notify.warning({
            title: "通知",
            message: "請輸入角色名稱",
            type: "warning"});   
        return;
      }

      

      let checkedNodeTemp=[
        // ...this.$refs.userTree.getHalfCheckedKeys(),
        ...this.$refs.userTree.getCheckedKeys()
      ]
      console.log("check key", this.$refs.userTree.getCheckedKeys());
      console.log("half key", this.$refs.userTree.getHalfCheckedKeys());

      // 要過灑不是 數字型的 id
      let checkedNode = checkedNodeTemp.filter(x=> ! isNaN(x));

      // 20230924, 要加上half check 
      if( this.$refs.userTree.getHalfCheckedKeys().length > 0) {
        this.$refs.userTree.getHalfCheckedKeys().forEach(x=>{
          console.log("half key x",x);
          if( !isNaN(x) )
            checkedNode.push(x);
        })
      }

      
      if (checkedNode.length == 0) {
        this.$notify.warning({
            title: "通知",
            message: "至少選擇一個功能",
            type: "warning"});        
        return;
      }
      
      this.$store.dispatch("loadingHandler", true);

      checkedNode.sort(function(a, b) {
        return a - b;
      });

      console.log("checkedNode",checkedNode);

      if( this.setAccountMode == "add") {
        console.log("新增userQuery");
        let req = {
            "Name": this.userQuery.DeptName,          
            "AccessAction": checkedNode
        }

        console.log(req);
        await this.$api.apimgrs.addRole(req).then((res)=>{
            console.log(res);
            this.$notify({
              title: "成功",
              message: "新增成功",
              type: "success",
            }); 

            this.getList();             
        }).catch(err=>{
            this.$notify.error({
            title: "錯誤",
            message: err,
            type: "Error"});
        });

      }
      if( this.setAccountMode == "edit") {        
        let req = {
            "ID": this.editRow.ID,          
            "Name": this.userQuery.DeptName,
            "AccessAction": checkedNode
        }

        // console.log(req);
        await this.$api.apimgrs.updateRole(req).then((res)=>{
            // console.log(res);
            this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success",
            });       
            this.getList();          
        }).catch(err=>{
            this.$notify.error({
            title: "錯誤",
            message: err,
            type: "Error"});
        });
      }
      

      this.setAccount = false;
      this.usertreekey = this.usertreekey + 1;
      
      this.$store.dispatch("loadingHandler", false);


    },    


    async deleteDept(roleid) {
      this.$store.dispatch("loadingHandler", true);

      await this.$api.apimgrs.deleteRole(roleid).then((res)=>{
        //console.log(res);
        this.$notify({
          title: "成功",
          message: "刪除成功",
          type: "success",
        });   
        
      });
      
      await this.getList();

      this.$store.dispatch("loadingHandler", false);
    }, 

    // 載入編輯某一群組的 AccessAction
    loadFuncAtion() {



      // console.log('this.editRow=',this.editRow);
      // console.log('except',this.exceptNodeId);
      let nodeTemp = [];
      let temp=[];
      if( !! this.editRow.AccessAction &&  this.editRow.AccessAction.length > 0)
      {
        this.editRow.AccessAction.split(',').forEach(x=>{
          if(! this.exceptNodeId.find(z=> z==x))
            temp.push(x);
        });
        nodeTemp = temp;
      }        
      else {
        nodeTemp = [];
      } 
      
      // this.nodecheckedList = nodeTemp;  
      // 20231013, 要排除掉parentIDs, 因為parentIDs若被設定了, 則子節點就被全部設定了., 所以要排除..
      // console.log("before node",nodeTemp);

      const localIDs = this.parentIDs;
      // console.log("localIDs",localIDs);

      this.nodecheckedList = nodeTemp.filter(x=> ! localIDs.includes(x));

      // console.log("after node",this.nodecheckedList);

      this.usertreekey = this.usertreekey + 1;

      // 強制更新Tree ..
      this.treeVisible = false;
      this.$nextTick(function () {
        this.treeVisible = true;
      });

      // console.log('nodecheckedList=',this.nodecheckedList);
    },

    closeDiaglog() {      
      this.setAccount = false
    },


    async setAccountFunc(val, data) {
      
      // console.log('op=', val)
      switch (val) {
        case "add":          
          this.setAccountMode = "add";
          this.setAccountTitle = "新增角色功能"; 
          this.userQuery.DeptName = "";          
          this.setAccount = true;          
          this.nodecheckedList = [];
          this.usertreekey = this.usertreekey + 1;
          
          break;
        case "edit":
         
          // console.log(data);
          this.setAccountTitle = "編輯角色功能";
          this.userQuery.DeptName = data.Name;
          this.editRow = data;
          this.setAccountMode ="edit";          
          this.setAccount = true;
          this.nodecheckedList = [];
          
          // console.log('op=',val,data);     
          //await this.test();
          this.loadFuncAtion();
          break;
        case "show":
         
         // console.log(data);
         this.setAccountTitle = "角色功能";
         this.userQuery.DeptName = data.Name;
         this.editRow = data;
         this.setAccountMode ="show";          
         this.setAccount = true;
         this.nodecheckedList = [];
         
         // console.log('op=',val,data);     
         //await this.test();
         this.loadFuncAtion();
         break;          
        case "del":
          
          this.$confirm("確定要刪除『" + data.DeptName + "』嗎？", "提示", {
            confirmButtonText: "確定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
              // console.log("data",data);
              this.deleteDept({"ID":data.ID});
              
            })
          .catch(() => {
              this.$notify({
                title: "失敗",
                message: "刪除失敗",
                type: "error",
              });  

          });          
          break;
      }
      
      
      
    },  






    async getList(isSys) {
      this.$store.dispatch("loadingHandler", true);
      this.tableData = [];
      let req = {
        "Page_Size": this.Page_Size,
        "Page_Num": this.Page_Num
      }

      if(! isSys)
        req.isSys = false;
      else
        req.isSys = true;
      
      if( this.keyword.length > 0 ) {
        req.Name = this.keyword;
      }
      //  getRole
      // console.log(req);
      await this.$api.apimgrs.getRoleList(req).then((res)=>{
      //  console.log(res);
        let adminCount = 0;
        res.data.forEach(x=>{
          if( x.Name!="admin") {
            this.tableData.push(x);
          } 
          else {
            adminCount = adminCount + 1;
          } 
        })
        // this.tableData = res.data;
        this.totalLists = res.total - adminCount;
      });

      // console.log(this.tableData);
      
      this.$store.dispatch("loadingHandler", false);
    },
    
  },


  async mounted() {
    this.$store.dispatch("loadingHandler", true);
    await this.getList(true);
    this.$store.dispatch("loadingHandler", false);

    // 載入權限表..
    let mList = JSON.parse(window.localStorage.getItem("menuFlatter"));    
    console.log("mList",mList);

    if( mList) {
      console.log(mList);
      // 找出ID=11 , 的roleFunCMange 那個...  (要從mList找出來, 因為mList是扁平化的..)
      // "角色及功能權限管理", bizWeb這個專案, 改了ID, 變成..15了...
      let thisAction = mList.find(x=> x.ID=='15').Action;
      if( thisAction)
      {
        console.log(thisAction);
        // 新增btn.. 
        if( thisAction.find(x=>x.ApiID=='10') ) {
          this.showAddBtn = true;
          this.eltreeNodeDisabled = false;
        }
          
        // 編輯btn
        if( thisAction.find(x=>x.ApiID=='11') )
        {
          this.showEditBtn = true;
          this.eltreeNodeDisabled = false;
        }
        // 編輯btn
        if( thisAction.find(x=>x.ApiID=='12') )
        {
          this.showDelBtn = true;        
          this.eltreeNodeDisabled = false;
        }
        
      }

    }
    await this.getActionList();
  },

};
</script>
<style lang="scss" scoped>
// * {
//   outline: red solid 1px;
// }

.goDetailPage {
      color: #0645ad;
      text-decoration: underline;
      cursor: pointer;
   } 
.roleFuncBox {
  height:150vh;
  // background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  background-image: linear-gradient(to bottom,#FFF 55% ,#fff 80% , transparent 50% );  
  background-size: cover;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;


  &__title {
    width: 100%;
    padding-top: 20px;
    text-align: center;

    strong {
      color: #191972;
      letter-spacing: 2px;
      font-size: 1.1rem;
    }
  }

  &__listBox {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--add {
      display: flex;
      flex-direction: row;
      width: 90%;
      justify-content: flex-end;
      margin-bottom: 20px;
    }

    &--query {
      display: flex;
      flex-direction: row;
      width: 90%;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;      

      label {
        white-space: nowrap;
        margin-right: 2rem;
      }

      .el-input {
        width: 30%;
        margin-right: 2rem;
      }
    }

    &--Content {
      width: 90%;

      &--add {
        width: 100%;
        //padding: 0 30px;
        box-sizing: border-box;
        text-align: right;

        span {
          transition: 0.6s;
          cursor: pointer;
          i,
          a {
            font-size: 18px;
            font-weight: bold;
            color: #191972;
          }
          i {
            padding-right: 4px;
          }

          &:hover {
            letter-spacing: 2px;
          }
        }
      }

      &--userFunc {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
</style>
