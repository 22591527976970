<template>
    <div class="apiBox">
        <Breadcrumb :title="$route.meta.title" />    
        <div class="apiBox__listBox">
        <section class="filterWrap"> 
            <div class="itemWrap">
              <label>查詢日期：</label>
              <el-date-picker :editable="true" type="daterange" value-format="yyyy-MM-dd" range-separator="至" 
                  start-placeholder="開始日期" 
                  end-placeholder="結束日期"
                  v-model="rangeDate"                  
              ></el-date-picker>
                
            </div>
        </section>
        <section class="filterWrap">
            <!-- 名單類型 -->
            <div class="itemWrap">
              <label>API分類：</label>
              <el-select v-model="catType" clearable placeholder="請選擇分類" no-data-text="目前無名單" style="width:200px;" @change="changeCat" @clear="clearAction">                                
                    <!-- <el-option v-for="item in apiCat" :key="item" :label="item" :value="item"></el-option> -->
                    <el-option v-for="(item,idx) in allCatApi" :key="idx" :label="item.Type" :value="item.Type" ></el-option>
                </el-select>
            </div>
            <div class="itemWrap">
              <label>API名稱：</label>
              <el-select v-model="selectedApi" clearable placeholder="請選擇名稱" no-data-text="目前無名單" style="width:250px;"  :disabled="enableSelectApi" >                                
                    <el-option v-for="item in allApis" 
                    :key="item.ID"
                    :label="item.Name"
                    :value="item.ID"
                    ></el-option>
                </el-select>
            </div>  
            <div class="itemWrap" style="margin-left:15px;">
              <label>關鍵字：</label>
              <el-input v-model="keyword" placeholder="關鍵字查詢" style="width: 200px" clearable></el-input>
            </div>             
                       
            <div class="itemWrap">
              <el-button @click="doSearch" type="primary" class="searchLog"><font-awesome-icon icon="search"   />查詢</el-button>
              <el-button @click="doAddApi" type="primary"  v-if="showAddBtn" ><font-awesome-icon icon="plus"  />新增API資訊</el-button>
            </div>
        </section>
        <!-- <div class="addButtonBox" >
            
        </div>     -->

       
        <div class="content">
            <el-table ref="multipleTable" border :cell-style="{padding: '3px'}" :header-cell-style="{ background: '#FFF6EF'}" style="width: 100%"  empty-text="暫無數據" :data="tableData">
              <el-table-column label="單位"  prop="RespUnit" width="150px;">
              </el-table-column>     
              <el-table-column label="API分類"  prop="DataType" min-width="10%" >
                </el-table-column>                        
              <el-table-column label="API名稱"  prop="Name" min-width="20%">
                <template slot-scope="scope">
                    <a class="goDetailPage" @click="setAccountFunc('show', scope.row)">{{ scope.row.Name }}</a>
                </template>                      
              </el-table-column>              
              <el-table-column label="API Url" prop="EndPoint" min-width="15%"  >  
              </el-table-column>
              <el-table-column label="上架時間"   width="100px;">  
                <template slot-scope="scope">
                  <div>{{ filterDate(scope.row.CreateDt) }}</div>
                </template>                
              </el-table-column>            
              <el-table-column label="最後修改時間" width="100px;">  
                <template slot-scope="scope">
                  <div>{{ filterDate(scope.row.LastMADt) }}</div>
                </template>                
              </el-table-column>     
              <el-table-column label="操作"   min-width="15%">  
                <template slot-scope="scope">
                <div class="roleFuncBox__listBox--Content--userFunc" >
                  <el-tooltip effect="dark" content="刪除Api" placement="left" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button v-if="showDelBtn" type="text" @click="setAccountFunc('del', scope.row)">
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </el-tooltip>     
                  <el-tooltip effect="dark" content="編輯Api" placement="right" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button v-if="showEditBtn" type="text" @click="setAccountFunc('edit', scope.row)">
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </el-tooltip>                                 

                </div>
              </template>                
              </el-table-column>                                     
            </el-table>
        </div>
        <div class="pageBox">
          <Pagination
            @currentChange="pageChange"
            :pageSize="this.listQuery.Page_Size"
            :propsCurrentPage="this.listQuery.Page_Num"
            :totalLists="totalLists">
          </Pagination>
        </div>        
        </div>

        <!-- // 新增帳號 -->
        <el-dialog :title="setAccountTitle" :visible.sync="addApiModel" width="80%" center>
          <div style="display:flex; flex-direction:column; align-items:center;">
            <el-form :model="apiModel"  :rules="rules_setAccount" ref="ruleForm_setAccount" label-width="120px">
              <el-row>
                <el-col :span="10">
                    <el-form-item label="API分類" prop="DataType">
                        <el-input v-model="apiModel.DataType"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="API名稱" prop="Name">
                        <el-input v-model="apiModel.Name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="是否可申請" >
                      <el-checkbox v-model="apiModel.IsApply"  disabled :key="ckidx" ></el-checkbox>                  
                    </el-form-item>   
                </el-col>                
                </el-row>
                <el-row type="flex">
                <el-col :span="12">
                    <el-form-item label="Url" prop="EndPoint">          
                        <el-input v-model="apiModel.EndPoint"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="提供單位" prop="RespUnit">
                    <el-input v-model="apiModel.RespUnit"></el-input>
                    </el-form-item>
                </el-col>
                </el-row>

                <el-row>
                <el-col :span="12">
                    <el-form-item label="聯絡人" prop="Contact">
                    <el-input v-model="apiModel.Contact"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="聯絡方式" prop="ContactInfo">
                    <el-input v-model="apiModel.ContactInfo"></el-input>
                    </el-form-item>
                </el-col>
                </el-row>               
                <el-form-item label="API描述" >
                  <!-- <el-input v-model="apiModel.Desc" ></el-input> -->
                  <el-input v-model="apiModel.Description" type="textarea" :autosize="{ minRows: 2, maxRows: 4}"></el-input>
                </el-form-item>
                <el-form-item label="備註" prop="Memo">
                  <el-input v-model="apiModel.Memo" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" ></el-input>
                </el-form-item>
            </el-form>      
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addApiBtn"><font-awesome-icon icon="plus" style="margin-right:5px;" />新增</el-button>
            <el-button type="danger" @click="addApiModel = false"><font-awesome-icon icon="times" style="margin-right:5px;" />關閉</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="setAccountTitle" :visible.sync="showApiModel" width="80%" center>
          <div style="display:flex; flex-direction:column; align-items:center;">
            <el-form :model="showModel"  :rules="rules_setAccount" label-width="120px">
                <el-row>
                <el-col :span="12">
                    <el-form-item label="API分類" prop="DataType">
                        <el-input v-model="showModel.DataType" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="API名稱" prop="Name">
                        <el-input v-model="showModel.Name" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>

                </el-row>
                <el-row type="flex">
                <el-col :span="12">
                    <el-form-item label="Url" prop="EndPoint">          
                        <el-input v-model="showModel.EndPoint" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="提供單位" prop="RespUnit">
                    <el-input v-model="showModel.RespUnit" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                </el-row>

                <el-row>
                <el-col :span="12">
                    <el-form-item label="聯絡人" prop="Contact">
                    <el-input v-model="showModel.Contact" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="聯絡方式" prop="ContactInfo">
                    <el-input v-model="showModel.ContactInfo" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                </el-row>                
                <el-row>
                <el-col :span="24">
                    <el-form-item label="API描述" >
                    <el-input v-model="showModel.Description" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                </el-row>                     
                <el-form-item label="備註" prop="Memo">
                  <el-input v-model="showModel.Memo" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" :disabled="this.setAccountMode == 'show'"></el-input>
                </el-form-item>
            </el-form>      
          </div>
          <span slot="footer" class="dialog-footer">    
            <el-button type="primary" @click="editApiBtn" v-if="setAccountMode=='edit'"><font-awesome-icon icon="save" style="margin-right:5px;" />儲存</el-button>
            <el-button type="danger" @click="showApiModel = false"><font-awesome-icon icon="times" style="margin-right:5px;" />關閉</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="setAccountTitle" :visible.sync="editApiModel" width="80%" center>
          <div style="display:flex; flex-direction:column; align-items:center;">
            <el-form :model="editModel"  :rules="rules_setAccount" label-width="120px">
                <el-row>
                <el-col :span="12">
                    <el-form-item label="API分類" prop="DataType">
                        <el-input v-model="editModel.DataType" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="API名稱" prop="Name">
                        <el-input v-model="editModel.Name" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>

                </el-row>
                <el-row type="flex">
                <el-col :span="12">
                    <el-form-item label="Url" prop="EndPoint">          
                        <el-input v-model="editModel.EndPoint" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="提供單位" prop="RespUnit">
                    <el-input v-model="editModel.RespUnit" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                </el-row>

                <el-row>
                <el-col :span="12">
                    <el-form-item label="聯絡人" prop="Contact">
                    <el-input v-model="editModel.Contact" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="聯絡方式" prop="ContactInfo">
                    <el-input v-model="editModel.ContactInfo" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                </el-row>                
                <el-row>
                <el-col :span="24">
                    <el-form-item label="API描述" >
                    <el-input v-model="editModel.Description" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" :disabled="this.setAccountMode == 'show'"></el-input>
                    </el-form-item>
                </el-col>
                </el-row>                     
                <el-form-item label="備註" prop="Memo">
                  <el-input v-model="editModel.Memo" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" :disabled="this.setAccountMode == 'show'"></el-input>
                </el-form-item>
            </el-form>      
          </div>
          <span slot="footer" class="dialog-footer">    
            <el-button type="primary" @click="editApiBtn" v-if="setAccountMode=='edit'"><font-awesome-icon icon="save" style="margin-right:5px;" />儲存</el-button>
            <el-button type="danger" @click="editApiModel = false"><font-awesome-icon icon="times" style="margin-right:5px;" />關閉</el-button>
          </span>
        </el-dialog>

    </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";

export default {

  components: { Breadcrumb ,Pagination},
  data() {
    return {
      tableData: [],
      tableUsage: [],
      totalLists: 0,
      allApis: [],
      enableSelectApi: true,
      deptList: [],
      totalLists: 0,
      totalUsageLists: 0,
      addApiModel: false,
      showApiModel: false,
      editApiModel: false,
      rangeDate: [],
      keyword:"",
      setAccountTitle:"",
      catType:"",
      selectedApi: "",
      apiCat: [],
      ckidx:0,
      setAccountMode:"",
      allCatApi:[],

      showAddBtn: false,
      showEditBtn: false,
      showDelBtn: false,      

      apiModel: {
        Name:"",
        Memo:"",
        RespUnit:"",
        Contact:"",
        IsApply: "",
        EndPoint:"",
        ContactInfo:"",
        DataType:"",
        CreateDt:"",
        Description:"",
        ap:"",
        ID:""     
      },
      editModel: {
        Name:"",
        Memo:"",
        RespUnit:"",
        Contact:"",
        IsApply: "",
        EndPoint:"",
        ContactInfo:"",
        DataType:"",
        CreateDt:"",
        Description:"",
        ap:"",
        ID:""     
      },      

      showModel: {
        Name:"",
        Memo:"",
        RespUnit:"",
        Contact:"",
        IsApply: "",
        EndPoint:"",
        ContactInfo:"",
        DataType:"",
        CreateDt:"",
        Description:"",
        ap:"",
        ID:""     
      }, 

      listQuery: {
        // DeptName: "all",        
        // ApiID: [],
        // selApi: "",
        Page_Size:10,
        Page_Num:1
      },
      rules_setAccount: {
        DataType: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        Name: [{ required: true, message: "此為必填欄位", trigger: "blur" }]
      },
    };
  },
  computed: {
    filterDate() {
      return (date) => {
        if( !date) 
          return "";
        else 
          return this.$dayjs(date).format("YYYY-MM-DD");
      };
    },
 
  },

  methods: {

   

    async clearAction() {
      this.selectedApi = "";
      this.enableSelectApi = true;
    },

    async changeCat(value) {
      console.log(value);
      this.allCatApi.forEach(x=>{
        if( x.Type==value) {
          this.allApis = x.ApiList;
          this.selectedApi = "";
        }
      });
      this.enableSelectApi = false;
      // this.allApis = this.allCatApi[value].ApiList;
      
    },
    doAddApi() {
      this.setAccountTitle = "新增API資訊";
      
      this.apiModel.Name = "";
      this.apiModel.EndPoint = "";
      this.apiModel.ID = "";
      this.apiModel.DataType = "";
      this.apiModel.IsApply = "F";
      this.apiModel.Memo = "";
      this.apiModel.Contact = "";
      this.apiModel.ContactInfo = "";
      this.apiModel.RespUnit = "";
      this.apiModel.Description = "";
      this.$nextTick(()=>{
        this.ckidx += 1;
        this.addApiModel = true;
      })
      
      // console.log(this.apiModel);
      
    },


    async setAccountFunc(val, data) {
      this.$store.dispatch("loadingHandler", true);
      // console.log('op=', val,data)
      switch (val) {
        case "show":
          
          this.showModel.Name = data.Name;
          this.showModel.EndPoint = data.EndPoint;
          this.showModel.ID = data.ID;
          this.showModel.DataType = data.DataType;
          this.showModel.IsApply = data.IsApply == 'T' ? true : false;
          this.showModel.Memo = data.Memo;
          this.showModel.Description = data.Description;
          this.showModel.Contact = data.Contact;
          this.showModel.ContactInfo = data.ContactInfo;
          this.showModel.RespUnit = data.RespUnit;
          this.setAccountMode ="show";
          this.setAccountTitle = "API資訊";
          // console.log("showModel",this.showModel);
          this.showApiModel = true;          
          break;
        case "edit":
          // console.log(data);
          this.editModel.Name = data.Name;
          this.editModel.EndPoint = data.EndPoint;
          this.editModel.ID = data.ID;
          this.editModel.DataType = data.DataType;
          this.editModel.IsApply = data.IsApply == 'T' ? true : false;
          this.editModel.Memo = data.Memo;
          this.editModel.Description = data.Description;
          this.editModel.Contact = data.Contact;
          this.editModel.ContactInfo = data.ContactInfo;
          this.editModel.RespUnit = data.RespUnit;          
          this.setAccountMode ="edit";
          this.setAccountTitle = "編輯API資訊";
          // console.log("editModel",this.editModel);
          this.editApiModel = true;
          break;
        case "del":
          // console.log(data);
          this.$confirm("確定要刪除『" + data.Name + "』嗎？", "提示", {
            confirmButtonText: "確定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
              // console.log("data",data);
              
              let reqDel = {ID: data.ID};
              this.$api.apimgrs.deleteApi(reqDel).then(res=>{
                console.log("刪除成功");
                this.$notify({
                  title: "通知",
                  message: "刪除成功",
                  type: "success",
                });                  
                this.getList(true);    
                this.getApiCat();// 取得api 分類               
              }).catch(err=>{
                
                console.log("刪除失敗");
                  this.$notify({
                    title: "失敗",
                    message: "刪除失敗",
                    type: "error",
                  });  
              });
              
            })
          .catch(() => {
              // this.$notify({
              //   title: "失敗",
              //   message: "刪除失敗",
              //   type: "error",
              // });  

          });          
          break;
      }
      
      this.$store.dispatch("loadingHandler", false);
      
    },      

    async doSearch(){
      this.listQuery.Page_Num = 1;
      await this.getList();
      // this.getApiCat();// 取得api 分類    
    },

    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.getList();
    },   
    
    async getApiCat() {
      
      await this.$api.apimgrs.getApiCat().then( res=>{     
        // console.log(res); 
        this.allCatApi = res.ApiType;
        // console.log(this.allCatApi);
        this.apiCat = res.ApiType.map(x=>x.Type);
      });      
    },   

    formValidate(form) {
      let status = false;
      this.$refs[form].validate((valid) => {
        if (valid) {
          status = true;
        }
      });
      
      return status;

    },      
    
    async addApiBtn() {

      // if( ! this.formValidate("ruleForm_setAccount"))
      // {
        
      //   return;
      // }
      
      // this.$refs.ruleForm_setAccount.validate((valid)=>{
      //   if( valid )
      //     console.log("valid",valid);
      //     return valid;
      // });

      if( this.apiModel.DataType.length==0 || this.apiModel.Name.length==0 ) {        
          this.$notify.warning({
            title: "通知",
            message: "請輸入API分類 / API 名稱",
            type: "Warning"});
          this.$store.dispatch("loadingHandler", false);
          return;         
      }



      // 用this.apiModel 來新增
      // console.log("apiModel", this.apiModel);
      this.$store.dispatch("loadingHandler", true);

      this.apiModel.IsApply = "F";      
      await this.$api.apimgrs.addApi(this.apiModel).then((res)=>{
            this.$notify({
              title: "成功",
              message: "新增成功",
              type: "success",
            });       

            this.addApiModel = false;
          }
        );
      await this.getList();    
      await this.getApiCat();// 取得api 分類     
      this.$store.dispatch("loadingHandler", false);
    },

    async editApiBtn() {
      // console.log(this.apiModel);
      this.$store.dispatch("loadingHandler", true);
      if( this.apiModel.IsApply)
        this.apiModel.IsApply = "T";
      else
        this.apiModel.IsApply = "F";
      await this.$api.apimgrs.updateApi(this.apiModel).then(res=>{
        
        this.$notify({
              title: "成功",
              message: "更新成功",
              type: "success",
        });         
        
      }).catch(err=>{
        this.$notify({
              title: "錯誤",
              message: "更新發生錯誤",
              type: "error",
        });  
      });     

      this.showApiModel = false;
      await this.getList();
      await this.getApiCat();// 取得api 分類    
      this.$store.dispatch("loadingHandler", false);
    },

    async getList() {

      this.$store.dispatch("loadingHandler", true);
      let req = {"Page_Size": this.listQuery.Page_Size, "Page_Num": this.listQuery.Page_Num};
      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          req.Start_Date = this.rangeDate[0]
          req.End_Date = this.rangeDate[1]
        }
      }

      if( this.keyword.length > 0)
        req.Name=this.keyword;

      if( this.selectedApi.length > 0)
        req.ID = this.selectedApi;

      // console.log("catType",this.catType);
      if( this.catType.length > 0)
        req.DataType = this.catType;        

      this.tableData = [];

      // console.log(req);

      {
        let ret = [];
        // console.log(req);
        await this.$api.apimgrs.getApi(req).then((res)=>{
            // this.allApis = res.data;  
            this.tableData = res.data;
            this.totalLists = res.total;
            // console.log(res.data);
          }
        );


      }
      this.$store.dispatch("loadingHandler", false);
      
    }, 
  },

  async mounted() {
    
    this.getList();  

    // 載入權限表..
    let mList = JSON.parse(window.localStorage.getItem("menuFlatter"));    
    // console.log(mList);
    if( mList) {
      // console.log(mList);
      // console.log("showAdd",this.showAddBtn);
      // console.log("showEdit",this.showEditBtn);
      // console.log("showDel",this.showDelBtn);
      // 找出ID=11 , 的roleFunCMange 那個...
      let thisAction = mList.find(x=> x.ID=='16').Action;
      if( thisAction)
      {
        // console.log(thisAction);
        // 新增btn.. 
        if( thisAction.find(x=>x.ApiID=='27') )
          this.showAddBtn = true;
        // 編輯btn
        if( thisAction.find(x=>x.ApiID=='28') )
          this.showEditBtn = true;
        // 編輯btn
        if( thisAction.find(x=>x.ApiID=='29') )
          this.showDelBtn = true;        
        
      }

    }    
    
    this.getApiCat();// 取得api 分類    
  },
}
</script>


<style lang="scss" scoped >

// * {
//     outline: red solid 1px;
// }

.apiBox {
  height:150vh;
  // background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%) center center / 100% 100%;
  background-image: linear-gradient(to bottom,#FFF 55% ,#fff 80% , transparent 50% );
  background-size: cover;
  height: 100vh;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;   

  &__listBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width:100%;
    
    .filterWrap{
      padding: 0px 5%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .itemWrap{
          
          // width: 500px;    
          
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1%;
          margin-right: 10px;
          
          label{
              margin-right: 15px;
              white-space:nowrap;
          }


      }

    }    

    .content {
      display: flex;      
      flex-direction: row;
      justify-content: flex-start;
      // padding: 0px 5%;
      width:100%;
      .el-table {
        padding: 0px 5%;
      }
    }

    .pageBox {
      display: flex;      
      flex-direction: row;
      justify-content: flex-end;      
      width: 100%;

      #page {
        padding: 0px 5%;
      }
    }

    .addButtonBox {
      display: flex;

      flex-direction: row;
      justify-content: flex-end;       
      padding: 0px 5%;
      width: 90%;
      margin-bottom: 2%;
    }
  }  

  .el-form {
    width:90%;
  }

  .goDetailPage {
      color: #0645ad;
      text-decoration: underline;
      cursor: pointer;
   } 



}
</style>