<template>
  <div id="app" @click="clicked">
    
    <Loading />
    <div id="nav">
      <el-aside v-if="!$route.meta.hideMenu" class="mainLayoutAside"
        :class="{ isCollapse: isCollapse }"
      >
        <div class="mainLayoutAside__title"
          :class="{ mainLayoutAside__shrink: isCollapse }"
          :style="{ 'flex-direction: column': !isCollapse }"
        >
          <!-- <img :src="logoImg" alt="logo" :width="getLogoWidth" style="margin-bottom:10px;"> -->
          <img :src="logoImg" alt="logo"  :style="getLogoWidth">
          <strong v-if="! isCollapse">邁得思</strong>
          <i class="el-icon-s-unfold"
            :class="{ goRound: !isCollapse }"
            @click="isCollapse = !isCollapse"
          ></i>
        </div>
        <div class="unWrap" v-show="!isCollapse"><strong>{{getUserProfile?getUserProfile:''}} 您好</strong></div>
        <el-menu
          :default-active="$route.name"
          :show-timeout="200"
          :collapse-transition="false"
          :unique-opened="uniqueOp"
          :collapse="isCollapse"
          background-color="#191970"
          text-color="#fff"
          active-text-color="#fff"
          :router="false"
        >
          <Sidebar v-for="menu in routes" :key="menu.id"
            :isCollapse="isCollapse"
            :item="menu"
            @doRoute="doRoute"
          />
        </el-menu>
      </el-aside>

      <el-main :class="{ withUp: isCollapse, noBar: $route.meta.hideMenu }">
        <div class="viewHeight">
          <router-view></router-view>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapGetters} from "vuex";
import Loading from "./components/Loading.vue";
import Sidebar from "./components/Sidebar.vue";

export default {
  components: { Loading, Sidebar },
  data() {
    return {
      userName:"",
      // userInfo: null,
      isCollapse: true,
      uniqueOp: true,
      transitionName: "slide-left",
      logoImg:require('../src/assets/logo.png'),
      lTime: new Date().getTime(), // 最後一次點擊的時間
      // ctTime: new Date().getTime(), //當前時間
      tOut: 3000 * 60 * 1000,   //超時時間10min     
      timeID:"",

    };
  },
  methods: {
    doRoute(val) {
      
      this.$router.push({ name: val });
    },
    clicked () {
            this.lTime = new Date().getTime()  //當界面被點擊更新點擊時間
            this.userName = window.localStorage.getItem("userName");
            //console.log("this.username", this.userName);
    },
    tTime() {
            let cTime = new Date().getTime();
            // console.log(cTime,this.lTime);
            let diff = cTime -this.lTime;
            // console.log("diff",diff);
            if (diff > process.env.VUE_APP_BASE_TimeOut) {
                // console.log("log Out Automative ");
                console.log( this.timeID);
                this.$store.dispatch("Logout").then(() => {
                window.localStorage.removeItem("userID");
                this.$notify({
                  title: "自動登出",
                  message: "您已超過10分鐘未使用，因此自動登出!",
                  type: "success",
                });
                
                if( this.timeID )
                {
                  window.clearInterval(this.timeID);
                }
                else
                {
                  console.log("TimeID is not exist");
                }
                  
                this.$router.push("/logoutauto");
              });
            }
    }    
  },
  computed: {
    ...mapGetters(["userInfo"]),
    getUserProfile: {
      get() {
        //console.log("username", window.localStorage.getItem("userName"));        
        //return window.localStorage.getItem("userName") || "";
        return this.userName || "";

      },
    },
    getLogoWidth() {
      let sty = "width:50px;margin-bottom:20px;margin-top:20px;"
      if( ! this.isCollapse)
        sty = sty + "width:80px;margin-bottom:0px;margin-top:0px;"
      return sty;
    },
    routes() {
      //console.log("routes",this.$store.state.menuList);
      return this.$store.state.menuList;
    },
  },


  
  mounted() {
    this.timeID = window.setInterval(this.tTime, 60*1000);
    //console.log("userName",window.localStorage.getItem("userName"));
    this.userName =  window.localStorage.getItem("userName") || "";
    // user
    // this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    // this.userName = this.$store.state.userName || window.localStorage.getItem("userName") || ""
    // console.log('appUserName:',this.$store.state.userName);
    // console.log('ueserInfo',this.$store.state.userInfo);
    
    // window.onbeforeunload = () => {
    //   //alert('reload alert');
    //   return 'tips';

    // }
  },
};
</script>

<style lang="scss">
#nav {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  .unWrap{
    padding-left: 20px;
  }
  .mainLayoutAside {
    padding-top: 10px;
    position: fixed;
    z-index: 2;
    height: 100vh;
    background: #191970;
    color: #fff;
    transition: 0.5s;
    width: 250px !important;

    &.isCollapse {
      width: auto !important;
    }

    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      strong {
        font-size: 24px;
        color: #fff;
      }

      i {
        padding: 0 8px;
        font-size: 24px;
        margin-left: auto;
        transition: 0.5s;
        cursor: pointer;

        &.goRound {
          transform: rotate(180deg);
        }
      }
    }

    &__shrink {
      flex-direction: column;

      i {
        font-size: 20px;
        margin-left: 0;
      }
    }
  }

  .el-main {
    padding: 0;
    height: 100%;
    margin-left: 250px;

    &.withUp {
      margin-left: 50px;
    }

    &.noBar {
      margin-left: 0 !important;
    }
  }
}
.el-menu {
  &--collapse {
    width: auto !important;
  }
}
</style>
