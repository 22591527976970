<template>
    <div class="indusBox">
        <Breadcrumb :title="$route.meta.title" />
        <div class="searchWrap">
            <div class="itemWrap" >
                <label>產業別</label>
                <el-select v-model="listQuery.Indus" placeholder="請選擇產業別" no-data-text="目前無名單" @change="changeIndusSub" clearable>
                <el-option v-for="(item,key) in indusList" :key="key"  :label="item" :value="key"></el-option>
                </el-select>             
            </div>
            <div class="itemWrap" v-if="showIndusSub">
                <label>次類別</label>
                <el-select v-model="listQuery.IndusSub" placeholder="請選擇產業次類別" no-data-text="無次類別" @change="changeIndusSmall"  clearable>
                <el-option  v-for="(item,key) in indusSubList" :key="key"  :label="item" :value="key"></el-option>
                </el-select>
            </div>
            <div class="itemWrap" v-if="showIndusSmall">
                <label>小類別</label>
                <el-select v-model="listQuery.IndusSmall" placeholder="請選擇產業小類別" no-data-text="無小類別" @change="changeIndusChain"  clearable>
                <el-option  v-for="(item,key) in indusSmallList" :key="key" :label="item" :value="key" ></el-option>
                </el-select>
            </div>
            <div class="itemWrap" v-if="showIndusChain">
                <label>產業鏈</label>
                <el-select v-model="listQuery.IndusChain" placeholder="請選擇產業鏈" no-data-text="無小類別" @change="changeIndusDesc"  clearable>
                <el-option  v-for="(item,key) in indusChainList" :key="key" :label="item" :value="key" ></el-option>
                </el-select>
            </div>
            <div class="itemWrap" v-if="showIndusDesc">
                <label>產業描述</label>
                <el-select v-model="listQuery.IndusDesc" placeholder="請選擇產業描述" no-data-text="無小類別" @change="changeIndusCat"  clearable>
                <el-option  v-for="(item,key) in indusDescList" :key="key" :label="item" :value="key" ></el-option>
                </el-select>
            </div>   
            <div class="itemWrap" v-if="showIndusCat">
                <label>產業分類</label>
                <el-select v-model="listQuery.IndusCat" placeholder="請選擇產業分類" no-data-text="無小類別" @change="changeProdComb"   clearable>
                <el-option  v-for="(item,key) in indusCatList" :key="key" :label="item" :value="key" ></el-option>
                </el-select>
            </div>   
            <div class="itemWrap" v-if="showProdComb">
                <label>產品結構</label>
                <el-select v-model="listQuery.ProdComb" placeholder="請選擇產品結構" no-data-text="無小類別"    clearable>
                <el-option  v-for="(item,key) in prodCombList" :key="key" :label="item" :value="key" ></el-option>
                </el-select>
            </div>
                                                   
        </div>
        <div class="searchWrap2">
            <div class="itemWrap2-1" >
                <label>查詢日期</label>
                <el-date-picker :editable="true" v-model="dateRange"  type="daterange" range-separator="至" start-placeholder="開始日期" end-placeholder="結束日期" style="width:75%">
                </el-date-picker>
            </div>
            <div class="itemWrap2-2" style="margin-left:20px">
                <el-button type="primary" @click="btnSearch"  ><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>                
                <el-button type="danger" @click="openAddProjSort = true" >
                    <font-awesome-icon icon="plus" style="margin-right: 5px"/>加入專卷
                </el-button>                
            </div>             
        </div>
        <div class="searchWrap3">
            <div class="tableData" >   
                <el-table ref="multipleTable"  :data="tableData"  tooltip-effect="dark" style="width: 100%;"  empty-text="暫無數據" :cell-style="{padding: '3px', height: '35px'}" :header-cell-style="{ background: '#FFF6EF', border: '1px #ddd solid'}" @select="handleSelectionChange"  >
                    <el-table-column type="selection" width="50"></el-table-column>  
                    <el-table-column label="公司名稱" prop="PrimaryCompanys" min-width="20%">
                        <!-- <template slot-scope="scope" >
                            <div v-if="scope.row.pCompanyTitle.length>0">
                                {{ scope.row.PrimaryCompanys }} 
                                <el-tooltip class="item" effect="light"  placement="right">                                
                                    <div slot="content" v-html="scope.row.pCompanysTips"></div>
                                    <el-button type="text">{{ scope.row.pCompanyTitle }}</el-button>
                                </el-tooltip>                                
                            </div>
                            <div v-else>
                                {{ scope.row.PrimaryCompanys }}
                            </div>                            
                        </template>    -->
                    </el-table-column>
                    <el-table-column label="新聞標題"  min-width="20%" >
                        <template slot-scope="scope">
                            <a class="goDetailPage" @click="goDetailPage(scope.row)">{{ scope.row.NewsTitle }}</a>
                        </template>
                    </el-table-column>
                    <el-table-column label="新聞網站" prop="NewsSite" min-width="10%">                
                    </el-table-column>
                    <el-table-column label="新聞時間"  min-width="10%">    
                        <template slot-scope="scope">
                            <div>{{ scope.row.NewsTime | moment("YYYY-MM-DD") }}</div>
                        </template>                                    
                    </el-table-column>                      
                    <el-table-column label="既有客戶" prop="tbbCompanys"  min-width="15%">           
                    </el-table-column>                        
                    <el-table-column label="相關公司" prop="Companys"  min-width="20%">           
                    </el-table-column>    
                    <el-table-column label="相關人物" prop="Ppls" min-width="10%">                
                    </el-table-column>
                    <!-- <el-table-column label="商情摘要" prop="AML" min-width="10%">                
                    </el-table-column> -->
                </el-table>           
                <Pagination @currentChange="pageChange" :pageSize="this.listQuery.Page_Size" :propsCurrentPage="this.listQuery.Page_Num" :totalLists="this.totalLists"> </Pagination>

            </div>
        </div>

        <el-dialog title="請選擇專卷主題" :visible.sync="openAddProjSort" width="40%" center>
            <div style="display:flex; flex-direction:column; align-items:center;">
                <div class="projMaintain__searchBox--sort">
                <label>專卷分類：</label>
                <el-select v-model="folder" placeholder="請選擇專卷" no-data-text="無數據" @change="getTopicList">
                    <el-option label="請選擇" value=""></el-option>
                    <el-option :label="item.FolderCategory" :value="item.FolderID" v-for="item in folerList" :key="item.FolderID"></el-option>
                </el-select>
                </div>
                <!-- 專卷主題 -->
                <div class="projMaintain__searchBox--theme" style="margin-top:20px;">
                <label>專卷主題：</label>
                <el-select v-model="topic" placeholder="請選擇專卷主題" v-if="folerList.length > 0" no-data-text="無數據" >
                    <el-option :label="item.TopicName" :value="item.TopicID" v-for="item in topicList" :key="item.TopicID"></el-option>
                </el-select>
                <el-select v-model="topic" placeholder="請先選擇專卷分類" :disabled="!folder" v-else></el-select>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveTopic()"><font-awesome-icon icon="plus"  style="margin-right:5px;" />加入主題</el-button>
                <el-button type="danger" @click="openAddProjSort = false"><font-awesome-icon icon="times" style="margin-right:5px;" />關閉</el-button>
            </span>
        </el-dialog>          

    </div>
</template>

<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from '../../src/components/Breadcrumb.vue';
import moment from "moment";

export default {
    components: {Breadcrumb,Pagination},
    data() {
        return {
            openAddProjSort : false,
            projThemeInfo: {},
            selectData: [],
            selectPage: [],    
            
            folder:"",
            topic:"",      
            folerList: [],
            topicList: [],               

            webSiteOptions:[],
            allNodes: [],
            currentNode: [],
            currentSelections: [],  

            ComId:'',
            coName: [],
            indusList:[],
            indusSubList:[],
            indusSmallList:[],
            indusChainList:[],
            indusDescList:[],
            indusCatList:[],
            prodCombList:[],           


            showIndus:false,
            showIndusSub:false,
            showIndusSmall:false,
            showIndusChain:false,
            showIndusDesc:false,
            showIndusCat:false,
            showProdComb: false,
            listQuery: {
                Start_Date: '',
                End_Date: '',
                Page_Size: 10, //每頁有幾筆
                Page_Num: 1, //目前頁數
                Indus:"",
                IndusSub:"",
                IndusSmall:"",
                IndusChain:"",
                IndusDesc:"",
                IndusCat:"",
                ProdComb:"",
            }, 
            searchNewsQry: {},
            dateRange: [
                moment().add(-7, "days").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
            ], 
            tableData: [],
            totalLists: 0,     
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {      

        // 儲存專卷
        saveTopic() {
            
            console.log("選取的NewsId", this.selectData)
            // console.log("頁數有勾選的",this.selectPage);
            let errList=[];
            let qry = { "FolderID": this.folder, "TopicID": this.topic, "NewsID": "", "isSys": false };      
            this.selectData.forEach(x=>{
                x.forEach(y=>{
                    qry.NewsID = y.DataID;
                    qry.NewsTitle = y.NewsTitle;
                    qry.NewsSite = y.NewsSite;
                    qry.NewsChannel = y.NewsChannel;                    
                    qry.NewsTime = y.NewsTime;
                    // console.log(qry);
                    this.$api.folder.addTopicNews(qry).then(res=>{
                        // console.log("success",res);
                    },err => {
                        errList.push(y);
                    });
                });
            });


            if(errList.length > 0) {
                this.$notify.error({
                title: "錯誤",
                message: "儲存中發生錯誤",
                type: "Error"});
            }
            else {
                this.$notify({
                title: "成功",
                message: "儲存成功!",
                type: "success",
                });
                this.openAddProjSort = false;
            }

        },         


        async pageChange(val) {
            
            this.listQuery.Page_Num = val;
            await this.getIndustryNews();
            
        },

        async getTopicList(isSys) {
            this.$store.dispatch("loadingHandler", true);
            this.topicList = [];

            let req = {Page_Size: 100, Page_Num: 1, FolderID: this.folder };     
            if ( !isSys ) 
                req.isSys = false;
            else 
                req.isSys = true;
            //console.log(req);

            await this.$api.folder.searchTopic(req).then((res)=>{                
                //console.log(res);        
                this.topicList = res.data;
                this.totalLists = res.total;
                //console.log(this.topicList);        
            });        

            this.$store.dispatch("loadingHandler", false);
        },

        async getFolderList(isSys) {
            this.folerList = [];

            let req = {Page_Size: 100, Page_Num: 1 };     
            if ( !isSys ) 
                req.isSys = false;
            else 
                req.isSys = true;      

            await this.$api.folder.searchFolder(req).then((res)=>{                
                this.folerList = res.data;          
            });
        },           


        async getIndustryNews() {
            this.$store.dispatch('loadingHandler', true);
            //alert('IndusNews');
             let qry = {
                //CreateUniComNm: "1234567",
                Start_Date: moment(this.dateRange[0]).format("YYYY-MM-DD"),
                End_Date: moment(this.dateRange[1]).format("YYYY-MM-DD"),
                Page_Size: 10 ,
                Page_Num: this.listQuery.Page_Num         
            };  
            //console.log(qry);
            
            if(this.listQuery.Indus!=="") {  
                qry.Indus = this.indusList[this.listQuery.Indus];
            } 
            else {
                this.$notify.error({
                    title: "錯誤",
                    message: "至少選取一個產業別",
                type: "Error"});                
                return;
            }
            if(this.listQuery.IndusSub!=="") {  
                qry.IndusSub = this.indusSubList[this.listQuery.IndusSub];
            }           
            if(this.listQuery.IndusSmall!=="") {  
                qry.IndusSmall = this.indusSmallList[this.listQuery.IndusSmall];
            }       
            if(this.listQuery.IndusChain!=="") {  
                qry.IndusChain = this.indusChainList[this.listQuery.IndusChain];
            }
            if(this.listQuery.IndusDesc!=="") {  
                qry.IndusDesc = this.indusDescList[this.listQuery.IndusDesc];
            }
            if(this.listQuery.IndusCat!=="") {  
                qry.IndusCat = this.indusCatList[this.listQuery.IndusCat];
            }     
            if(this.listQuery.ProdComb!=="") {  
                qry.IndusProdComb = this.prodCombList[this.listQuery.ProdComb];
            }                        
            console.log("Qry",qry);
            //console.log(qry);
            await this.$api.apimgrs.searchIndustryNews(qry).then((res)=>{
                    this.tableDataTemp = res.data;
                    this.totalLists = res.total;
                    
                    console.log("searchIndustryNews", this.tableDataTemp);

                    this.tableData = [];
                    this.tableDataTemp.forEach(x=>{    
                        let row = {     
                            DataID: x.DataID,                       
                            NewsTitle : x.NewsTitle,                            
                            NewsSite : x.NewsSite,
                            NewsTime : x.NewsTime,
                            NewsChannel : x.NewsChannel,
                            PrimaryCompanys : x.RelationCompany.map(y=>y).join(' / '),
                            Companys : x.ComName.map(y=>y.name).join(' / '),
                            tbbCompanys: x.TbbList?.replace(",", " / "),
                            Ppls : [...new Set(x.PplName.map(y=>y.name))].join(' / '),
                        }   
                        this.tableData.push(row);
                    });                    



            });
            
            

            this.$store.dispatch('loadingHandler', false);
            // this.makeTableShowChecked()
        },        

        getChild3(node, altX){
            //console.log(node);
            let xChild = []; 
            node.selection.forEach(x=>{
                //console.log(x);
                // console.log(node[x]);
                // console.log(node[x].selection);
                let yChild = [];
                node[x].selection.forEach(y=>{
                    //console.log(y); // ----------- 187
                    //console.log(node[x][y]);
                    let zChild =[];
                    node[x][y].selection.forEach(z=>{
                        // console.log(z); // ------------- 190
                        // //console.log(node[x][y][z]);
                        // console.log(this.outputChildren(node[x][y][z])); //------------ 192      
                        zChild.push( {label: z, children: this.outputChildren(node[x][y][z])}) ;          
                    });
                    //console.log(zChild);
                    yChild.push( { label: y, children: zChild});
                });
                //console.log(yChild);

                // console.log(this.getChild(node[x].selection));
                xChild.push( {label: x=="empty" ? altX : x , children: yChild});
            });
            console.log(xChild);

            return xChild;             
        },

        outputChildren(node){
            if( this.isLeaf(node)) { // 還有兒子...
                node[x][y][z].selection.forEach(m=>{
                    console.log(m);
                    console.log(node);
                }); 
                return "";
            }
            else { // 沒有兒子了, 所以直接輸出
                // console.log(node[x][y][z]);
                // console.log(this.toObjArray(node[x][y][z]));
                let output = [];
                node.selection.forEach(x=> output.push({label: x}));
                return output;
            }
        },

        isLeaf(node) {
            let res = true;
            // console.log(node)
            if( node.selection.length >0) {
                // console.log(node.selection[0]);
                // console.log(node[node.selection[0]]);
                if( node[node.selection[0]]==undefined)
                    res = false;
            }
            //console.log(res);
            return res;
        },

        removeEmpty(node){
            if(node.label=="empty" && node.children.length==1) {
                //console.log("Ret2",node.children[0]);
                return node.children[0];
            }
            else  
                return node;
        },

        //顯示上,中,下游
        showStream(sup, sub, name) {     
            console.log(sup, sub, name);
            this.currentSelections = this.allNodes[sup].children[sub].children[name];
            console.log( this.currentSelections);
            this.treedata1 = [];this.treedata2=[];this.treedata3=[];
            this.showTree1 = false;this.showTree2 = false; this.showTree3=false;
            if( this.currentSelections.children.length==1 && this.currentSelections.children[0].label=="empty") {
                // 如果.. this.currentSelections[0].label=="empty", 表示沒有上, 中, 下游
                //    ==> 直接秀...
                //alert("無 上, 中, 下游");
                
                console.log(this.currentSelections);
                this.treedata2  = [ this.currentSelections];
                this.showTree2 = true;

                let rEmpty = this.currentSelections;
                for (let index = 0; index < 4; index++) {
                    rEmpty = this.removeEmpty(rEmpty);                    
                }

                // 刪除頂部只有empty的...
                if( rEmpty.label=="empty") {
                    rEmpty = rEmpty.children;
                }
                else
                {
                    this.treedata1  = [rEmpty];                                      
                }

                //刪除底部的empty



                console.log(rEmpty);
                this.treedata1 = rEmpty;
                this.showTree1 = true;


            }
            else {
                    for(let i=0;i<this.currentSelections.children.length;i++)
                    {
                        let title= this.currentSelections.children[i].label;
                        // console.log(title);
                        if( title.indexOf("上游") >= 0 ) {                            
                            this.treedata1  =  [ this.currentSelections.children[i] ];
                            this.showTree1 = true;
                        }
                        if( title.indexOf("中游") >= 0 ) {                            
                            this.treedata2  =  [ this.currentSelections.children[i] ];
                            this.showTree2 = true;
                        }
                        if( title.indexOf("下游") >= 0 ) {                            
                            this.treedata3  =  [ this.currentSelections.children[i] ];
                            this.showTree3 = true;
                        }
                    };                  
            }          
        },

        async btnSearch() {
            this.$store.dispatch('loadingHandler', true);
            this.listQuery.Page_Num = 1;
            this.selectData = [];   
            await this.getIndustryNews();
            this.$store.dispatch('loadingHandler', false);
        },

        makeTableShowChecked(){
            // console.log("makeTableShowChecked")
            if( this.selectData.length > 0) {        
                this.$nextTick(()=>{
                this.tableData.forEach((item) => {
                    const checkedDataIDs = this.selectData[this.listQuery.Page_Num-1];
                    if( checkedDataIDs == undefined)
                    return;
                    // console.log("temp",temp);
                    checkedDataIDs.forEach((selectedItem) => {
                    if (item.DataID === selectedItem.DataID) {
                        // console.log(item);
                        this.$refs.multipleTable.toggleRowSelection(item, true);
                    }
                    });
                });
                });

            }
        },   
        
        handleSelectionChange(val) {
            // console.log("checked",val);
            this.selectData[this.listQuery.Page_Num - 1] = val;
            // console.log("selectData", this.selectData);
        },           

        switchCurrentNode() {
            this.currentNode = this.allNodes[this.listQuery.Indus];
            // console.log("Before",this.currentNode); 
            // console.log(this.listQuery);    
            if(this.listQuery.IndusSub!=="") {  
                this.currentNode = this.currentNode.children[this.listQuery.IndusSub];
            }               
            if(this.listQuery.IndusSmall!=="") {
                this.currentNode = this.currentNode.children[this.listQuery.IndusSmall];
            }
            if(this.listQuery.IndusChain!==""){
                this.currentNode = this.currentNode.children[this.listQuery.IndusChain];
            }
            if(this.listQuery.IndusDesc!=="") {
                this.currentNode = this.currentNode.children[this.listQuery.IndusDesc];    
            }  
            if(this.listQuery.IndusCat!=="") {
                this.currentNode = this.currentNode.children[this.listQuery.IndusCat];
            }
            if(this.listQuery.ProdComb!=="") {
                this.currentNode = this.currentNode.children[this.listQuery.ProdComb];    
            }                                         
            // console.log("After", this.currentNode);
        },



        changeProdComb(name) {
            // console.log("產業分類",name);
            this.clearDownStream("IndusCat");
            this.switchCurrentNode();
            this.showCombobox();   
        },

        changeIndusCat(name) {
            // console.log("產業描述",name);
            this.clearDownStream("IndusDesc");
            this.switchCurrentNode();
            this.showCombobox();   
        },

        changeIndusDesc(name) {
            // console.log("產業鏈",name);
            this.clearDownStream("IndusChain"); 
            this.switchCurrentNode();
            this.showCombobox();           
        },

        changeIndusChain(name) {
            // console.log("小類別",name);
            this.clearDownStream("IndusSmall");
            this.switchCurrentNode();
            this.showCombobox();
        },


        changeIndusSmall(name) {
            // console.log("次類別",name);
            this.clearDownStream("IndusSub");
            this.switchCurrentNode();
            this.showCombobox();
            
        },

        changeIndusSub(name) {
            // console.log("產業別",name);
            this.clearDownStream("Indus");
            this.switchCurrentNode(); 
            this.showCombobox();
        },

        showCombobox(){   
            //console.log('showCombox');
            if( this.currentNode.children===undefined)
                return;
            let node = this.currentNode.children;
            let layer = node[0].layer;
            let label = this.currentNode.label;
            //console.log(label,layer,node);

            switch(layer) {
                case "IndusSub":
                    this.indusSubList = node.map(x=>x.label);
                    this.showIndusSub = true;
                    break;            
                case "IndusSmall":
                    this.indusSmallList = node.map(x=>x.label);
                    this.showIndusSmall = true;
                    break;
                case "IndusChain": 
                    this.indusChainList = node.map(x=>x.label);
                    this.showIndusChain = true;
                    break;  
                case "IndusDesc":
                    this.indusDescList = node.map(x=>x.label);
                    this.showIndusDesc = true;
                    break;
                case "IndusCat":
                    this.indusCatList = node.map(x=>x.label);
                    this.showIndusCat = true;
                    break;
                 case "ProdComb":
                    this.prodCombList = node.map(x=>x.label);
                    this.showProdComb = true;
                    break;
            }
        },       

        clearDownStream(layer) {
            //console.log(layer);
            switch(layer) {
                case "Indus":
                    this.listQuery.IndusSub = "";
                    this.listQuery.IndusSmall = "";
                    this.listQuery.IndusChain = "";
                    this.listQuery.IndusDesc = "";
                    this.listQuery.IndusCat = "";
                    this.listQuery.ProdComb = "";
                    this.showIndusSub = false;
                    this.showIndusSmall = false;
                    this.showIndusChain = false; 
                    this.showIndusDesc = false;
                    this.showIndusCat = false;
                    this.showProdComb = false;                                      
                    break;
                case "IndusSub":
                    this.listQuery.IndusSmall = "";
                    this.listQuery.IndusChain = "";
                    this.listQuery.IndusDesc = "";
                    this.listQuery.IndusCat = "";
                    this.listQuery.ProdComb = "";
                    this.showIndusSmall = false;
                    this.showIndusChain = false; 
                    this.showIndusDesc = false;
                    this.showIndusCat = false;
                    this.showProdComb = false;                                      
                    break;            
                case "IndusSmall":
                    this.listQuery.IndusChain = "";
                    this.listQuery.IndusDesc = "";
                    this.listQuery.IndusCat = "";
                    this.listQuery.ProdComb = ""; 
                    this.showIndusChain = false; 
                    this.showIndusDesc = false;
                    this.showIndusCat = false;
                    this.showProdComb = false;                   
                    break; 
                case "IndusChain":
                    this.listQuery.IndusDesc = "";
                    this.listQuery.IndusCat = "";
                    this.listQuery.ProdComb = "";    
                    this.showIndusDesc = false;
                    this.showIndusCat = false;
                    this.showProdComb = false;                                    
                    break; 
                case "IndusDesc":
                    this.listQuery.IndusCat = "";
                    this.listQuery.ProdComb = "";   
                    this.showIndusCat = false;
                    this.showProdComb = false;                                             
                    break; 
                case "IndusCat":
                    this.listQuery.ProdComb = "";    
                    this.showProdComb = false;                                     
                    break;
                case "ProdComb":
                    this.listQuery.ProdComb = "";                    
                    break;
            }           
        },

        goDetailPage(data) {
            console.log(data);
            let routeUrl = this.$router.resolve({
                path: "/detailNews",
                query: { id: data.DataID },                
            });
            window.open(routeUrl.href, "_blank");
        },
          

        getIndusSelections() {
        return new Promise((resolve)=>{
            let temp = {
                //CreateUniComNm: "1234567",
                "FixLayer": false
            };
            this.$api.apimgrs.getIndusSelections(temp).then((res) => {
                console.log(res);
                this.allNodes = res.data;
                //this.indusList = this.allNodes.map( function(value,index) {return { id:index, txt:value.label, layer: value.layer } });
                this.indusList = this.allNodes.map(x=>x.label);
                //console.log(this.indusList);
                this.showIndus = true;
                //console.log(this.indusSelections);
                resolve()
            });
        })
        },     

        getWebSiteOptions() {
            return new Promise((resolve)=>{
                let temp = {
                Type: ['NewsSite'],
                };
                this.$api.system.getOptions(temp).then((res) => {
                    this.webSiteOptions = res.NewsSite;
                    resolve()
                });
            })
        },
    },
    async mounted() {
        this.$store.dispatch("loadingHandler", true);
        //await this.getWebSiteOptions();
         
        await this.getIndusSelections();
        this.getFolderList(true);
        this.$store.dispatch("loadingHandler", false);
    },
}
</script>


<style lang="scss" scoped>
// * {
//     outline: red solid 1px;
// }
.indusBox {
  background: linear-gradient(-135deg, #FFF 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  min-height: 100vh;
  padding: 0px 5%;

    .searchWrap {
        display: flex;
        flex-direction: row;
        justify-content:flex-start;
        flex-wrap: wrap;
        margin: 0px 5%;
        

        .itemWrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 30%;
            margin: 10px 0px;

            label {
                white-space: nowrap;
                width: 80px;
                text-align: right;
                margin-right: 10px;

            }

        }


    }

    .searchWrap2 {
        display: flex;
        flex-direction: row;
        justify-content:flex-start;
        flex-wrap: wrap;
        margin: 0px 5%;
        width: 90%;

        .itemWrap2-1 {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 50%;
            margin: 10px 0px;

            label {
                white-space: nowrap;
                width: 80px;
                text-align: right;
                margin-right: 10px;

            }

        }
        .itemWrap2-2 {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 45%;
            margin: 10px 0px;

            label {
                white-space: nowrap;
                width: 80px;
                text-align: right;
                margin-right: 10px;

            }

        }

    }

    .searchWrap3 {
        display: flex;
        flex-direction: row;
        margin: 0px 5%;

        .tableData
        {
            width: 100%;
        }
    }


  .goDetailPage {
    color: #0645ad;
    text-decoration: underline;
    cursor: pointer;
  }

}

</style>