<template>
<div class="wholeBread">
  <div class="breadBox">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="projSearch--breadcrumb" >
      <el-breadcrumb-item :to="{ name: 'home' }" >
        <font-awesome-icon icon="home" style="margin-right:10px;" />首頁
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="title!='None'">
        <font-awesome-icon icon="list-ul" style="margin-right:10px;" />
        {{ title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <hr/>

  </div>
</div>  
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: "None",
    },
  },
};
</script>

<style lang="scss" scoped>

.wholeBread {
    display: flex;
    width: 100%;
    justify-content: center;

  .breadBox {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: flex-start;

    .projSearch {
      &--breadcrumb {
        padding: 20px 0px;
      }
    }

    hr {
      border: 2px solid #00abb9;
      border-radius: 5px;
      filter: drop-shadow(2px 2px 1px #aaa);
      width: 100%;
      margin-bottom: 2%;
    }  

  }

}

</style>
